import { Exact, StaffQuery } from '../../../../graphql/generated/generated';
import { QueryResult } from '@apollo/client';
import { BreaksForm } from '../../../BreaksForm/BreaksForm';
import { Title } from '../../../shared/Title/Title';

import './Breaks.scss';

interface Props {
  user: QueryResult<
    StaffQuery,
    Exact<{
      id: number;
    }>
  >;
}

export const Breaks = (props: Props) => {
  const { loading, data, error } = props.user;

  return (
    <div className="Breaks">
      <Title loading={loading} error={error}>
        <>Breaks for {data?.staffMember.full_name}:</>
      </Title>
      {data?.staffMember.id && <BreaksForm id={data?.staffMember.id} />}
    </div>
  );
};
