import { NotificationFormik } from '../../../lib/hooks/formik/useNotificationFormik';
import { Input } from '../../shared/Input/Input';
import './NotificationForm.scss';

interface Props {
  formik: NotificationFormik;
}

export const NotificationForm = (props: Props) => {
  const { formik } = props;

  return (
    <>
      <Input
        className="NotificationForm__input"
        label="Title"
        type="text"
        name="title"
        id="title"
        placeholder="Notification Title"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        touched={formik.touched.title}
        value={formik.values.title}
        error={formik.errors.title}
        invalid={formik.touched.title && !!formik.errors.title}
      />
      <Input
        className="NotificationForm__input"
        label="Description"
        type="textarea"
        name="description"
        id="description"
        placeholder="Notification Description"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        touched={formik.touched.description}
        value={formik.values.description}
        error={formik.errors.description}
        invalid={formik.touched.description && !!formik.errors.description}
      />
    </>
  );
};
