import { Form, ModalFooter } from 'reactstrap';
import { FormButtons } from '../../shared/FormButtons/FormButtons';
import { InputProps } from '../../shared/Input/Input';
import { Modal, ModalProps } from '../../shared/Modal/Modal';

import { NotificationForm } from '../NotificationForm/NotificationForm';
import './NotificationModal.scss';
// import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import { NotificationField, useNotificationFormik } from '../../../lib/hooks/formik/useNotificationFormik';
import { useAddNotificationMMutation } from '../../../graphql/generated/generated';

interface Props extends ModalProps {}

export interface GetInputProps extends InputProps {
  key: keyof NotificationField;
}
export const NotificationModal = (props: Props) => {
  const { toggle, ...rest } = props;
  const [addNotification, { error }] = useAddNotificationMMutation();

  const formik = useNotificationFormik({
    initialValues: {
      title: '',
      description: '',
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        await addNotification({
          variables: {
            data: {
              title: values.title,
              description: values.description,
            },
          },
        });
        toggle();
        formikHelpers.resetForm();
      } catch (e) {}
    },
  });
  return (
    <Modal
      title="Notification"
      toggle={() => {
        toggle();
      }}
      className="NotificationModal"
      {...rest}
    >
      <Form className="NotificationModal__form" onSubmit={formik.handleSubmit}>
        <NotificationForm formik={formik} />
        <ModalFooter className="NotificationModal__footer ">
          <FormButtons
            submitClassName="NotificationModal__submit"
            submitButtonName="Send"
            disabled={formik.isSubmitting || !formik.isValid}
            loading={formik.isSubmitting}
            resetForm={formik.resetForm}
            toggle={() => {
              toggle();
            }}
          />
        </ModalFooter>
        {/* <ErrorMessage errorData={error} /> */}
      </Form>
    </Modal>
  );
};
