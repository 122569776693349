// import { useState } from 'react';
import { AppointmentType, useAppointmentsByCustomerQuery } from '../../../graphql/generated/generated';
// import { AppointmentModal } from '../../AddAppointmentModal/AppointmentModal';
// import { AddNewButton } from '../../shared/AddNewButton/AddNewButton';
import { AppointmentsTable } from '../AppointmentsTable/AppointmentsTable';

import './CustomerAppointments.scss';
interface Props {
  id: string;
}

export const CustomerAppointments = (props: Props) => {
  const { loading, data, error } = useAppointmentsByCustomerQuery({ variables: { id: props.id } });

  // const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // const toggleModal = () => setIsModalOpen((prevState) => !prevState);

  //count total cost of appointments
  const costs = data?.appointments.rows?.map((row) => row.cost);
  const totalCost = costs?.reduce((partialSum: number, a: number) => partialSum + a, 0);

  return (
    <div className="CustomerAppointments">
      <h4 className="CustomerAppointments__title">Appointments</h4>
      <div className="CustomerAppointments__divider"></div>
      <div className="CustomerAppointments__summary">
        {/* <AddNewButton onClick={toggleModal} /> */}
        <div className="CustomerAppointments__appointments">{data?.appointments.total_data} Appointments</div>
        <div className="CustomerAppointments__total">{totalCost} €</div>
      </div>
      <div className="CustomerAppointments__table">
        <AppointmentsTable
          appointments={data?.appointments.rows as AppointmentType[]}
          error={error}
          loading={loading}
        />
        {/* <AppointmentModal title="Appointment" isOpen={isModalOpen} toggle={toggleModal} /> */}
      </div>
    </div>
  );
};
