import React from 'react';
import { FormFeedback, FormGroup, Input as ReactstrapInput, InputProps as ReactInputProps } from 'reactstrap';
import cs from 'classnames';
//styles
import './Input.scss';

export interface InputProps extends ReactInputProps {
  touched?: boolean;
  error?: string;
  label?: string;
  hiddenLabel?: boolean;
  inputClassName?: string;
  renderElement?: JSX.Element;
}

export const Input = (props: InputProps) => {
  const {
    className,
    inputClassName,
    error,
    touched,
    label,
    id,
    hiddenLabel,
    wrapperClassName,
    colors,
    disabled,
    ...rest
  } = props;
  return (
    <FormGroup className={cs('Input', className)}>
      {label && (
        <label htmlFor={id} hidden={hiddenLabel}>
          {label}
        </label>
      )}
      <div className={cs('Input__wrapper', wrapperClassName)}>
        <ReactstrapInput disabled={disabled} className={cs('Input__field', inputClassName)} {...rest} />
        {props.renderElement ? props.renderElement : null}
        {error && touched && <FormFeedback className="Input__error">{props.error}</FormFeedback>}
      </div>
    </FormGroup>
  );
};
