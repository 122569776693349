import { UserDetailsFormik } from '../../../lib/hooks/formik/useUserDetailsFormik';
import { Button } from '../../shared/Button/Button';
import { Icon } from '../../shared/Icon/Icon';
import { Input } from '../../shared/Input/Input';
import './UserDetailsForm.scss';

interface Props {
  formik: UserDetailsFormik;
  onUpdatePasswordClick: () => void;
}

export const UserDetailsForm = (props: Props) => {
  const { formik } = props;
  return (
    <>
      <Input
        className="UserDetailsForm__input"
        label="Full Name"
        type="text"
        name="fullName"
        id="fullName"
        placeholder="Full Name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        touched={formik.touched.fullName}
        value={formik.values.fullName}
        error={formik.errors.fullName}
        invalid={formik.touched.fullName && !!formik.errors.fullName}
      />
      <Input
        className="UserDetailsForm__input"
        label="Email"
        type="text"
        name="email"
        id="email"
        placeholder="Email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        touched={formik.touched.email}
        value={formik.values.email}
        error={formik.errors.email}
        invalid={formik.touched.email && !!formik.errors.email}
      />
      <Button className="UserDetailsForm__button" type="button" onClick={props.onUpdatePasswordClick}>
        Update Password <Icon className="UserDetailsForm__arrow" icon="arrow-general" />
      </Button>
    </>
  );
};
