import React, { useState } from 'react';
import './ForgotPassword.scss';
import { ForgotPasswordField, useForgotPasswordFormik } from '../../../lib/hooks/formik/useForgotPasswordFormik';
import { Input, InputProps } from '../../shared/Input/Input';
import { Button, Form } from 'reactstrap';
import { useRouter } from '../../../lib/hooks/useRouter';
import { Router } from 'react-router';
import { useForgotPasswordMutation } from '../../../graphql/generated/generated';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';

import { InputType } from 'reactstrap/es/Input';
import { ApprovedMessage } from '../ApprovedMessage/ApprovedMessage';

interface GetInputProps {
  key: keyof ForgotPasswordField;
  type: InputType | undefined;
  placeholder: string;
}
export const ForgotPassword = () => {
  const router = useRouter();
  const [success, setSuccess] = useState(true);
  const handleCancel = () => {
    router.history.push('login');
  };
  const [forgotMyPassword, { error }] = useForgotPasswordMutation();
  const formik = useForgotPasswordFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        await forgotMyPassword({
          variables: {
            data: {
              email: values.email,
            },
          },
        });
        setSuccess(false);
        formikHelpers.resetForm();
      } catch (error) {}
    },
  });
  const getInputProps = ({ key, type, placeholder, ...props }: GetInputProps) => {
    return {
      className: 'SetPasswordForm__input',
      type: type,
      name: key,
      id: key,
      placeholder: placeholder,
      hiddenLabel: true,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      touched: formik.touched[key],
      error: formik.errors[key],
      value: formik.values[key],
      invalid: formik.touched[key] && !!formik.errors[key],
      ...props,
    } as InputProps;
  };

  return (
    <div className="ForgotPassword">
      {success ? (
        <Form onSubmit={formik.handleSubmit}>
          <h2 className="ForgotPassword__description">Forgot my password</h2>
          <div className="ForgotPassword__form">
            <div className="ForgotPassword__emails">
              <Input {...getInputProps({ key: 'email', type: 'email', placeholder: 'Email' })} />
            </div>
          </div>

          <p className="ForgotPassword__content">
            In this email you will receive a link where you need to follow the steps to reset your password
          </p>
          <div className="ForgotPassword__buttons">
            <Button loading={formik.isSubmitting} disabled={formik.isSubmitting || !formik.isValid} type="submit">
              SEND
            </Button>
            <Button onClick={handleCancel}>CANCEL</Button>
          </div>
          <ErrorMessage errorData={error} />
        </Form>
      ) : (
        <ApprovedMessage
          title="Approved"
          description="We have sent you an email instructing you on how to reset your password. If you reset your password, log in by clicking this button"
        />
      )}
    </div>
  );
};
