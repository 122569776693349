import { useMemo, useState } from 'react';
import { addMinutesToTime } from '../../lib/helpers/addMinutesToTime';
import { formatDataForSelectOption } from '../../lib/helpers/formatDataForSelectOption';
import { getTimeOptions } from '../../lib/helpers/getTimeOptions';
import { AddBreaksFields } from '../../lib/hooks/formik/useAddBreaksFormik';
import { useBreaks } from '../../lib/hooks/useBreaks';
import { ValueContainer } from '../AppointmentDetailsForm/AppointmentDetailsForm';
import { hourStyles } from '../ChooseWorkingHour/ChooseWorkingHour';
import { Button } from '../shared/Button/Button';
import { ErrorMessage } from '../shared/ErrorMessage/ErrorMessage';
import { Icon } from '../shared/Icon/Icon';
import { Option, Select } from '../shared/Select/Select';
import { ScrollOnOption } from '../../components/shared/Select/components/ScrollOnOption';
import {
  GetUserBreaksType,
  useAddBreakMutation,
  useDeleteBreakMutation,
  useUpdateBreakMutation,
} from '../../graphql/generated/generated';

import './BreaksFormField.scss';

interface Props {
  label: string;
  id: keyof AddBreaksFields;
  breaks: GetUserBreaksType;
}

export const BreaksFormField = (props: Props) => {
  //state managment
  const { staffBreak, addBreak, deleteBreak, updateStartTime, updateEndTime } = useBreaks(props.breaks);

  //mutations
  const [addBreakMutation, { loading: isAdding, error: addError }] = useAddBreakMutation();
  const [deleteBreakMutation, { loading: isDeleting, error: deleteError }] = useDeleteBreakMutation();
  const [updateBreakMutation, { loading: isUpdating, error: updateError }] = useUpdateBreakMutation();

  const [breakId, setBreakId] = useState<number>();

  //add break
  const handleAddBreak = async () => {
    try {
      const res = await addBreakMutation({
        variables: {
          data: {
            day: staffBreak.day,
            start_time: staffBreak.breaks?.[staffBreak.breaks?.length - 1]?.end_time || '08:00:00',
            end_time: addMinutesToTime(15, staffBreak?.breaks?.[staffBreak?.breaks?.length - 1]?.end_time),
            user_id: staffBreak.user_id,
          },
        },
      });
      const newBreak = res.data?.addUserBreak;

      addBreak(newBreak);
    } catch (e) {}
  };

  //delete break
  const handleDeleteBreak = async (id: number) => {
    setBreakId(id);
    try {
      await deleteBreakMutation({ variables: { id: id } });

      deleteBreak(id);
    } catch (e) {}
  };

  //update StartTime
  const handleUpdateStartTime = async (option: Option, id: number) => {
    setBreakId(id);
    try {
      await updateBreakMutation({
        variables: {
          data: { start_time: option.value, end_time: staffBreak.breaks?.find((item) => item.id === id)?.end_time },
          id: id,
        },
      });

      updateStartTime(option, id);
    } catch (e) {}
  };

  //update EndTime
  const handleUpdateEndTime = async (option: Option, id: number) => {
    setBreakId(id);
    try {
      await updateBreakMutation({
        variables: {
          data: { start_time: staffBreak.breaks?.find((item) => item.id === id)?.start_time, end_time: option.value },
          id: id,
        },
      });

      updateEndTime(option, id);
    } catch (e) {}
  };

  const options = useMemo(() => getTimeOptions(24), []);

  return (
    <div className="BreaksFormField">
      <label>{props.label}</label>
      <Button
        className="BreaksFormField__add"
        loading={isAdding}
        disabled={isAdding || isDeleting}
        onClick={handleAddBreak}
      >
        Add Breaks
      </Button>
      <div className="BreaksFormField__wrapper">
        {staffBreak?.breaks &&
          staffBreak.breaks?.length > 0 &&
          staffBreak.breaks.map((item, idx) => (
            <div className="BreaksFormField__content" key={idx}>
              <Select
                className="BreaksFormField__select"
                value={formatDataForSelectOption(item.start_time)}
                onChange={(option: any) => handleUpdateStartTime(option, item.id)}
                valueContainer={ValueContainer}
                options={options}
                selectStyle={hourStyles}
                isDisabled={isUpdating}
                isSearchable={false}
                isSelected
                option={ScrollOnOption}
              />
              to
              <Select
                className="BreaksFormField__select"
                value={formatDataForSelectOption(item.end_time)}
                onChange={(option: any) => handleUpdateEndTime(option, item.id)}
                valueContainer={ValueContainer}
                options={options}
                selectStyle={hourStyles}
                isDisabled={isUpdating}
                isSearchable={false}
                option={ScrollOnOption}
                isSelected
              />
              <button
                type="button"
                disabled={isDeleting || isAdding}
                className="BreaksFormField__delete"
                onClick={() => handleDeleteBreak(item.id)}
              >
                <Icon
                  className="BreaksFormField__trash"
                  icon={isDeleting && breakId === item.id ? 'spinner' : 'trash'}
                  spin={isDeleting && breakId === item.id}
                />
              </button>
              {breakId === item.id && (
                <ErrorMessage className="BreaksFormField__error" errorData={addError || deleteError || updateError} />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
