import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

export interface WorkingHoursInterface {
  day: string;
  start_time: string;
  end_time: string;
  status: boolean;
}
export interface AddEditWorkingHoursFields {
  monday: WorkingHoursInterface;
  tuesday: WorkingHoursInterface;
  wednesday: WorkingHoursInterface;
  thursday: WorkingHoursInterface;
  friday: WorkingHoursInterface;
  saturday: WorkingHoursInterface;
  sunday: WorkingHoursInterface;
}

interface UseAddEditWorkingHoursOptions {
  initialValues?: AddEditWorkingHoursFields;
  onSubmit: (
    values: AddEditWorkingHoursFields,
    formikHelpers: FormikHelpers<AddEditWorkingHoursFields>,
  ) => Promise<any>;
}

export const useAddEditWorkingHoursFormik = (options: UseAddEditWorkingHoursOptions) => {
  const AddEditWorkingHoursSchema = Yup.object().shape({});

  return useFormik({
    initialValues: {
      // workingHours: [],
      monday: { day: 'Monday', start_time: '', end_time: '', status: false },
      tuesday: { day: 'Tuesday', start_time: '', end_time: '', status: false },
      wednesday: { day: 'Wednesday', start_time: '', end_time: '', status: false },
      thursday: { day: 'Thursday', start_time: '', end_time: '', status: false },
      friday: { day: 'Friday', start_time: '', end_time: '', status: false },
      saturday: { day: 'Saturday', start_time: '', end_time: '', status: false },
      sunday: { day: 'Sunday', start_time: '', end_time: '', status: false },
      ...options.initialValues,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: AddEditWorkingHoursSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type AddEditWorkingHoursFormik = ReturnType<typeof useAddEditWorkingHoursFormik>;
