import { NoBorderButton } from '../shared/NoBorderButton/NoBorderButton';
import cs from 'classnames';

import './AppointmentNav.scss';

interface Props {
  route: string;
  onNavItemClick: (id: string) => void;
  isNavValid: boolean;
}

export const AppointmentNav = (props: Props) => {
  const { route, onNavItemClick, isNavValid } = props;

  const navItems = [
    { id: 'details', name: 'Details' },
    { id: 'customer', name: 'Customer', valid: isNavValid },
  ];

  return (
    <ul className="AppointmentNav">
      {navItems.map((navItem) => (
        <li>
          <NoBorderButton
            className={cs('AppointmentNav__link', { AppointmentNav__link_active: route === navItem.id })}
            onClick={() => onNavItemClick(navItem.id)}
            disabled={navItem.valid}
          >
            {navItem.name}
          </NoBorderButton>
        </li>
      ))}
    </ul>
  );
};
