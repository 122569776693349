import { ApolloError } from '@apollo/client';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { Skeleton } from '../Skeleton/Skeleton';

import './Title.scss';

interface Props {
  children?: string | JSX.Element;
  description?: string | null;
  loading?: boolean;
  error?: ApolloError;
}

export const Title = (props: Props) => {
  const { children, description } = props;
  if (props.loading) return <TitlePlaceholder />;
  if (props.error) return <ErrorMessage errorData={props.error} />;
  return (
    <div className="Title">
      <h1>{children}</h1>
      <span>{description}</span>
    </div>
  );
};

function TitlePlaceholder() {
  return (
    <>
      <Skeleton className="Title__placeholder" />
      <Skeleton className="Title__placeholder_description" />
    </>
  );
}
