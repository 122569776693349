import { AppointmentsByArtistsQuery, AppointmentType, ArtistsAppointments } from '../../graphql/generated/generated';

export interface CachedAppointments {
  appointmentsByArtists: ArtistsAppointments;
}

export const getAppointments = (
  cachedAppointments: AppointmentsByArtistsQuery | undefined,
  newAppointment?: AppointmentType | null,
) => {
  const cachedAppointmentsRows = cachedAppointments?.appointmentsByArtists.rows;

  if (!cachedAppointmentsRows?.length) return;
  //if provider already has appointments
  return cachedAppointmentsRows.map((row) => {
    if (parseInt(row.id) !== newAppointment?.provider?.id) return row;
    if (!row.appointments?.length)
      return {
        appointments: [newAppointment],
        email: row.email,
        full_name: row.full_name,
        id: row.id,
        role_id: row.role_id,
        __typename: 'UserType',
      };
    return {
      appointments: [...row.appointments, newAppointment],
      email: row.email,
      full_name: row.full_name,
      id: row.id,
      role_id: row.role_id,
      __typename: 'UserType',
    };
  });
};
