import React from 'react';
import { Form } from 'reactstrap';
import { StaffMembersDocument, StaffMembersType, useAddStaffMutation } from '../../graphql/generated/generated';
import { useAddStaffFormik } from '../../lib/hooks/formik/useAddStaffFormik';
import { ErrorMessage } from '../shared/ErrorMessage/ErrorMessage';
import { FormButtons } from '../shared/FormButtons/FormButtons';
import { Input } from '../shared/Input/Input';

import './AddStaffForm.scss';

interface Props {
  toggle: () => void;
}

export const AddStaffForm = (props: Props) => {
  const [staffMutation, { error }] = useAddStaffMutation();

  const formik = useAddStaffFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await staffMutation({
          variables: {
            data: {
              full_name: values.name,
              email: values.email,
              phone_number: values.mobile,
              description: values.description,
            },
          },
          update(cache, data) {
            const cachedData = cache.readQuery({ query: StaffMembersDocument }) as { staffMembers: StaffMembersType };
            if (!data.data || !data.data.addStaffMember) return;
            cache.writeQuery({
              query: StaffMembersDocument,
              data: {
                staffMembers: cachedData.staffMembers.rows.concat([data.data?.addStaffMember]),
              },
            });
          },
        });
        formikHelpers.resetForm();
        props.toggle && props.toggle();
      } catch (e) {}
    },
  });

  return (
    <div className="AddStaffForm">
      <Form inline onSubmit={formik.handleSubmit}>
        <Input
          className="AddStaffForm__input"
          type="text"
          name="name"
          id="name"
          placeholder="Name"
          wrapperClassName="AddStaffForm__wrapper_input"
          hiddenLabel
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.name}
          error={formik.errors.name}
          value={formik.values.name}
          invalid={formik.touched.name && !!formik.errors.name}
        />
        <Input
          className="AddStaffForm__input"
          type="email"
          name="email"
          id="email"
          placeholder="Email"
          wrapperClassName="AddStaffForm__wrapper_input"
          hiddenLabel
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.email}
          error={formik.errors.email}
          value={formik.values.email}
          invalid={formik.touched.email && !!formik.errors.email}
        />
        <Input
          className="AddStaffForm__input"
          type="text"
          name="mobile"
          id="mobile"
          placeholder="Mobile"
          wrapperClassName="AddStaffForm__wrapper_input"
          hiddenLabel
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.mobile}
          error={formik.errors.mobile}
          value={formik.values.mobile}
          invalid={formik.touched.mobile && !!formik.errors.mobile}
        />
        <Input
          className="AddStaffForm__input"
          type="textarea"
          name="description"
          id="description"
          placeholder="Description"
          wrapperClassName="AddStaffForm__wrapper_input"
          hiddenLabel
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.description}
          error={formik.errors.description}
          value={formik.values.description}
          invalid={formik.touched.description && !!formik.errors.description}
        />

        <FormButtons
          submitButtonName="Add staff"
          disabled={formik.isSubmitting || !formik.isValid}
          loading={formik.isSubmitting}
          submitClassName="AddStaffForm__submit"
          toggle={props.toggle}
        />

        <ErrorMessage errorData={error} />
      </Form>
    </div>
  );
};
