import {
  UserTimeOffDocument,
  UserTimeOffType,
  useTimeOffByIdQuery,
  useUpdateTimeOffMutation,
} from '../../../graphql/generated/generated';
import { formatDate } from '../../../lib/helpers/formatDate';
import { useAddEditTimeOffFormik } from '../../../lib/hooks/formik/useAddEditTimeOffFormik';
import { AddEditTimeOffForm } from '../AddEditTimeOffForm/AddEditTimeOffForm';

import './EditTimeOff.scss';

interface Props {
  toggle: () => void;
  staffId: string;
  timeOffId: string;
}

const currentDate = new Date();

export const EditTimeOff = (props: Props) => {
  const { staffId, timeOffId, toggle, ...rest } = props;

  const { loading, data, error } = useTimeOffByIdQuery({ variables: { id: parseInt(timeOffId) } });
  const [updateTimeOffMutation, { error: updateError }] = useUpdateTimeOffMutation();

  const formik = useAddEditTimeOffFormik({
    initialValues: {
      startDate: new Date(data?.userTimeOffById.start_date || currentDate),
      endDate: new Date(data?.userTimeOffById.end_date || currentDate),
      description: data?.userTimeOffById.description || '',
      startTime: null,
      endTime: null,
    },
    onSubmit: async (values, formikHelpers) => {
      // const startTime = getMinutesAndHourFromTime(values.startTime?.value);
      // const endTime = getMinutesAndHourFromTime(values.startTime?.value);
      // console.log(values.startDate?.toISOString());
      try {
        await updateTimeOffMutation({
          variables: {
            id: parseInt(timeOffId),
            data: {
              description: values.description,
              start_date: formatDate(values.startDate) || '',
              end_date: formatDate(values.endDate) || '',
              // end_date: values.endDate?.setHours(endTime.hour, endTime.minutes).toString() || '',
              // start_date: values.startDate?.setHours(startTime.hour, startTime.minutes).toString() || '',
            },
          },
          update(cache, data) {
            const cachedData = cache.readQuery({
              query: UserTimeOffDocument,
              variables: { id: parseInt(staffId) },
            }) as {
              userTimeOff: UserTimeOffType[];
            };

            const updatedTimeOff = cachedData.userTimeOff.filter(
              (timeOff) => timeOff.id !== data.data?.updateUserTimeOff.id,
            );

            if (!data.data?.updateUserTimeOff) return;
            cache.writeQuery({
              query: UserTimeOffDocument,
              variables: { id: parseInt(staffId) },
              data: { userTimeOff: updatedTimeOff.concat([data.data.updateUserTimeOff]) },
            });
          },
        });
        toggle();
        formikHelpers.resetForm();
      } catch (e) {}
    },
  });

  return (
    <AddEditTimeOffForm loading={loading} toggle={toggle} error={error || updateError} formik={formik} {...rest} />
  );
};
