import React from 'react';
import { enGB } from 'date-fns/locale';
import { DateChangeCallBack, DatePicker as ReactDatePicker } from 'react-nice-dates';
import { Icon, IconDefinition } from '../Icon/Icon';
import cs from 'classnames';

import 'react-nice-dates/build/style.css';
import './DatePicker.scss';

interface Props {
  className?: string;
  inputClassName?: string;
  label?: string;
  icon?: IconDefinition;
  format?: string;
  date: Date | undefined;
  onDateChange: DateChangeCallBack;
  id: string;
  minimumDate?: string;
  maximumDate?: string;
  placeholder?: string;
  error?: any;
  touched?: boolean;
}

export const DatePicker = (props: Props) => {
  return (
    <>
      <ReactDatePicker
        date={props.date}
        format={props.format || 'dd MMMM yyyy'}
        onDateChange={(date) => props.onDateChange(date)}
        locale={enGB}
      >
        {({ inputProps }) => {
          const { placeholder, ...rest } = inputProps;
          return (
            <div className={cs('DatePicker', props.className)}>
              <input
                className={cs('DatePicker__field', props.inputClassName)}
                placeholder={props.placeholder}
                {...rest}
              />

              <label htmlFor={props.id}>
                <Icon className="DatePicker__icon" icon={props.icon} />
              </label>
            </div>
          );
        }}
      </ReactDatePicker>
      {props.touched && props.error && <pre className="DatePicker__error">{props.error}</pre>}
    </>
  );
};
