import { useServicesByStaffQuery } from '../../graphql/generated/generated';
import { AppointmentFormik } from '../../lib/hooks/formik/useAppointmentFormik';
import { Input } from '../shared/Input/Input';
import { getMinutesFromTime } from '../../lib/helpers/getMinutesFromTime';
import { getInputProps } from './utils/getInputProps';
import { ProvidersSelect } from '../shared/ProvidersSelect/ProvidersSelect';
import { components, OptionsType, OptionTypeBase, ValueContainerProps } from 'react-select';
import { ServicesWithDetailsSelect } from '../ServicesWithDetailsSelect/ServicesWithDetailsSelect';
import { InputWithElement } from '../shared/InputWithElement/InputWithElement';
import { DatePickerWithTime } from '../DatePickerWithTime/DatePickerWithTime';
import { NoBorderButton } from '../shared/NoBorderButton/NoBorderButton';
import { Button } from '../shared/Button/Button';
import { ApolloError } from '@apollo/client';

import './AppointmentDetailsForm.scss';

interface Props {
  toggle: () => void;
  formik: AppointmentFormik;
  onRouteChange: (route: string) => void;
  providerStatus: { providerLoading: boolean; providerError?: ApolloError };
}

export const AppointmentDetailsForm = (props: Props) => {
  const { formik, onRouteChange, toggle, providerStatus } = props;
  const { loading, data, error } = useServicesByStaffQuery({
    variables: { id: formik.values.details.provider?.value || '' },
  });

  const selectedService = data?.services?.rows?.find((row) => row.id === formik.values.details?.service?.value);

  const handleClick = () => {
    if (!formik.isValid) onRouteChange('customer');
    formik.submitForm();
  };

  const handleCancelClick = () => {
    formik.resetForm();
    toggle();
  };

  const services = data?.services.rows?.map((row) => ({
    label: row.name,
    value: row.id,
    color: row.color,
    time: row.time,
    cost: row.cost,
  }));


  const handleServiceClick = (value: any) => {
    formik.setFieldTouched('details.service');
    formik.setFieldValue('details.price', value?.cost);
    formik.setFieldValue('details.duration', getMinutesFromTime(value?.time));
    formik.setFieldValue('details.service', value);
  };

  return (
    <div className="AppointmentDetailsForm">
      <ProvidersSelect
        className="AppointmentDetailsForm__select"
        key="details.provider"
        label="Provider"
        id="details.provider"
        name="details.provider"
        valueContainer={ValueContainer}
        isClearable
        loading={providerStatus.providerLoading}
        onChange={(value: OptionTypeBase | OptionsType<OptionTypeBase> | null) => {
          formik.setFieldValue('details.provider', value);

          formik.setFieldValue('details.service', null, true);
          formik.setFieldTouched('details.provider');
        }}
        onBlur={() => {
          formik.setFieldTouched('details.provider');
        }}
        touched={formik.touched.details?.provider}
        error={formik.errors.details?.provider || providerStatus.providerError?.message}
        value={formik.values.details?.provider}
        isSearchable={false}
      />

      <ServicesWithDetailsSelect
        className="AppointmentDetailsForm__select"
        key="details.service"
        label="Service"
        id="details.service"
        name="details.service"
        options={services}
        providerId={formik.values.details.provider?.value}
        valueContainer={ValueContainer}
        isClearable
        onChange={handleServiceClick}
        onBlur={() => {
          formik.setFieldTouched('details.service');
        }}
        loading={loading}
        touched={formik.touched.details?.service}
        error={formik.errors.details?.service || error?.message}
        value={formik.values.details?.service}
        isSearchable={false}
      />

      {selectedService && (
        <>
          <div className="AppointmentDetailsForm__service">
            <InputWithElement
              className="AppointmentDetailsForm__input"
              type="number"
              name="details.price"
              id="details.price"
              min="0"
              placeholder="Price"
              hiddenLabel={false}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.details?.price}
              error={formik.errors.details?.price}
              value={formik.values.details?.price}
              invalid={formik.touched.details?.price && !!formik.errors.details?.price}
              renderElement={<span>€</span>}
            />

            <InputWithElement
              className="AppointmentDetailsForm__input"
              type="number"
              name="details.duration"
              id="details.duration"
              placeholder="Duration"
              hiddenLabel={false}
              min="0"
              onChange={(e) => formik.setFieldValue('details.duration', parseInt(e.target.value))}
              onBlur={formik.handleBlur}
              touched={formik.touched.details?.duration}
              error={formik.errors.details?.duration}
              value={formik.values.details.duration}
              invalid={formik.touched.details?.duration && !!formik.errors.details?.duration}
              renderElement={<span>mins</span>}
            />
          </div>
          <div className="AppointmentDetailsForm__content">
            {!formik.isValid ? (
              <>
                <p>Discount</p>
                <InputWithElement
                  className="AppointmentDetailsForm__input discount"
                  type="number"
                  name="discount.price"
                  id="discount.price"
                  min="0"
                  placeholder="Discount"
                  hiddenLabel={false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.details?.discount}
                  renderElement={<span>€</span>}
                />
              </>
            ) : null}
          </div>
        </>
      )}
      <div className="AppointmentDetailsForm__time">
        <label>Date</label>
        <DatePickerWithTime
          className="AppointmentDetailsForm__date"
          date={formik.values.details.date}
          onDateChange={(date) => formik.setFieldValue('details.date', date)}
          formik={formik}
        />
      </div>

      <Input
        {...getInputProps({
          key: 'notes',
          label: 'Notes',
          placeholder: 'Notes for the Customer',
          type: 'textarea',
          formik,
          className: 'AppointmentDetailsForm__notes',
          inputClassName: 'AppointmentDetailsForm__notes_field',
        })}
      />
      <div className="AppointmentDetailsForm__buttons">
        <NoBorderButton onClick={handleCancelClick}>Close</NoBorderButton>
        <Button
          className="AppointmentDetailsForm__submit"
          onClick={handleClick}
          type="button"
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting || !!formik.errors.details}
        >
          {formik.isValid ? 'Save Appointment' : 'Continue'}
        </Button>
      </div>
    </div>
  );
};

export function ValueContainer(props: ValueContainerProps<any, any>) {
  return <components.ValueContainer {...props} />;
}
