import { AddNewButton } from '../../shared/AddNewButton/AddNewButton';
import './AddTimeOffView.scss';

interface Props {
  toggleModal: () => void;
}

export const AddTimeOffView = (props: Props) => {
  return (
    <div className="AddTimeOffView">
      <div className="AddTimeOffView__add">
        <div className="AddTimeOffView__description">Add time off by clicking the button below.</div>
        <div className="AddTimeOffView__button">
          <label>Add Time Off</label>
          <AddNewButton onClick={() => props.toggleModal()} />
        </div>
      </div>
    </div>
  );
};
