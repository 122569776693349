import React, { useState } from 'react';
import { AddCategoryForm } from '../AddCategoryForm/AddCategoryForm';
import { AddNewButton } from '../../shared/AddNewButton/AddNewButton';
import { Dropdown } from '../../shared/Dropdown/Dropdown';
import { Sidebar } from '../../shared/Sidebar/Sidebar';
import {
  CategoriesDocument,
  CategoryType,
  useCategoriesQuery,
  useDeleteCategoryMutation,
} from '../../../graphql/generated/generated';
import { useRouter } from '../../../lib/hooks/useRouter';

import './ServicesSidebar.scss';
import { useWindowWidth } from '../../../lib/hooks/useWindowWidth';
import { SidebarServiceList } from '../../SidebarServiceList/SidebarServiceList';
import { NoBorderButton } from '../../shared/NoBorderButton/NoBorderButton';

interface Props {}

export const ServicesSidebar = (props: Props) => {
  const router = useRouter();
  const { loading, data, error } = useCategoriesQuery();
  const [deleteCategoryMutation, deletingCategoryResponse] = useDeleteCategoryMutation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const width = useWindowWidth();

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleDeleteClick = async (id: string) => {
    const paramId = router.location.pathname.split('/')[2];
    try {
      await deleteCategoryMutation({
        variables: { id: parseInt(id) },
        update(cache) {
          const cachedData = cache.readQuery({ query: CategoriesDocument }) as { categories: CategoryType[] };
          cache.writeQuery({
            query: CategoriesDocument,
            data: { categories: cachedData.categories.filter((category) => category.id !== id) },
          });
        },
      });
    } catch (e) {
    } finally {
      if (paramId === id) {
        router.history.push('/services');
      }
    }
  };
  return (
    <>
      {width > 992 ? (
        <SidebarServiceList />
      ) : (
        <Dropdown
          className="CustomersSidebar__dropdown"
          menuClassName="CustomersSidebar__menu"
          nav
          isOpen={dropdownOpen}
          toggle={toggleDropdown}
          toggleButton={
            <div className="CustomersSidebar__toggle_list">
              {' '}
              <NoBorderButton className="CustomerSidebar__view">View Services</NoBorderButton>
            </div>
          }
          items={<SidebarServiceList dropdownToggle={toggleDropdown} />}
        />
      )}
    </>
  );
};
