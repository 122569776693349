import { GetUserBreaksType, useUserBreaksQuery } from '../../graphql/generated/generated';
import { AddBreaksFields } from '../../lib/hooks/formik/useAddBreaksFormik';
import { BreaksFormField } from '../BreaksFormField/BreaksFormField';
import { ErrorMessage } from '../shared/ErrorMessage/ErrorMessage';
import { Skeleton } from '../shared/Skeleton/Skeleton';

import './BreaksForm.scss';

interface Day {
  key: keyof AddBreaksFields;
  label: string;
}

interface Props {
  id: string;
}

const days: Day[] = [
  { key: 'monday', label: 'Monday' },
  { key: 'tuesday', label: 'Tuesday' },
  { key: 'wednesday', label: 'Wednesday' },
  { key: 'thursday', label: 'Thursday' },
  { key: 'friday', label: 'Friday' },
  { key: 'saturday', label: 'Saturday' },
  { key: 'sunday', label: 'Sunday' },
];

export const BreaksForm = (props: Props) => {
  const { loading, data, error } = useUserBreaksQuery({ variables: { id: parseInt(props.id) } });

  const staffDaysWithBreaks: GetUserBreaksType[] = days.map((day) => {
    return (
      data?.userBreaks.find((item) => item.day === day.label) || {
        __typename: 'GetUserBreaksType',
        breaks: [],
        day: day.label,
        user_id: parseInt(props.id),
      }
    );
  });

  if (loading) return <BreaksFormPlaceholder />;
  return (
    <div className="BreaksForm">
      {staffDaysWithBreaks &&
        staffDaysWithBreaks.length !== 0 &&
        staffDaysWithBreaks.map((day, idx) => (
          <BreaksFormField
            key={`Breaks-${idx}`}
            breaks={day}
            id={day.day.toLowerCase() as keyof AddBreaksFields}
            label={day.day}
          />
        ))}
      <ErrorMessage errorData={error} />
    </div>
  );
};

function BreaksFormPlaceholder() {
  return (
    <div className="BreaksForm__placeholder">
      <div className="BreaksForm__skeleton">
        <Skeleton height="1.875rem" count={7} />
      </div>
      <div className="BreaksForm__skeleton">
        <Skeleton height="1.875rem" count={7} />
      </div>
      <div className="BreaksForm__skeleton">
        <Skeleton height="1.875rem" count={7} />
      </div>
      <div className="BreaksForm__skeleton">
        <Skeleton height="1.875rem" count={7} />
      </div>
      <Skeleton className="BreaksForm__delete" height="1.875rem" count={7} />
    </div>
  );
}
