import { Input } from '../../shared/Input/Input';
import { AppointmentFormik, CustomerFields } from '../../../lib/hooks/formik/useAppointmentFormik';
import { InputType } from 'reactstrap/es/Input';
import { Icon } from '../../shared/Icon/Icon';

import './CustomerDetailsForm.scss';
import { DatePicker } from '../../shared/DatePicker/DatePicker';

export interface GetInputProps {
  key: keyof CustomerFields;
  type: InputType | undefined;
  placeholder: string;
  label: string;
  className?: string;
  formik: AppointmentFormik;
  min?: string;
}

interface Props {
  formik: AppointmentFormik;
  onCloseClick: () => void;
}

export const CustomerDetailsForm = (props: Props) => {
  const { formik, onCloseClick } = props;

  return (
    <div className="CustomerDetailsForm">
      <button className="CustomerDetailsForm__button" type="button" onClick={onCloseClick}>
        <Icon className="CustomerDetailsForm__close" icon="close" />
      </button>
      <div className="CustomerDetailsForm__form">
        <Input
          className="CustomerDetailsForm__input"
          inputClassName="CustomerDetailsForm__field"
          type="text"
          name="addCustomer.name"
          label="Name"
          id="addCustomer.name"
          placeholder="Name"
          hiddenLabel={false}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.addCustomer?.name}
          error={formik.errors.addCustomer?.name}
          value={formik.values.addCustomer?.name}
          invalid={formik.touched.addCustomer?.name && !!formik.errors.addCustomer?.name}
        />
        <Input
          className="CustomerDetailsForm__input"
          inputClassName="CustomerDetailsForm__field"
          type="email"
          name="addCustomer.email"
          label="Email"
          id="addCustomer.email"
          placeholder="Email"
          hiddenLabel={false}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.addCustomer?.email}
          error={formik.errors.addCustomer?.email}
          value={formik.values.addCustomer?.email}
          invalid={formik.touched.addCustomer?.email && !!formik.errors.addCustomer?.name}
        />
        <Input
          className="CustomerDetailsForm__input"
          inputClassName="CustomerDetailsForm__field"
          type="text"
          name="addCustomer.mobile"
          label="Mobile"
          id="addCustomer.mobile"
          placeholder="Mobile"
          hiddenLabel={false}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.addCustomer?.mobile}
          error={formik.errors.addCustomer?.mobile}
          value={formik.values.addCustomer?.mobile}
          invalid={formik.touched.addCustomer?.mobile && !!formik.errors.addCustomer?.mobile}
        />
        <div className="CustomerDetailsForm__birthDate">
          <p>Date </p>
          <DatePicker
            className="CustomerDetailsForm__datepicker"
            id="addCustomer.birthday"
            date={formik.values.addCustomer?.birthday}
            placeholder="Date of birth"
            onDateChange={(options) => {
              formik.setFieldValue('addCustomer.birthday', options);
              formik.setFieldTouched('birthday');
            }}
          />
        </div>
      </div>
    </div>
  );
};
