import { Route, RouteProps, Redirect } from 'react-router-dom';
import { Footer } from '../components/partials/Footer/Footer';
import { Header } from '../components/partials/Header/Header';
import { useAuthContext } from '../lib/context/AuthContext/AuthContext';
import { Content } from '../components/partials/Content/Content';
import { useUIContext } from '../lib/context/UIContext/UIContext';
import { NavSidebar } from '../components/partials/NavSidebar/NavSidebar';
import { useWindowWidth } from '../lib/hooks/useWindowWidth';
import cs from 'classnames';

import './PrivateRoute.scss';

export const PrivateRoute = (props: RouteProps) => {
  const authCtx = useAuthContext();
  const { isSidebarOpen } = useUIContext();
  const width = useWindowWidth();

  if (!authCtx.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <div className={cs('PrivateRoute', width > 992 ? isSidebarOpen : !isSidebarOpen && 'PrivateRoute--closed-sidebar')}>
      <Header />
      <NavSidebar />
      <Content>
        <Route {...props} />
      </Content>
      <Footer />
    </div>
  );
};
