import React, { useState } from 'react';

import {
  useStaffMembersQuery,
  useDeleteStaffMutation,
  StaffMembersDocument,
  StaffMembersType,
} from '../../../graphql/generated/generated';
import { useWindowWidth } from '../../../lib/hooks/useWindowWidth';
import { AddStaffForm } from '../../AddStaffForm/AddStaffForm';
import { AddNewButton } from '../../shared/AddNewButton/AddNewButton';
import { Dropdown } from '../../shared/Dropdown/Dropdown';
import { Sidebar } from '../../shared/Sidebar/Sidebar';

interface Props {
  dropdownToggle?: () => void;
}
export const StaffSideBarItem = (props: Props) => {
  const { loading, data, error } = useStaffMembersQuery();
  const [deleteStaff, deleteStaffResponse] = useDeleteStaffMutation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const sidebarItems = data?.staffMembers.rows.map((row) => ({ id: row.id, name: row.full_name }));

  const handleDeleteItem = async (id: string) => {
    await deleteStaff({
      variables: { id: parseInt(id) },
      update(cache) {
        const cachedData = cache.readQuery({ query: StaffMembersDocument }) as { staffMembers: StaffMembersType };
        cache.writeQuery({
          query: StaffMembersDocument,
          data: { staffMembers: cachedData.staffMembers.rows.filter((staff) => staff.id !== id) },
        });
      },
    });
  };
  return (
    <div>
      <Sidebar
        title="Staff"
        deleting={deleteStaffResponse}
        loading={loading}
        onDeleteItem={handleDeleteItem}
        items={sidebarItems}
        addNewButton={
          <Dropdown
            title="Add New staff"
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            items={<AddStaffForm toggle={toggleDropdown} />}
            toggleButton={<AddNewButton />}
          />
        }
        error={error}
        withInput
        dropdownToggle={props.dropdownToggle}
      />
    </div>
  );
};
