import React from 'react';
import cs from 'classnames';
import { HeaderProps } from 'react-big-calendar';

import './CalendarHeader.scss';

function truncateString(str: string, num: number) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num);
}
export const CalendarHeader = (props: HeaderProps) => {
  const isCurrentDate = () => {
    const calendarDate = props.date.toString().split(' ').slice(0, 4);
    const currentDate = new Date().toString().split(' ').slice(0, 4);

    return calendarDate.every((element, index) => element === currentDate[index]);
  };
  isCurrentDate();
  return (
    <div className="CalendarHeader">
      <div className={cs('CalendarHeader__wrapper', isCurrentDate() && 'CalendarHeader__today')}>
        <label className="CalendarHeader__day">{props.label?.split(' ')[1]?.toUpperCase()}</label>
        <label className="CalendarHeader__number">{truncateString(props.label?.split(' ')[0].toUpperCase(), 3)}</label>
      </div>
    </div>
  );
};
