import { useAuthContext } from '../../../../lib/context/AuthContext/AuthContext';
import { NavSidebarItem } from '../NavSidebarItem/NavSidebarItem';

import './NavSidebarList.scss';
interface Props {
  toggle: () => void;
}
export const NavSidebarList = (props: Props) => {
  const authCtx = useAuthContext();

  const isAdmin = authCtx.user?.role_name === 'Admin';

  const sideNavItems = [
    { label: 'Calendar', path: '', icon: 'calendar', show: true },
    { label: 'Customers', path: 'customers', icon: 'profile', show: isAdmin },
    { label: 'Services', path: 'services', icon: 'services', show: isAdmin },
    { label: 'Reports', path: 'reports', icon: 'reports', show: isAdmin },
  ];
  return (
    <ul className="NavSidebarList">
      {sideNavItems.map((item, idx) => {
        if (!item.show) return null;
        return (
          <li key={`${item.path}-${idx}`}>
            <NavSidebarItem toggle={props.toggle} data={item} />
          </li>
        );
      })}
    </ul>
  );
};
