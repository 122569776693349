import { ApolloError } from '@apollo/client';
import { OptionsType, OptionTypeBase } from 'react-select';
import { AppointmentDetailsFields, AppointmentFormik } from '../../../lib/hooks/formik/useAppointmentFormik';
import { Option } from '../../shared/Select/Select';

export interface GetServicesSelectProps {
  key: keyof AppointmentDetailsFields;
  label?: string;
  formik: AppointmentFormik;
  valueContainer?: any;
  isClearable: boolean;
  className?: string;
  placeholder?: string;
  error?: ApolloError;
  loading?: boolean;
  providerId?: string;
}

export const getSelectProps = ({ key, formik, error, ...props }: GetServicesSelectProps) => {
  const nestedKey = `details.${key}`;
  return {
    className: 'AppointmentDetailsForm__select',
    key: key,
    name: key,
    id: key,
    onChange: (value: OptionTypeBase | OptionsType<OptionTypeBase> | null) => {
      formik.setFieldValue(nestedKey, value);
      formik.setFieldTouched(key);
    },
    onBlur: () => {
      formik.setFieldTouched(key);
    },
    touched: formik.touched.details?.[key],
    error: formik.errors.details?.[key] || error,
    value: formik.values.details?.[key] as Option,
    isSearchable: false,
    ...props,
  };
};
