import { Form } from 'reactstrap';
import { ServicesByStaffDocument, useAssignServicesMutation } from '../../../graphql/generated/generated';
import { useAssignServiceFormik } from '../../../lib/hooks/formik/useAssignServiceToStaffFormik';
import { ServicesSelect } from '../../ServicesSelect/ServicesSelect';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import { FormButtons } from '../../shared/FormButtons/FormButtons';
import { Option } from '../../shared/Select/Select';

import './AssignServiceToStaffForm.scss';

interface Props {
  id: string;
  toggle: () => void;
  assignedServices?: Option[];
}

export const AssignServiceToStaffForm = (props: Props) => {
  const [assignServicesMutation, { error }] = useAssignServicesMutation();

  const formik = useAssignServiceFormik({
    initialValues: {
      services: props.assignedServices || [],
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        await assignServicesMutation({
          variables: {
            data: { services: values.services?.map((service) => parseInt(service.value)) },
            id: parseInt(props.id),
          },
          update(cache, data) {
            cache.writeQuery({ query: ServicesByStaffDocument, variables: { id: props.id }, data: { services: data } });
          },
        });
        props.toggle && props.toggle();
        formikHelpers.resetForm();
      } catch (e) {}
    },
  });

  return (
    <div className="AssignServiceToStaffForm">
      <Form inline onSubmit={formik.handleSubmit}>
        <ServicesSelect
          isMulti
          closeMenuOnSelect={false}
          value={formik.values.services}
          className="AssignServiceToStaffForm__select"
          onChange={(option) => formik.setFieldValue('services', option)}
        />
        <FormButtons
          submitClassName="AssignServiceToStaffForm__submit"
          submitButtonName="Assign services"
          disabled={formik.isSubmitting}
          loading={formik.isSubmitting}
          toggle={props.toggle}
        />

        <ErrorMessage errorData={error} />
      </Form>
    </div>
  );
};
