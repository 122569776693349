import { useEffect, useState } from 'react';
import { View } from 'react-big-calendar';
import { AppointmentInfo, AppointmentModal } from '../../components/AppointmentModal/AppointmentModal';
import { EditAppointmentType } from '../../components/shared/AppointmentDetails/AppointmentDetails';
import { BigCalendar, SlotInformation } from '../../components/shared/BigCalendar/BigCalendar';
import { Event } from '../../components/shared/BigCalendar/Event/Event';
import { Toolbar } from '../../components/shared/BigCalendar/Toolbar/Toolbar';
import { ErrorMessage } from '../../components/shared/ErrorMessage/ErrorMessage';
import {
  useAppointmentsByArtistsQuery,
  useBreaksForCalendarQuery,
  useStaffMembersQuery,
  useTimeOffsForCalendarQuery,
  useUserWorkingHoursForCalendarQuery,
} from '../../graphql/generated/generated';
import { useAuthContext } from '../../lib/context/AuthContext/AuthContext';
import { calendarLocalizer } from '../../lib/helpers/calendarLocalizer';
import { convertView } from '../../lib/helpers/convertView';
import { getBreaksEvents } from '../../lib/helpers/getBreaksEvents';
import { getDateRange } from '../../lib/helpers/getDateRange';
import { getEvents } from '../../lib/helpers/getEvents';
import { getTimeOffsEvents } from '../../lib/helpers/getTimeOffsEvents';
import { getVariablesForTimeOffs } from '../../lib/helpers/getVariablesForTimeOffs';
import { getWorkingHours } from '../../lib/helpers/getWorkingHours';
import { useLiveUpdates } from '../../lib/hooks/useLiveUpdates';

import './Calendar.scss';

export const Calendar = () => {
  const authCtx = useAuthContext();
  const [view, setView] = useState<View>('day');
  const [date, setDate] = useState<Date>(new Date());
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [appointmentInfo, setAppointmentInfo] = useState<AppointmentInfo>();
  const [appointmentToEdit, setAppointmentToEdit] = useState<EditAppointmentType>();
  const { loading: breaksLoading, data: breaks, error: breaksError } = useBreaksForCalendarQuery();
  const { loading, data, error } = useStaffMembersQuery({ variables: { limit: 7, page: pageNumber } });
  const [selectedStaff, setSelectedStaff] = useState<Array<string>>(() => {
    if (authCtx.user?.role_name === 'Artist') return [authCtx.user?.id];
    return [''];
  });
  const { loading: whLoading, data: workingHours, error: whError } = useUserWorkingHoursForCalendarQuery();

  const dateRange = getDateRange(date, view);
  const convertedView = convertView(view);
  const options = {
    view_type: convertedView,
    provider_id: parseInt(selectedStaff?.[0] || ''),
    date: view === 'day' ? date.toISOString().slice(0, 10) : null,
    start_date: dateRange?.startDate,
    end_date: dateRange?.endDate,
  };

  const variablesForTimeOffs = getVariablesForTimeOffs(view, date);
  const {
    loading: timeOffsLoading,
    data: timeOffs,
    error: timeOffsError,
  } = useTimeOffsForCalendarQuery({
    variables: { data: variablesForTimeOffs },
  });

  const {
    loading: isLoading,
    data: appointments,
    error: isError,
    fetchMore,
  } = useAppointmentsByArtistsQuery({
    variables: {
      options: options,
    },
  });

  //fetch when changing date
  useEffect(() => {
    fetchMore({
      variables: {
        options: {
          view_type: convertedView,
          provider_id: parseInt(selectedStaff?.[0] || ''),
          date: convertedView === 'Daily' ? date.toISOString().slice(0, 10) : null,
          start_date: dateRange?.startDate,
          end_date: dateRange?.endDate,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertedView, date, dateRange, selectedStaff]);

  //live updates on calendar
  useLiveUpdates(appointments, options);
  //timeOffs to show on Calendar
  const timeOffEvents = getTimeOffsEvents(timeOffs, view);
  //breaks to show on Calendar
  const breaksEvents = getBreaksEvents(breaks, date, view, selectedStaff?.[0]);

  const workingHoursEvents = getWorkingHours(workingHours, date, view, selectedStaff?.[0]);
  //events to show on Calendar
  const appointmentEvents = getEvents(appointments);
  //find provider from selectBox
  const provider = data?.staffMembers.rows.find((member) => member.id === selectedStaff?.[0]);
  //show provider which is selected on Calendar
  const selectedResource = [{ resourceId: parseInt(provider?.id || ''), resourceTitle: provider?.full_name || '' }];
  //get staffMembers to show on Calendar as resources of Calendar
  const staffMembers = data?.staffMembers.rows.map((row) => {
    return {
      resourceId: parseInt(row.id),
      resourceTitle: row.full_name,
    };
  }) || [
    {
      resourceId: +selectedStaff?.[0],
      resourceTitle: authCtx.user?.full_name || '',
    },
  ];

  // open or close appointment modal
  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
    setAppointmentToEdit(undefined);
  };

  const handlePageChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const addAppointment = (slotInfo: SlotInformation) => {
    const { start, end, resourceId } = slotInfo;
    if (!resourceId) return;
    toggleModal();
    setAppointmentInfo({
      start,
      end,
      providerId: resourceId,
    });
  };

  const handleProviderChange = (staffId: Array<string>) => {
    setSelectedStaff(staffId);
  };

  const handleDateChange = (_: any, date: Date | undefined) => {
    if (!date) return;
    setDate(date);
  };

  const handleViewChange = (view: View) => {
    setView(view);
  };

  const editAppointment = (appointment: EditAppointmentType) => {
    if (!appointment) return;
    setAppointmentToEdit(appointment);
    setIsModalOpen((prevState) => !prevState);
  };

  const workingHoursFilteredEvents = workingHoursEvents.filter((workingHour) => {
    return !timeOffEvents.find((timeOff) => {
      return (
        workingHour.start.toLocaleDateString() === timeOff.start.toLocaleDateString() &&
        workingHour.resourceId === timeOff.resourceId
      );
    });
  });

  const isStaff = authCtx.user?.role_name === 'Artist';
  return (
    <div className="Calendar">
      <BigCalendar
        view={view}
        localizer={calendarLocalizer}
        resourceMap={
          isStaff
            ? [
                {
                  resourceId: parseInt(authCtx.user?.id || ''),
                  resourceTitle: authCtx.user?.full_name || '',
                },
              ]
            : provider
            ? selectedResource
            : staffMembers
        }
        events={[...appointmentEvents, ...timeOffEvents, ...breaksEvents, ...workingHoursFilteredEvents]}
        staff={data?.staffMembers.rows}
        onSlotSelection={addAppointment}
        components={{
          toolbar: (toolBarProps) => (
            <Toolbar
              staff={data?.staffMembers}
              pageNumber={pageNumber}
              selectHeader={provider?.full_name}
              selectValue={selectedStaff}
              onPageChange={handlePageChange}
              onProviderChange={handleProviderChange}
              onDateChange={handleDateChange}
              onViewChange={handleViewChange}
              calendarLoading={isLoading || loading || timeOffsLoading || breaksLoading || whLoading}
              error={error || timeOffsError || breaksError || whError}
              loading={loading}
              {...toolBarProps}
              date={date}
            />
          ),
          event: (eventProps) => <Event onEditClick={editAppointment} options={options} {...eventProps} />,
        }}
      />
      <AppointmentModal
        appointmentInfo={appointmentInfo}
        appointmentToEdit={appointmentToEdit}
        isOpen={isModalOpen}
        toggle={toggleModal}
        view={convertedView}
        date={date}
        title="Appointment"
      />

      <ErrorMessage errorData={isError} />
    </div>
  );
};
