import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Table } from '../../shared/Table/Table';
import { TableWrapper } from '../../shared/TableWrapper/TableWrapper';
import {
  CategoryDocument,
  CategoryType,
  useCategoryQuery,
  // useDeleteServiceMutation,
} from '../../../graphql/generated/generated';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import { AddEditServiceModal } from '../AddEditServiceModal/AddEditServiceModal';
import { getMinutesFromTime } from '../../../lib/helpers/getMinutesFromTime';
import { useConfirmation } from '../../../lib/context/ConfirmationContext/ConfirmationContext';
import { Icon } from '../../shared/Icon/Icon';

import './ServicesById.scss';

interface Props {}

const columns = [
  { key: 'color', header: '', width: 15 },
  { key: 'name', header: '', width: 705 },
  { key: 'time', header: '', width: 315 },
  { key: 'cost', header: '', width: 60 },
  { key: 'delete', header: '', width: 35 },
];

export const ServicesById = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  // const [deleteServiceMutation, { loading: deleting, error: deleteError }] = useDeleteServiceMutation();
  const { loading, data, error } = useCategoryQuery({ variables: { id: parseInt(id) } });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [serviceId, setServiceId] = useState<string>();
  const confirmation = useConfirmation();

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleDeleteClick = async (serviceId: string) => {
    try {
      // await deleteServiceMutation({
      //   variables: { id: parseInt(serviceId) },
      //   update(cache) {
      //     const cachedData = cache.readQuery({ query: CategoryDocument, variables: { id: parseInt(id) } }) as {
      //       category: CategoryType;
      //     };
      //     cache.writeQuery({
      //       query: CategoryDocument,
      //       variables: { id: parseInt(id) },
      //       data: { category: cachedData.category.services?.filter((service) => service.id !== id) },
      //     });
      //   },
      // });
    } catch (e) {}
  };

  const rows = data?.category.services?.map((row) => ({
    id: row.id,
    name: row.name,
    color: <div style={{ width: '0.313rem', height: '1.125rem', backgroundColor: row.color }}></div>,
    cost: row.cost ? `${row.cost} €` : '-',
    time: `${getMinutesFromTime(row.time)} mins`,
    delete: (
      <Icon
        className="AllServices__delete"
        onClick={(e) => {
          e.stopPropagation();
          confirmation.confirm('Are you sure you want to delete?').then(() => {
            handleDeleteClick(row.id);
          });
        }}
        // spin={deleting}
        // icon={deleting ? 'spinner' : 'trash'}
      />
    ),
  }));

  const handleRowClick = (id: string) => {
    setServiceId(id);
    toggleModal();
  };

  const resetServiceId = () => setServiceId(undefined);

  return (
    <div className="ServicesById">
      <TableWrapper title="Services" toggleModal={toggleModal}>
        <Table
          className="ServicesById__table"
          loading={loading}
          error={error}
          columns={columns}
          rows={rows}
          onRowClick={handleRowClick}
        />
      </TableWrapper>
      <ErrorMessage
      // errorData={deleteError}
      />
      <AddEditServiceModal
        serviceId={serviceId}
        resetServiceId={resetServiceId}
        categoryId={id}
        isOpen={isModalOpen}
        toggle={toggleModal}
      />
    </div>
  );
};
