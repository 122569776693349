import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

export interface AddCategoryFields {
  category: string;
}

interface UseLoginFormOptions {
  initialValues?: AddCategoryFields;
  onSubmit: (values: AddCategoryFields, formikHelpers: FormikHelpers<AddCategoryFields>) => Promise<any>;
}

export const useAddCategoryFormik = (options: UseLoginFormOptions) => {
  const LoginSchema = Yup.object().shape({
    category: Yup.string().required('Category is required'),
  });

  return useFormik({
    initialValues: {
      category: '',
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: LoginSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type CategoryFormik = ReturnType<typeof useAddCategoryFormik>;
