import { ApolloError } from '@apollo/client';
import { AppointmentType } from '../../../graphql/generated/generated';
import { transformDate } from '../../../lib/helpers/transformDate';
import { transformTime } from '../../../lib/helpers/transformTime';
import { SeeMoreButton } from '../../SeeMoreButton/SeeMoreButton';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import { Icon } from '../../shared/Icon/Icon';
import { NoDataFound } from '../../shared/NoDataFound/NoDataFound';
import { Popover } from '../../shared/Popover/Popover';
import { Skeleton } from '../../shared/Skeleton/Skeleton';
import { Table } from '../../shared/Table/Table';
import { AppointmentDetails } from '../../shared/AppointmentDetails/AppointmentDetails';

import './AppointmentsTable.scss';

interface Props {
  appointments: AppointmentType[];

  error?: ApolloError;
  loading: boolean;
}

const columns = [
  {
    key: 'calendar',
    header: '',
    width: 49,
  },
  {
    key: 'details',
    header: '',
    width: 552,
  },
  { key: 'more', header: '', width: 30 },
];

export const AppointmentsTable = (props: Props) => {
  const appointments = props.appointments?.map((appointment) => {
    const date = transformDate(appointment.date);
    const time = transformTime(appointment.start_time, appointment?.end_time);

    return {
      calendar: <Icon className="CustomerAppointments__icon" icon="calendar" />,
      details: (
        <>
          <div className="CustomerAppointments__time">
            {date} {time}
          </div>
          <div className="CustomerAppointments__appointment_detail">
            {appointment.service?.name} / w {appointment.provider?.full_name}. {appointment.service?.time}.{' '}
            {appointment.cost} €
          </div>
        </>
      ),
      more: (
        <>
          <SeeMoreButton
            onClick={(e) => {
              e.currentTarget.focus();
            }}
            id={`PopoverFocus-${appointment.id}`}
            type="button"
          />{' '}
          <Popover
            className="AppointmentsTable__popover"
            target={`PopoverFocus-${appointment.id}`}
            trigger="focus"
            placement="left-start"
            title="Appointment Details"
          >
            <AppointmentDetails
              id={appointment.id}
              status={appointment.status}
              customer={appointment.customer}
              service={appointment.service}
              provider={appointment.provider}
              date={new Date(appointment.date)}
              cost={appointment.cost}
              time={`${appointment.start_time.slice(0, 5)} - ${appointment.end_time?.slice(0, 5)}`}
            />
          </Popover>
        </>
      ),
    };
  });

  if (props.loading) return <AppointmentsTablePlaceholder />;
  if (props.error) return <ErrorMessage errorData={props.error} />;
  if (!props.appointments || props.appointments.length === 0) return <NoDataFound />;
  return (
    <>
      <Table className="AppointmentsTable" columns={columns} rows={appointments} />
    </>
  );
};

function AppointmentsTablePlaceholder() {
  return (
    <div className="CustomerAppointmentsPlaceholder">
      <Skeleton className="CustomerAppointmentsPlaceholder__table" count={5} />
    </div>
  );
}
