import { useAllServicesQuery } from '../../graphql/generated/generated';
import { Select, SelectProps } from '../shared/Select/Select';

interface Props extends SelectProps {}

export const ServicesSelect = (props: Props) => {
  const { loading, data, error } = useAllServicesQuery();

  const services = data?.services.rows?.map((row) => ({ label: row.name, value: row.id }));

  const { label, isMulti, ...rest } = props;
  return (
    <Select
      isMulti={isMulti}
      label={label}
      options={services}
      loading={loading}
      error={error}
      name="Service"
      placeholder="Select a service"
      closeMenuOnSelect
      {...rest}
    />
  );
};
