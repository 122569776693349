import React from "react";
import "./Content.scss";

interface ContentProps {
  children: React.ReactNode;
}

export const Content = (props: ContentProps) => {
  return <div className="Content ">{props.children}</div>;
};
