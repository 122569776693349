import { ApolloError } from '@apollo/client';
import { HandleErrorState } from '../shared/HandleErrorState/HandleErrorState';
import { HandleLoadingState } from '../shared/HandleLoadingState/HandleLoadingState';
import { Skeleton } from '../shared/Skeleton/Skeleton';
import './IncomeViewer.scss';

interface Props {
  amount?: number;
  loading: boolean;
  error?: ApolloError;
}

export const IncomeViewer = (props: Props) => {
  return (
    <div className="IncomeViewer">
      <HandleLoadingState loading={props.loading} placeholder={<Skeleton width={135} />}>
        <HandleErrorState error={props.error} placeholder={<pre className="IncomeViewer__error">{props.error}</pre>}>
          <div className="IncomeViewer__description">This day income:</div>
          <div className="IncomeViewer__amount">{props.amount}€</div>
        </HandleErrorState>
      </HandleLoadingState>
    </div>
  );
};
