import { DatePicker } from '../../shared/DatePicker/DatePicker';
import { Select } from '../../shared/Select/Select';
import { getTimeOptions } from '../../../lib/helpers/getTimeOptions';
import cs from 'classnames';

import './DateAndTimePicker.scss';
import { components, OptionTypeBase, ValueContainerProps } from 'react-select';

interface Props {
  className?: string;
  date?: Date | undefined;
  onDateChange: (date: Date | null) => void;
  onTimeChange: (time: OptionTypeBase | null) => void;
  timeKey?: string;
  timeName?: string;
  timeId?: string;
  label?: string;
  time: OptionTypeBase | null;
  withInputTime?: boolean;
}

export const DateAndTimePicker = (props: Props) => {
  const { onDateChange, withInputTime, onTimeChange, timeKey, timeName, timeId, date, label, time } = props;

  return (
    <div className="DateAndTimePicker">
      <div className="DateAndTimePicker__wrapper">
        <label>{label}</label>
        <div className={cs('DateAndTimePicker__datepicker', props.className)}>
          <DatePicker
            className="DateAndTimePicker__date"
            inputClassName="DateAndTimePicker__field"
            placeholder="Time"
            id="startDate"
            date={date}
            onDateChange={(date) => onDateChange(date)}
          />
          {withInputTime && (
            <>
              <div className="DateAndTimePicker__divider"></div>
              <Select
                className="DateAndTimePicker__select"
                key={timeKey}
                name={timeName}
                id={timeId}
                placeholder="Time"
                value={time}
                onChange={(option: OptionTypeBase | null) => onTimeChange(option)}
                options={getTimeOptions(24)}
                selectStyle={style}
                valueContainer={ValueContainer}
                isSearchable={false}
              />{' '}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
function ValueContainer(props: ValueContainerProps<any, any>) {
  return <components.ValueContainer {...props}></components.ValueContainer>;
}

const style = {
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: '2rem',
    height: '2.1rem',
    border: 'none',
    boxShadow: '#FFFFFF',
    borderRadius: '0',
    paddingLeft: '0rem',
    marginRight: '0.05rem',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    width: '3rem',
    minHeight: '2rem',
    height: '2.1rem',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    minHeight: '2rem',
    height: '2.1rem',
    top: '70%',
  }),
};
