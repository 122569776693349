import { Icon } from '../Icon/Icon';
import cs from 'classnames';
import './AddNewButton.scss';

interface Props {
  className?: string;
  onClick?: () => void;
}

export const AddNewButton = (props: Props) => {
  const { className, ...rest } = props;
  return (
    <div className={cs('AddNewButton', className)} {...rest}>
      <Icon className="AddNewButton__icon" icon="plus" />
    </div>
  );
};
