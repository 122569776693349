import { ApolloError } from '@apollo/client';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { NoDataFound } from '../NoDataFound/NoDataFound';
import { Skeleton } from '../Skeleton/Skeleton';

import cs from 'classnames';

import './Table.scss';

export interface TableColumn {
  key: string;
  header: string;
  width?: number;
}

export interface TableRow {
  [key: string]: any;
}

interface Props {
  columns: TableColumn[];
  rows?: TableRow[];
  loading?: boolean;
  emptyPlaceholder?: string;
  className?: string;
  error?: ApolloError;
  onRowClick?: (id: string) => void;
}

export const Table = (props: Props) => {
  if (props.loading)
    return (
      <div className="Table__skeleton">
        <Skeleton className="Table__placeholder_row" count={8} />
      </div>
    );
  if (props.error) return <ErrorMessage errorData={props.error} />;
  if (props.rows?.length === 0) return <div className="Table__empty">{props.emptyPlaceholder || <NoDataFound />}</div>;
  return (
    <div className={cs('Table', props.className)}>
      <table>
        <thead>
          <tr>
            {props.columns?.map((col, index) => (
              <th key={`Table-${index}-${col.key}`} style={{ width: `${col.width}px` }}>
                {col.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.rows?.map((row: TableRow, index: number) => (
            <tr
              key={index}
              onClick={() => {
                props.onRowClick && props.onRowClick(row.id);
              }}
            >
              {props.columns.map((rowColumn, idx) => (
                <td key={idx}>{row[rowColumn['key']]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
