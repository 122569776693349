import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

export interface AddCustomerFields {
  name: string;
  email: string;
  phone_number: string;
  birthday: Date | undefined;
}

interface UseAddCustomerFormOptions {
  initialValues?: AddCustomerFields;
  onSubmit: (values: AddCustomerFields, formikHelpers: FormikHelpers<AddCustomerFields>) => Promise<any>;
}

export const useAddCustomerFormik = (options: UseAddCustomerFormOptions) => {
  const AddCustomerSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required'),
    phone_number: Yup.string()
      .required('Mobile is required')
      .matches(/^[+]\d+/, 'Should start with +383'),
  });

  return useFormik({
    initialValues: {
      name: '',
      email: '',
      phone_number: '',
      birthday: undefined,
      ...options.initialValues,
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: AddCustomerSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type AddCustomerFormik = ReturnType<typeof useAddCustomerFormik>;
