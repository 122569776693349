import { useCustomersQuery } from '../../graphql/generated/generated';
import { AppointmentFormik } from '../../lib/hooks/formik/useAppointmentFormik';
import { ValueContainer } from '../AppointmentDetailsForm/AppointmentDetailsForm';
import { CustomerDetailsForm } from '../Customers/CustomerDetailsForm/CustomerDetailsForm';
import { Button } from '../shared/Button/Button';
import { NoBorderButton } from '../shared/NoBorderButton/NoBorderButton';
import { Select } from '../shared/Select/Select';

import './AppointmentCustomerForm.scss';

interface Props {
  toggle: () => void;
  formik: AppointmentFormik;
  onRouteChange?: (route: string) => void;
}
export const AppointmentCustomerForm = (props: Props) => {
  const { loading, data, error } = useCustomersQuery();

  const options = data?.customers.rows?.map((row) => ({
    label: row.name,
    value: row.id,
  }));
  const { toggle, formik } = props;

  const handleCancelClick = () => {
    formik.resetForm();
    toggle();
  };

  const toggleCustomersDetailForm = () =>
    formik.setFieldValue('showAddCustomerForm', !formik.values.showAddCustomerForm);

  const handleSubmit = () => {
    if (!formik.isValid) toggleCustomersDetailForm();
    formik.submitForm();
  };

  return (
    <div className="AppointmentCustomerForm">
      {formik.values.showAddCustomerForm ? (
        <CustomerDetailsForm formik={formik} onCloseClick={toggleCustomersDetailForm} />
      ) : (
        <Select
          id="customer"
          key="customer"
          name="customer"
          loading={loading}
          error={error}
          className="AppointmentCustomerForm__select"
          placeholder="Search Customer"
          dropDownIcon="search"
          options={options}
          value={formik.values.customer}
          valueContainer={ValueContainer}
          isClearable
          onChange={(option) => {
            formik.setFieldValue('customer', option);
          }}
        />
      )}

      <div className="AppointmentCustomerForm__buttons">
        <NoBorderButton onClick={handleCancelClick}>Cancel</NoBorderButton>
        <Button
          className="AppointmentCustomer__submit"
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting}
          type="button"
          onClick={handleSubmit}
        >
          {formik.isValid ? 'Save Appointment' : '+ New Customer'}
        </Button>
      </div>
    </div>
  );
};
