import ReactSkeleton, { SkeletonTheme, SkeletonProps as ReactSkeletonProps } from 'react-loading-skeleton';
import cs from 'classnames';
interface SkeletonProps extends ReactSkeletonProps {
  color?: string;
  highlightColor?: string;
}
export const Skeleton = (props: SkeletonProps) => {
  const { className, ...rest } = props;
  return (
    <SkeletonTheme color={props.color} highlightColor={props.highlightColor}>
      <ReactSkeleton className={cs('Skeleton', className)} {...rest} />
    </SkeletonTheme>
  );
};
