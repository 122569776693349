import { CustomersSidebar } from '../../components/Customers/CustomersSidebar/CustomersSidebar';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { CustomerDetails } from '../../components/Customers/CustomerDetails/CustomerDetails';

import './Customers.scss';

export const Customers = (props: RouteComponentProps) => {
  return (
    <div className="Customers">
      <CustomersSidebar />

      <Switch>
        <Route path={`${props.match.url}/:id`} component={CustomerDetails} exact />
        <Route path={`${props.match.url}`} component={CustomerDetails} exact />
      </Switch>
    </div>
  );
};
