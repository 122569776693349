import React, { useEffect } from 'react';

import { Modal } from '../shared/Modal/Modal';
import { useRouter } from '../../lib/hooks/useRouter';

import './ConfirmationModal.scss';
import { Button } from '../shared/Button/Button';
import { NoBorderButton } from '../shared/NoBorderButton/NoBorderButton';

export interface Props {
  showCloseButton?: boolean;
  confirmationData: {
    text?: string;
    isOpen: boolean;
    isLoading?: boolean;
    onConfirm: () => void;
    onCancel: () => void;
  };
}

export default function ConfirmationModal(props: Props) {
  const router = useRouter();

  useEffect(() => {
    cancel();
  }, [router.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const confirm = () => {
    if (props.confirmationData.onConfirm) {
      props.confirmationData.onConfirm();
    }
  };

  const cancel = () => {
    if (props.confirmationData.onCancel) {
      props.confirmationData.onCancel();
    }
  };

  return (
    <Modal
      className="ConfirmationModal"
      title={props.confirmationData.text}
      isOpen={props.confirmationData.isOpen}
      toggle={() => cancel()}
    >
      <div className="ConfirmationModal__buttons">
        <Button className="ConfirmationModal__ok" onClick={confirm}>
          Ok
        </Button>
        <NoBorderButton className="ConfirmationModal__cancel" onClick={cancel}>
          Cancel
        </NoBorderButton>
      </div>
    </Modal>
  );
}
