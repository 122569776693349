import { UncontrolledPopover as ReactPopover, PopoverHeader, PopoverBody, PopoverProps } from 'reactstrap';
import cs from 'classnames';

import './Popover.scss';

interface Props extends PopoverProps {}

export const Popover = (props: Props) => {
  const { className, title, children, ...rest } = props;
  return (
    <ReactPopover popperClassName={cs('Popover', className)} {...rest}>
      {title && <PopoverHeader className="Popover__header">{title}</PopoverHeader>}
      <PopoverBody className="Popover__body">{children}</PopoverBody>
    </ReactPopover>
  );
};
