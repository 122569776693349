import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

export interface UserDetailsField {
  fullName: string;
  email: string;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface UseUserDetailsFormOptions {
  initialValues?: UserDetailsField;
  onSubmit: (values: UserDetailsField, formikHelpers: FormikHelpers<UserDetailsField>) => Promise<any>;
}

export const useUserDetailsFormik = (options: UseUserDetailsFormOptions) => {
  const UserDetailsSchema = Yup.object().shape({
    fullName: Yup.string().required('Full name is required'),
    email: Yup.string().email('Please enter a valid email!').required('Email is required'),
    currentPassword: Yup.string().min(6, 'Password should be more than 6 letters').required('Password is required'),
    newPassword: Yup.string().min(6, 'Password should be more than 6 letters').required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .min(6, 'Password should be more than 6 letters')
      .required('Password is required'),
  });

  return useFormik({
    initialValues: {
      fullName: '',
      email: '',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      ...options.initialValues,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: UserDetailsSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type UserDetailsFormik = ReturnType<typeof useUserDetailsFormik>;
