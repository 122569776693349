import { UserWorkingHoursForCalendarQuery } from './../../graphql/generated/generated';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { View } from 'react-big-calendar';
import { formatDate } from './formatDate';
import { getDayNameFromDate } from './getDayNameFromDate';

export const getWorkingHours = (
  workingHours: UserWorkingHoursForCalendarQuery | undefined,
  date: Date,
  view: View,
  staffId: string,
) => {
  if (!workingHours?.userWorkingHoursForCalendar) return [];

  if (view === 'month') return [];

  if (view === 'day') {
    const selectedDay = getDayNameFromDate(date);

    const filteredWorkingHours = workingHours?.userWorkingHoursForCalendar.filter((item) => item.day === selectedDay);
    if (!filteredWorkingHours) return [];

    return filteredWorkingHours.flatMap((item) => {
      return [
        {
          eventId: item.id,
          resourceId: item?.user_id,
          start: new Date(`${formatDate(date)} 00:00:00`),
          end: new Date(`${formatDate(date)} ${item.start_time}`),
          status: 'No Working Hours',
          time: '',
          customer: {},
          provider: {},
          service: { color: '#DCDCDC' },
          isDisabled: true,
        },
        {
          eventId: item.id,
          resourceId: item?.user_id,
          start: new Date(`${formatDate(date)} ${item.end_time}`),
          end: new Date(`${formatDate(date)} 23:59:59`),
          status: 'No Working Hours',
          time: '',
          customer: {},
          provider: {},
          service: { color: '#DCDCDC' },
          isDisabled: true,
        },
      ];
    });
  }

  let startDate = startOfWeek(date);
  let endDate = endOfWeek(date);
  if (view === 'week') {
    startDate = startOfWeek(date);
    endDate = endOfWeek(date);
  }

  if (!staffId) return [];
  const filteredWorkingHours = workingHours?.userWorkingHoursForCalendar.filter(
    (item) => item.user_id === parseInt(staffId),
  );

  const breakEvents = filteredWorkingHours.map((item) => {
    const dateRangeBreaks = [];
    while (startDate <= endDate) {
      let dateDayName = getDayNameFromDate(startDate);

      if (item.day === dateDayName) {
        dateRangeBreaks.push(
          {
            eventId: item.id,
            resourceId: item?.user_id,
            start: new Date(`${formatDate(startDate)} 00:00:00`),
            end: new Date(`${formatDate(startDate)} ${item.start_time}`),
            status: 'No Working Hours',
            time: '',
            customer: {},
            provider: {},
            service: { color: '#DCDCDC' },
            isDisabled: true,
          },
          {
            eventId: item.id,
            resourceId: item?.user_id,
            start: new Date(`${formatDate(startDate)} ${item.end_time}`),
            end: new Date(`${formatDate(startDate)} 23:59:59`),
            status: 'No Working Hours',
            time: '',
            customer: {},
            provider: {},
            service: { color: '#DCDCDC' },
            isDisabled: true,
          },
        );

        if (view === 'week') {
          break;
        }

        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
      }

      startDate = new Date(startDate.setDate(startDate.getDate() + 1));
    }

    startDate = view === 'week' ? startOfWeek(date) : startOfMonth(date);
    return dateRangeBreaks;
  });

  return breakEvents.flat();
};
