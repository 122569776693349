import { useState } from 'react';
import { Button } from 'reactstrap';

import { useWindowWidth } from '../../../lib/hooks/useWindowWidth';
import { Dropdown } from '../../shared/Dropdown/Dropdown';
import { Icon } from '../../shared/Icon/Icon';
import { NoBorderButton } from '../../shared/NoBorderButton/NoBorderButton';
import { SideBarItems } from '../../SideBarItems/SideBarItems';

import './CustomersSidebar.scss';

export const CustomersSidebar = () => {
  const width = useWindowWidth();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      {width > 992 ? (
        <SideBarItems />
      ) : (
        <Dropdown
          className="CustomersSidebar__dropdown"
          menuClassName="CustomersSidebar__menu"
          nav
          isOpen={dropdownOpen}
          toggle={toggleDropdown}
          toggleButton={
            <div className="CustomersSidebar__toggle_list">
              {' '}
              <NoBorderButton className="CustomerSidebar__view">View Customers</NoBorderButton>
            </div>
          }
          items={<SideBarItems dropdownToggle={toggleDropdown} />}
        />
      )}
    </>
  );
};
