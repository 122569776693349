import { useCategoriesQuery } from '../../../graphql/generated/generated';
import { Select } from '../Select/Select';

interface Props {
  label?: string;
}

export const CategoriesSelect = (props: Props) => {
  const { loading, data, error } = useCategoriesQuery();

  const categoriesOptions = data?.categories.map((category) => ({ label: category.name, value: category.id }));

  const { label, ...rest } = props;
  return (
    <Select
      isMulti
      label={label}
      name="Categories"
      options={categoriesOptions}
      loading={loading}
      error={error}
      closeMenuOnSelect={false}
      {...rest}
    />
  );
};
