import { Input, InputProps } from '../Input/Input';
import cs from 'classnames';
import './InputWithElement.scss';

interface Props extends InputProps {
  renderElement: JSX.Element;
}
export const InputWithElement = (props: Props) => {
  const { className, inputClassName, renderElement, ...rest } = props;
  return (
    <Input
      className={cs('InputWithElement', className)}
      inputClassName={cs('InputWithElement__input', inputClassName)}
      renderElement={renderElement}
      {...rest}
    />
  );
};
