import { ApolloError } from '@apollo/client';
import { useState } from 'react';
import { components, ValueContainerProps } from 'react-select';
import { Form } from 'reactstrap';
import { client } from '../../apollo/client';
import { GetReportDocument, ReportType } from '../../graphql/generated/generated';
import { useReportsFormik } from '../../lib/hooks/formik/useReportsFormik';
import { ServicesSelect } from '../ServicesSelect/ServicesSelect';
import { Button } from '../shared/Button/Button';
import { DatePicker } from '../shared/DatePicker/DatePicker';
import { ErrorMessage } from '../shared/ErrorMessage/ErrorMessage';
import { Select } from '../shared/Select/Select';
import { StaffSelect } from '../Staff/StaffSelect/StaffSelect';
import { formatDate } from '../../lib/helpers/formatDate';

import './ReportGenerator.scss';

interface Props {
  onGenerateReport: (report: ReportType) => void;
}
export const ReportGenerator = (props: Props) => {
  const [error, setError] = useState<ApolloError>();

  const formik = useReportsFormik({
    onSubmit: async (values) => {
      try {
        const { data } = await client.query({
          query: GetReportDocument,
          variables: {
            filters: {
              end_date: formatDate(values.endDate),
              start_date: formatDate(values.startDate),
              providers:
                values.providers?.length === 0 ? null : values.providers?.map((provider) => parseInt(provider.value)),
              status: values.status?.value,
              services:
                values.services?.length === 0 ? null : values.services?.map((service) => parseInt(service.value)),
            },
          },
        });
        props.onGenerateReport(data.report);
      } catch (e: any) {
        setError(e);
      }
    },
  });

  return (
    <div className="ReportGenerator">
      <Form onSubmit={formik.handleSubmit}>
        <div className="ReportGenerator__content">
          <div className="ReportGenerator__description">
            <label>Start Date</label>
            <div className="ReportGenerator__date">
              <DatePicker
                placeholder="Start Date"
                id="startDate"
                date={formik.values.startDate}
                onDateChange={(date) => formik.setFieldValue('startDate', date)}
                error={formik.errors.startDate}
                touched={formik.touched.startDate}
              />
            </div>
          </div>

          <div className="ReportGenerator__description">
            <label>End Date</label>
            <div className="ReportGenerator__date">
              <DatePicker
                placeholder="End Date"
                id="endDate"
                date={formik.values.endDate}
                onDateChange={(date) => formik.setFieldValue('endDate', date)}
                error={formik.errors.endDate}
                touched={formik.touched.endDate}
              />
            </div>
          </div>

          <div className="ReportGenerator__select_wrapper">
            <div className="ReportGenerator__description">
              <label>Services</label>
              <ServicesSelect
                isMulti
                closeMenuOnSelect={false}
                className="ReportGenerator__select"
                onChange={(options) => formik.setFieldValue('services', options)}
              />
            </div>
          </div>

          <div className="ReportGenerator__select_wrapper">
            <div className="ReportGenerator__description">
              <label>Staff</label>
              <StaffSelect
                isMulti
                className="ReportGenerator__select"
                onChange={(options) => formik.setFieldValue('providers', options)}
              />
            </div>
          </div>

          <div className="ReportGenerator__status">
            <div className="ReportGenerator__description">
              <label>Status</label>
              <Select
                className="ReportGenerator__select"
                placeholder="Status"
                options={statusOptions}
                valueContainer={ValueContainer}
                onChange={(option) => formik.setFieldValue('status', option)}
              />
            </div>
            <div className="ReportGenerator__button-content">
              <Button className="ReportGenerator__button" type="submit">
                Generate Report
              </Button>
            </div>
          </div>
        </div>
        <ErrorMessage className="ReportGenerator__error" errorData={error} />
      </Form>
    </div>
  );
};

const statusOptions = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Confirmed', label: 'Confirmed' },
  { value: 'Done', label: 'Done' },
  { value: 'Paid', label: 'Paid' },
  { value: 'Running Late', label: 'Running late' },
  { value: 'No-Show', label: 'No Show' },
];

function ValueContainer(props: ValueContainerProps<any, any>) {
  return <components.ValueContainer {...props}></components.ValueContainer>;
}
