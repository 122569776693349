import React from 'react';

import { Button as ReactstrapButton, ButtonProps as ReactstrapButtonProps } from 'reactstrap';
import { Spinner } from '../Spinner/Spinner';
import cs from 'classnames';

import './Button.scss';
export interface ButtonProps extends ReactstrapButtonProps {
  loading?: boolean;
}

export const Button = (props: ButtonProps) => {
  const { className, children, loading, ...rest } = props;
  return (
    <ReactstrapButton className={cs('Button', className)} {...rest}>
      {loading ? <Spinner className="Button__spinner" /> : children}
    </ReactstrapButton>
  );
};
