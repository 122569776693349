import './ApprovedMessage.scss';
import { useRouter } from '../../../lib/hooks/useRouter';
import { Button } from 'reactstrap';

interface Props {
  title: string;
  description: string;
}
export const ApprovedMessage = (props: Props) => {
  const router = useRouter();
  return (
    <div className="ApprovedMessage">
      <h1 className="ApprovedMessage__title">{props.title}</h1>
      <p className="ApprovedMessage__description">{props.description}</p>
      <Button className="ApprovedMessage__button" onClick={() => router.history.push('login')}>
        Go to login
      </Button>
    </div>
  );
};
