import { useState } from 'react';
import { Form } from 'reactstrap';
import { useAddWorkingHoursMutation, useWorkingHoursQuery } from '../../graphql/generated/generated';
import { formatInitialValueForWorkingHour } from '../../lib/helpers/formatInitialValueForWorkingHour';
import { AddEditWorkingHoursFields, useAddEditWorkingHoursFormik } from '../../lib/hooks/formik/useAddEditWorkingHours';
import { ChooseWorkingHour } from '../ChooseWorkingHour/ChooseWorkingHour';
import { ErrorMessage } from '../shared/ErrorMessage/ErrorMessage';
import { FormButtons } from '../shared/FormButtons/FormButtons';
import { NoBorderButton } from '../shared/NoBorderButton/NoBorderButton';
import { Skeleton } from '../shared/Skeleton/Skeleton';

import './WorkingHoursForm.scss';

interface Props {
  staffId: string;
}

interface Day {
  key: keyof AddEditWorkingHoursFields;
  label: string;
}

const days: Day[] = [
  { key: 'monday', label: 'Monday' },
  { key: 'tuesday', label: 'Tuesday' },
  { key: 'wednesday', label: 'Wednesday' },
  { key: 'thursday', label: 'Thursday' },
  { key: 'friday', label: 'Friday' },
  { key: 'saturday', label: 'Saturday' },
  { key: 'sunday', label: 'Sunday' },
];

export const WorkingHoursForm = (props: Props) => {
  const { loading, data, error } = useWorkingHoursQuery({ variables: { id: parseInt(props.staffId) } });
  const [addWorkingHours, { error: addError }] = useAddWorkingHoursMutation();
  const [fieldDisabled, setFieldDisabled] = useState<boolean>(true);

  const formik = useAddEditWorkingHoursFormik({
    initialValues: formatInitialValueForWorkingHour(data?.userWorkingHours),
    onSubmit: async (values) => {
      try {
        await addWorkingHours({
          variables: {
            data: { user_id: parseInt(props.staffId), working_hours: Object.values(values) },
          },
        });
        setFieldDisabled(true);
      } catch (e) {}
    },
  });

  if (loading) return <WorkingHoursFormPlaceholder />;

  return (
    <Form className="WorkingHoursForm" onSubmit={formik.handleSubmit}>
      {days.map((day) => {
        return (
          <ChooseWorkingHour
            key={day.key}
            label={day.label}
            disabled={fieldDisabled}
            value={formik.values[day.key]}
            onStartTimeChange={(option) => formik.setFieldValue(`${day.key}.start_time`, option)}
            onEndTimeChange={(option) => formik.setFieldValue(`${day.key}.end_time`, option)}
            onToggleSwitch={() => formik.setFieldValue(`${day.key}.status`, !formik.values[day.key].status)}
          />
        );
      })}

      <ErrorMessage errorData={addError || error} />

      <div className="WorkingHoursForm__divider"></div>

      {fieldDisabled ? (
        <NoBorderButton className="WorkingHoursForm__edit" onClick={() => setFieldDisabled((prevState) => !prevState)}>
          Edit Working Hours
        </NoBorderButton>
      ) : (
        <FormButtons
          submitButtonName="Save"
          disabled={formik.isSubmitting || !formik.isValid}
          loading={formik.isSubmitting}
          onCancelClick={() => setFieldDisabled(true)}
        />
      )}
    </Form>
  );
};

function WorkingHoursFormPlaceholder() {
  return (
    <div className="WorkingHoursForm__placeholder">
      <div className="WorkingHoursForm__skeleton">
        <Skeleton height="1.875rem" count={7} />
      </div>
      <div className="WorkingHoursForm__skeleton">
        <Skeleton height="1.875rem" count={7} />
      </div>
      <div className="WorkingHoursForm__skeleton">
        <Skeleton height="1.875rem" count={7} />
      </div>
    </div>
  );
}
