import { useState } from 'react';
import { ReportGenerator } from '../../components/ReportGenerator/ReportGenerator';
import { ReportInformation } from '../../components/ReportInformation/ReportInformation';
import { ReportType } from '../../graphql/generated/generated';
import './Reports.scss';

export const Reports = () => {
  const [report, setReport] = useState<ReportType>();

  const handleGenerateReport = (report: ReportType) => {
    setReport(report);
  };
  return (
    <div className="Reports">
      <h1 className="Reports__title">Reports</h1>
      <ReportGenerator onGenerateReport={handleGenerateReport} />
      <ReportInformation report={report} />
    </div>
  );
};
