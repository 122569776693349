import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

export interface ForgotPasswordField {
  email: string;
}

interface UseForgotPasswordOptions {
  initialValues?: ForgotPasswordField;
  onSubmit: (values: ForgotPasswordField, formikHelpers: FormikHelpers<ForgotPasswordField>) => Promise<any>;
}

export const useForgotPasswordFormik = (options: UseForgotPasswordOptions) => {
  const UserDetailsSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email!').required('Please write your email'),
  });

  return useFormik({
    initialValues: {
      email: '',
      ...options.initialValues,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: UserDetailsSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type useForgotPasswordFormik = ReturnType<typeof useForgotPasswordFormik>;
