import { UserBreaksType } from './../../graphql/generated/generated';
import { useEffect, useState } from 'react';
import { GetUserBreaksType } from '../../graphql/generated/generated';
import { Option } from '../../components/shared/Select/Select';

export const useBreaks = (breaks: GetUserBreaksType) => {
  const [staffBreak, setStaffBreak] = useState<GetUserBreaksType>(() => breaks);

  useEffect(() => {
    setStaffBreak(breaks);
  }, [breaks]);

  const addBreak = (addedBreak?: UserBreaksType) => {
    if (!addedBreak) return;
    setStaffBreak((prevState) => {
      return {
        ...prevState,
        breaks: [
          ...(prevState.breaks || []),
          {
            id: parseInt(addedBreak?.id),
            start_time: addedBreak?.start_time,
            end_time: addedBreak?.end_time,
          },
        ],
      };
    });
  };

  const deleteBreak = (id: number) => {
    setStaffBreak((prevState) => {
      return { ...prevState, breaks: prevState.breaks?.filter((item) => item.id !== id) };
    });
  };

  const updateStartTime = (option: Option, id: number) => {
    setStaffBreak((prevState) => {
      return {
        user_id: prevState.user_id,
        day: prevState.day,
        breaks: prevState.breaks?.map((item) => {
          if (item.id !== id) return item;
          return { id: id, start_time: `${option.value}:00`, end_time: item.end_time };
        }),
      };
    });
  };

  const updateEndTime = (option: Option, id: number) => {
    setStaffBreak((prevState) => {
      return {
        user_id: prevState.user_id,
        day: prevState.day,
        breaks: prevState.breaks?.map((item) => {
          if (item.id !== id) return item;
          return { id: id, start_time: item.start_time, end_time: `${option.value}:00` };
        }),
      };
    });
  };

  return { staffBreak, addBreak, deleteBreak, updateStartTime, updateEndTime };
};
