import React, { MouseEventHandler } from 'react';
import cs from 'classnames';
import { IconSvg, IconDefinition } from './Icon.generated';
import './Icon.scss';
export * from './Icon.generated';
interface Props {
  icon?: IconDefinition;
  alt?: string;
  style?: React.CSSProperties;
  className?: string;
  spin?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}
export function Icon(props: Props) {
  if (!props.icon) {
    return null;
  }
  return (
    <div
      style={props.style}
      className={cs('Icon', props.spin ? 'Icon--spin' : undefined, props.className)}
      onClick={(e) => props.onClick && props.onClick(e)}
    >
      {IconSvg[props.icon]}
    </div>
  );
}
