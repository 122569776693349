import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

export interface NotificationField {
  title: string;
  description: string;
}

interface UseNotificationFormOptions {
  initialValues?: NotificationField;
  onSubmit: (values: NotificationField, formikHelpers: FormikHelpers<NotificationField>) => Promise<any>;
}

export const useNotificationFormik = (options: UseNotificationFormOptions) => {
  const UserDetailsSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
  });

  return useFormik({
    initialValues: {
      title: '',
      description: '',
      ...options.initialValues,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: UserDetailsSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type NotificationFormik = ReturnType<typeof useNotificationFormik>;
