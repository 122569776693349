import { useCustomerInformationQuery } from '../../../graphql/generated/generated';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import { NoDataFound } from '../../shared/NoDataFound/NoDataFound';
import { Skeleton } from '../../shared/Skeleton/Skeleton';

import './CustomerInformation.scss';

interface Props {
  id: string;
}

export const CustomerInformation = (props: Props) => {
  const { id } = props;

  const { loading, data, error } = useCustomerInformationQuery({
    variables: { id: parseInt(id) },
  });

  if (loading) return <CustomerInformationPlaceholder />;
  if (error)
    return (
      <div className="CustomerInformation">
        <ErrorMessage className="CustomerInformation__error" errorData={error} />
      </div>
    );
  if (!data || !data.customer) return <NoDataFound />;
  return (
    <div className="CustomerInformation">
      <h1>{data?.customer?.name}</h1>
      <div className="CustomerInformation__content">
        <ul className="CustomerInformation__title">
          <li>Email</li>
          <li>Mobile</li>
        </ul>
        <ul className="CustomerInformation__details">
          <li>{data?.customer?.email}</li>
          <li>{data?.customer?.phone_number}</li>
        </ul>
      </div>
    </div>
  );
};

function CustomerInformationPlaceholder() {
  return (
    <div className="CustomerInformation__placeholder">
      <Skeleton className="CustomerInformation__line_header" width={100} />
      <Skeleton className="CustomerInformation__line" width={300} count={2} />
    </div>
  );
}
