import { components, OptionProps, ValueContainerProps } from 'react-select';
import { Input, InputProps } from '../shared/Input/Input';
import { Select } from '../shared/Select/Select';
import { GetInputProps, GetSelectProps } from '../Services/AddEditServiceForm/AddEditServiceForm';
import cs from 'classnames';

import './ServiceNameInput.scss';

interface Props {
  getInputProps: (props: GetInputProps) => InputProps;
  getSelectProps: (props: GetSelectProps) => any;
  error?: string;
  touched?: boolean;
}

export const ServiceNameInput = (props: Props) => {
  return (
    <div className="ServiceNameInput__service">
      <Select
        isMulti={false}
        customStyles={styles}
        options={options}
        option={Option}
        valueContainer={valueContainer}
        {...props.getSelectProps({
          key: 'color',
          className: cs('ServiceNameInput__color', { ServiceNameInput__error: props.error && props.touched }),
        })}
        closeMenuOnSelect
      />
      <Input
        disabled={true}
        {...props.getInputProps({
          key: 'name',
          type: 'text',
          placeholder: 'Enter service name',
          label: 'Service Name',
          className: 'ServiceNameInput__service_input',
        })}
      />
    </div>
  );
};

function Option(props: OptionProps<any, any>) {
  return (
    <components.Option {...props}>
      <div className="ServiceNameInput__options">
        <div
          style={{
            width: '0.75rem',
            height: '0.75rem',
            backgroundColor: props.data.value,
            borderRadius: '3px',
            border: `1px solid ${props.data.border}`,
          }}
        ></div>
      </div>
    </components.Option>
  );
}

function valueContainer(props: ValueContainerProps<any, any>) {
  const value = props.getValue();
  return (
    <components.ValueContainer {...props}>
      <div>
        <div
          style={{
            width: '0.75rem',
            height: '0.75rem',
            backgroundColor: value?.[0]?.value ? value[0].value : props.options[0].value,
            borderRadius: '3px',
            border: `1px solid ${value?.[0]?.border}`,
          }}
        ></div>
      </div>
    </components.ValueContainer>
  );
}
const styles = {
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: '1.8rem',
    borderRadius: '5px',
    marginLeft: '3rem',
    width: '1.5rem',
    border: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#000000',
    padding: '0.313rem',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    width: '0.75rem',
    height: '0.75rem',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: '#000000',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    marginLeft: '2.5rem',
    width: '1.8rem',
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    width: '0.75rem',
  }),
  menuList: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    padding: '0.5rem',
    backgroundColor: state.isSelected ? 'rgba(221, 201, 192, 0.5)' : '#FFFFFF',
    borderRadius: '0.2rem',
  }),
};
const options = [
  {
    value: '#f0c2c1',
    border: '#e59190',
    label: 'Red',
  },
  {
    value: '#e1e0e2',
    label: 'Light gray',
    border: '#a9a8a9',
  },
  {
    value: '#cae79e',
    label: 'Green',
    border: '#7bc40d',
  },
  {
    value: '#bbe6e1',
    label: 'Light blue',
    border: '#55bfb3',
  },
  {
    value: '#e6dbcb',
    label: 'Maroon',
    border: '#c4a981',
  },
  {
    value: '#b5cedb',
    label: 'Deep sky blue',
    border: '#4484a4',
  },
  {
    value: '#e1c6e1',
    label: 'Violet',
    border: '#b597e8',
  },
  {
    value: '#f3e7ae',
    label: 'Yellow',
    border: '#d4b825',
  },
  {
    value: '#fadeb3',
    label: 'Orange',
    border: '#f2a836',
  },
];
