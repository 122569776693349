import { useStaffMembersQuery } from '../../../graphql/generated/generated';
import { Select, SelectProps } from '../Select/Select';

interface Props extends SelectProps {}

export const ProvidersSelect = (props: Props) => {
  const { loading, data, error } = useStaffMembersQuery();

  const staffOptions = data?.staffMembers.rows.map((row) => ({ label: row.full_name, value: row.id }));

  const { isMulti, ...rest } = props;

  return (
    <Select
      isMulti={isMulti}
      options={staffOptions}
      loading={loading}
      error={error}
      name="Staff"
      placeholder="All Staff"
      isSelected={false}
      {...rest}
      closeMenuOnSelect
    />
  );
};
