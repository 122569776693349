import { useState } from 'react';
import { AddNewButton } from '../../shared/AddNewButton/AddNewButton';
import { Sidebar } from '../../shared/Sidebar/Sidebar';
import {
  StaffMembersDocument,
  StaffMembersType,
  useDeleteStaffMutation,
  useStaffMembersQuery,
} from '../../../graphql/generated/generated';
import { AddStaffForm } from '../../AddStaffForm/AddStaffForm';
import { Dropdown } from '../../shared/Dropdown/Dropdown';
import { useWindowWidth } from '../../../lib/hooks/useWindowWidth';
import { SideBarItems } from '../../SideBarItems/SideBarItems';
import { NoBorderButton } from '../../shared/NoBorderButton/NoBorderButton';
import { StaffSideBarItem } from '../StaffSideBarItem/StaffSideBarItem';

interface Props {
  toggleDropdown?: () => void;
}
export const StaffSidebar = () => {
  const { loading, data, error } = useStaffMembersQuery();
  const [deleteStaff, deleteStaffResponse] = useDeleteStaffMutation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const width = useWindowWidth();

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      {width > 992 ? (
        <StaffSideBarItem />
      ) : (
        <Dropdown
          className="CustomersSidebar__dropdown"
          menuClassName="CustomersSidebar__menu"
          nav
          isOpen={dropdownOpen}
          toggle={toggleDropdown}
          toggleButton={
            <div className="CustomersSidebar__toggle_list">
              {' '}
              <NoBorderButton className="CustomerSidebar__view">View Staff</NoBorderButton>
            </div>
          }
          items={<StaffSideBarItem dropdownToggle={toggleDropdown} />}
        />
      )}
    </>
  );
};
