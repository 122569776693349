export const getTimeOptions = (maxHours: number) => {
  let quarterHours = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
  let times = [];
  for (let i = 0; i < maxHours; i++) {
    for (let j = 0; j < 12; j++) {
      let time = i + ':' + quarterHours[j];
      if (i < 10) {
        time = '0' + time;
      }
      times.push({ value: time, label: `${time}` });
    }
  }
  return times;
};
