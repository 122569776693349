import { Form, ModalFooter } from 'reactstrap';
import { ServiceNameInput } from '../../ServiceNameInput/ServiceNameInput';
import { CategoriesSelect } from '../../shared/CategoriesSelect/CategoriesSelect';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import { FormButtons } from '../../shared/FormButtons/FormButtons';
import { Input, InputProps } from '../../shared/Input/Input';
import { ProvidersSelect } from '../../shared/ProvidersSelect/ProvidersSelect';
import { Switch } from '../../shared/Switch/Switch';
import cs from 'classnames';
import { AddEditServiceFields, AddEditServiceFormik } from '../../../lib/hooks/formik/useAddEditServiceFormik';
import { InputType } from 'reactstrap/es/Input';
import { OptionsType, OptionTypeBase } from 'react-select';
import { ApolloError } from '@apollo/client';
import { HandleLoadingState } from '../../shared/HandleLoadingState/HandleLoadingState';
import { Skeleton } from '../../shared/Skeleton/Skeleton';

import './AddEditServiceForm.scss';
import { SelectProps } from '../../shared/Select/Select';

export interface GetInputProps {
  key: keyof AddEditServiceFields;
  type: InputType | undefined;
  placeholder: string;
  label: string;
  className?: string;
  min?: string;
}

export interface GetSelectProps {
  key: keyof AddEditServiceFields;
  className?: string;
  customValueContainer?: boolean;
  label?: string;
  loading?: boolean;
  isMulti?: boolean;
}

interface Props {
  formik: AddEditServiceFormik;
  toggle: () => void;
  error?: ApolloError;
  loading?: boolean;
  resetServiceId: () => void;
}

export const AddEditServiceForm = (props: Props) => {
  const { formik, toggle, error, loading, resetServiceId } = props;

  const handleCancelClick = () => {
    resetServiceId();
  };
  const getInputProps = ({ key, type, placeholder, label, className, ...props }: GetInputProps) => {
    return {
      className: 'AddEditServiceForm__input',
      inputClassName: cs('AddEditServiceForm__field', className),
      type: type,
      name: key,
      label: label,
      id: key,
      placeholder: placeholder,
      hiddenLabel: false,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      touched: formik.touched[key],
      error: formik.errors[key],
      value: formik.values[key],
      invalid: formik.touched[key] && !!formik.errors[key],
      ...props,
    } as InputProps;
  };

  const getSelectProps = ({ key, ...props }: GetSelectProps) => {
    return {
      className: 'AddEditServiceForm__select',
      key: key,
      name: key,
      id: key,
      onChange: (value: OptionTypeBase | OptionsType<OptionTypeBase> | null) => {
        formik.setFieldTouched(key);
        formik.setFieldValue(key, value);
      },
      onBlur: () => {
        formik.setFieldTouched(key);
      },
      touched: formik.touched[key],
      error: formik.errors[key],
      value: formik.values[key],
      closeMenuOnSelect: false,
      isSearchable: true,
      ...props,
    } as SelectProps;
  };
  return (
    <Form className="AddEditServiceForm__form" inline onSubmit={formik.handleSubmit}>
      <HandleLoadingState loading={loading} placeholder={<Placeholder />}>
        <ServiceNameInput
          touched={formik.touched.name}
          error={formik.errors.name}
          getInputProps={getInputProps}
          getSelectProps={getSelectProps}
        />
        <Input
          {...getInputProps({ key: 'description', type: 'textarea', placeholder: 'Service description', label: '' })}
        />

        <ProvidersSelect
          {...getSelectProps({ key: 'providers', label: 'Choose provider', customValueContainer: true, isMulti: true })}
        />

        <Input
          {...getInputProps({ key: 'time', type: 'text', placeholder: 'Service time', label: 'Service time (mins)' })}
        />

        <Input
          disabled={true}
          {...getInputProps({
            key: 'cost',
            type: 'number',
            placeholder: 'Service cost',
            label: 'Service cost (€)',
            min: '0',
          })}
        />

        <CategoriesSelect
          {...getSelectProps({ key: 'categories', label: 'Categories list', customValueContainer: true })}
        />

        {/* <Switch
          className="AddEditServiceForm__switch"
          label="Private Service"
          selected={formik.values.private}
          toggleSelected={() => {
            formik.setFieldValue('private', !formik.values.private);
          }}
        /> */}
      </HandleLoadingState>

      <ModalFooter className="AddEditServiceForm__footer">
        <FormButtons
          submitButtonName="Save Service"
          submitClassName="AddEditServiceForm__submit"
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting || !formik.isValid}
          toggle={toggle}
          onCancelClick={handleCancelClick}
          resetForm={formik.resetForm}
        />
      </ModalFooter>
      <ErrorMessage errorData={error} />
    </Form>
  );
};

function Placeholder() {
  return <Skeleton className="AddEditServiceForm__placeholder" count={7} />;
}
