import React from 'react';
import { Dropdown as ReactstrapDropdown, DropdownMenu, DropdownProps, DropdownToggle } from 'reactstrap';
import cs from 'classnames';

import './Dropdown.scss';

interface Props extends DropdownProps {
  items: JSX.Element;
  toggleButton?: JSX.Element;
  menuClassName?: string;
  title?: string;
  menuStyle?: any;
}

export const Dropdown = (props: Props) => {
  const { className, toggleButton, items, menuClassName, title, menuStyle, ...rest } = props;

  return (
    <ReactstrapDropdown className={cs('Dropdown', className)} {...rest}>
      {toggleButton && <DropdownToggle className="Dropdown__toggle">{toggleButton}</DropdownToggle>}
      <DropdownMenu className={cs('Dropdown__menu', menuClassName)} style={menuStyle}>
        <h4 className="Dropdown__title">{title}</h4>
        {items}
      </DropdownMenu>
    </ReactstrapDropdown>
  );
};
