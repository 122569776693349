import React from 'react';
//styles
import './Footer.scss';

export const Footer = () => {
  return (
    <div className="Footer">
      <ul>
        <li>
          {' '}
          <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/angelbyvjosa/">
            Instagram
          </a>{' '}
        </li>
        <li>
          {' '}
          <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/angelstudioacad/">
            Facebook
          </a>
        </li>
      </ul>
      <span>Angel 2021 - All rights reserved</span>
    </div>
  );
};
