import { ReportType } from '../../graphql/generated/generated';
import { Icon } from '../shared/Icon/Icon';

import './ReportInformation.scss';

interface Props {
  report?: ReportType;
}

export const ReportInformation = (props: Props) => {
  const { report } = props;
  if (!report) return null;

  return (
    <div className="ReportInformation">
      <div className="ReportInformation__time">
        <h2>Dates</h2>
        <div className="ReportInformation__dates">
          {new Date(report.start_date).toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
          })}{' '}
          -{' '}
          {new Date(report.end_date).toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
          })}
        </div>
      </div>
      <Icon className="ReportInformation__arrow" icon="arrow-general" />
      <span className="ReportInformation__divider"></span>
      <div className="ReportInformation__appointments">
        <h3>{report.appointments}</h3>
        Appointments
      </div>
      <span className="ReportInformation__divider"></span>
      <div className="ReportInformation__revenue">
        <h3>{report.total} €</h3>
        Revenue
      </div>
    </div>
  );
};
