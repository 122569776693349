import { NavLink, useLocation } from 'react-router-dom';
import { Icon } from '../../../shared/Icon/Icon';
import cs from 'classnames';

import './NavSidebarItem.scss';
import { useUIContext } from '../../../../lib/context/UIContext/UIContext';
import { useWindowWidth } from '../../../../lib/hooks/useWindowWidth';
export interface SidebarItemProp {
  icon: any;
  label: string;
  path: string;
}

interface Props {
  data: SidebarItemProp;
  toggle: () => void;
}

export const NavSidebarItem = (props: Props) => {
  const location = useLocation();
  const { data } = props;
  const width = useWindowWidth();

  const handleClick = () => {
    return width < 992 ? props.toggle() : null;
  };

  return (
    <NavLink
      className={cs('NavSidebarItem', { NavSidebarItem__active: `${location.pathname.split('/')[1]}` === data.path })}
      exact
      to={`/${data.path}`}
      onClick={handleClick}
    >
      <Icon icon={data?.icon} className="NavSidebarItem__icon" />
      {data?.label}
    </NavLink>
  );
};
