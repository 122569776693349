import { AddAppointment } from '../AddAppointment/AddAppointment';
import { EditAppointment } from '../EditAppointment/EditAppointment';
import { EditAppointmentType } from '../shared/AppointmentDetails/AppointmentDetails';
import { Modal, ModalProps } from '../shared/Modal/Modal';

import './AppointmentModal.scss';

export interface AppointmentInfo {
  start: Date | string;
  end: Date | string;
  providerId: number;
}
interface Props extends ModalProps {
  appointmentInfo?: AppointmentInfo;
  view?: string;
  date: Date;
  appointmentToEdit?: EditAppointmentType;
}

export const AppointmentModal = (props: Props) => {
  const { appointmentInfo, view, date, appointmentToEdit, ...rest } = props;
  return (
    <Modal className="AppointmentModal" {...rest}>
      {!appointmentToEdit ? (
        appointmentInfo && <AddAppointment date={date} appointmentInfo={appointmentInfo} view={view} {...rest} />
      ) : (
        <EditAppointment appointmentToEdit={appointmentToEdit} date={date} view={view} {...rest} />
      )}
    </Modal>
  );
};
