export const styles = (style?: any) => ({
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: '2rem',
    borderColor: '#DDC9C0',
    borderRadius: '5px',
    paddingLeft: '1rem',
    height: '36px',
    boxShadow: state.isFocused ? '0 0 0  1px #DDC9C0' : 'unset',
    '&:hover': { borderColor: '#DDC9C0' },
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#000000',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    height: '100%',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    top: '45%',
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: '100%',
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: 'auto',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: '#BBBBBB',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: '#000000',
    backgroundColor: state.isSelected ? 'rgba(221, 201, 192, 0.5)' : '#FFFFFF',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    zIndex: '12',
  }),

  ...style,
});
