import { components, OptionProps } from 'react-select';
import { getMinutesFromTime } from '../../lib/helpers/getMinutesFromTime';
import { Select, SelectProps } from '../shared/Select/Select';

import './ServicesWithDetailSelect.scss';

interface Props extends SelectProps {
  providerId?: string;
  options?: { label: string; value: string; color: string; time: string }[];
}

export const ServicesWithDetailsSelect = (props: Props) => {
  const { label, isMulti, options, loading, error, ...rest } = props;

  return (
    <Select
      isMulti={isMulti}
      label={label}
      options={props.options}
      loading={loading}
      error={props.error}
      name="Service"
      placeholder="Select a service"
      option={Option}
      closeMenuOnSelect
      {...rest}
    />
  );
};
function Option(props: OptionProps<any, any>) {
  return (
    <components.Option className="ServicesWithDetailSelect__option" {...props}>
      <div
        className="ServicesWithDetailSelect__color"
        style={{ backgroundColor: props.data.color, width: '10px', height: '10px' }}
      ></div>
      <div className="ServicesWithDetailSelect__label">{props.data.label}</div>
      <div className="ServicesWithDetailSelect__time">{getMinutesFromTime(props.data.time)} mins</div>
    </components.Option>
  );
}
