import { cache } from '../../../apollo/cache';
import {
  AllServicesDocument,
  CategoryDocument,
  CategoryType,
  ServicesType,
  ServiceType,
  useServiceQuery,
  useUpdateServiceMutation,
} from '../../../graphql/generated/generated';
import { convertMinutesToTimestamp } from '../../../lib/helpers/convertMinutesToTimestamp';
import { getMinutesFromTime } from '../../../lib/helpers/getMinutesFromTime';
import { useAddEditServiceFormik } from '../../../lib/hooks/formik/useAddEditServiceFormik';
import { AddEditServiceForm } from '../AddEditServiceForm/AddEditServiceForm';

import './EditService.scss';

interface Props {
  serviceId: string;
  categoryId: string;
  toggle: () => void;
  resetServiceId: () => void;
}

export const EditService = (props: Props) => {
  const { loading, data, error } = useServiceQuery({ variables: { id: parseInt(props.serviceId) } });
  const [updateServiceMutation, { error: updateError }] = useUpdateServiceMutation();

  const service = data?.service;

  const { toggle, resetServiceId } = props;

  const formik = useAddEditServiceFormik({
    initialValues: {
      // private: service?.is_private || false,
      name: service?.name || '',
      categories: service?.categories?.map((category) => ({ value: category.id, label: category.name })) || [],
      color: { value: service?.color || '', label: service?.color || '' },
      description: service?.description || '',
      providers:
        service?.providers?.map((provider) => ({ value: provider.id.toString(), label: provider.full_name })) || [],
      time: getMinutesFromTime(service?.time)?.toString() || '',
      cost: service?.cost || 0,
    },
    onSubmit: async (values, formikHelpers) => {
      if (!values.providers || !values.categories) return;
      try {
        await updateServiceMutation({
          variables: {
            data: {
              // is_private: values.private,
              // name: values?.name || '',
              categories: values.categories?.map((category) => parseInt(category.value)),
              color: values.color.value,
              description: values.description,
              providers: values.providers?.map((provider) => parseInt(provider.value)),
              time: convertMinutesToTimestamp(parseInt(values.time)),
              // cost: values.cost,

              buffer_time: '',
            },
            id: parseInt(props.serviceId),
          },
          refetchQueries: [{ query: AllServicesDocument }, { query: CategoryDocument }],
        });
        toggle();
        formikHelpers.resetForm();
        resetServiceId();
      } catch (e) {}
    },
  });

  return (
    <AddEditServiceForm
      resetServiceId={resetServiceId}
      loading={loading}
      toggle={toggle}
      formik={formik}
      error={updateError || error}
    />
  );
};
