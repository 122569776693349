import { addMinutes } from 'date-fns/esm';
import { useState } from 'react';
import { Form } from 'reactstrap';
import {
  AppointmentsByArtistsDocument,
  AppointmentType,
  ArtistsAppointments,
  useAddCustomerMutation,
  useUpdateAppointmentMutation,
} from '../../graphql/generated/generated';
import { useAuthContext } from '../../lib/context/AuthContext/AuthContext';
import { useConfirmation } from '../../lib/context/ConfirmationContext/ConfirmationContext';
import { convertDateToTimestamp } from '../../lib/helpers/convertDateToTimeStamp';
import { formatDate } from '../../lib/helpers/formatDate';
import { getAppointments } from '../../lib/helpers/getAppointments';
import { getMinutesAndHourFromTime } from '../../lib/helpers/getMinutesAndHourFromTime';
import { getMinutesAndHoursFromDate } from '../../lib/helpers/getMinutesAndHoursFromDate';
import { transformTimeStampToMinutes } from '../../lib/helpers/transformTimeStampToMinutes';
import { useAppointmentFormik } from '../../lib/hooks/formik/useAppointmentFormik';
import { AppointmentCustomerForm } from '../AppointmentCustomerForm/AppointmentCustomerForm';
import { AppointmentDetailsForm } from '../AppointmentDetailsForm/AppointmentDetailsForm';
import { AppointmentNav } from '../AppointmentNav/AppointmentNav';
import { EditAppointmentType } from '../shared/AppointmentDetails/AppointmentDetails';
import { ErrorMessage } from '../shared/ErrorMessage/ErrorMessage';
import { Status } from '../shared/Status/Status';
import './EditAppointment.scss';

interface Props {
  toggle: () => void;
  view?: string;
  date: Date;
  appointmentToEdit: EditAppointmentType;
}
export const EditAppointment = (props: Props) => {
  const [route, setRoute] = useState<string>('details');
  const confirmation = useConfirmation();
  const [updateAppointment, { error: updateError }] = useUpdateAppointmentMutation();
  const [addCustomer, { error: customerError }] = useAddCustomerMutation();
  const { toggle, view, date, appointmentToEdit } = props;
  const authCtx = useAuthContext();
  const isStaff = authCtx.user?.role_name === 'Artist';

  const formik = useAppointmentFormik({
    initialValues: {
      showAddCustomerForm: false,
      details: {
        provider: {
          label: appointmentToEdit.provider?.full_name || '',
          value: appointmentToEdit.provider?.id.toString() || '',
        },
        status: { value: appointmentToEdit.status || '', label: appointmentToEdit.status || '' },
        time: {
          value: getMinutesAndHoursFromDate(appointmentToEdit.date),
          label: getMinutesAndHoursFromDate(appointmentToEdit.date),
        },
        service: { value: appointmentToEdit.service?.id || '', label: appointmentToEdit.service?.name || '' },
        date: appointmentToEdit.date,
        duration: appointmentToEdit.duration || 0,
        notes: '',
        price: appointmentToEdit?.cost || 0,
        discount: appointmentToEdit.discount || 0,
      },
      customer: { value: appointmentToEdit.customer?.id || '', label: appointmentToEdit.customer?.name || '' },
      addCustomer: {
        name: '',
        mobile: '',
        email: '',
        birthday: undefined,
      },
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        const time = getMinutesAndHourFromTime(values.details.time?.value || '');
        const endDate = addMinutes(
          values.details.date?.setHours(time.hour, time.minutes) || new Date(),
          values.details.duration,
        );
        const endTime = convertDateToTimestamp(endDate);
        const customer =
          values.addCustomer.name &&
          (await addCustomer({
            variables: {
              data: {
                name: values.addCustomer.name,
                email: values.addCustomer.email,
                phone_number: values.addCustomer.mobile,
              },
            },
          }));
        const customer_id = customer && customer.data ? parseInt(customer.data?.addCustomer.id || '') : null;

        await updateAppointment({
          variables: {
            id: +(appointmentToEdit.id || ''),
            data: {
              date: formatDate(values.details.date) || '',
              start_time: `${values.details.time?.value}:00`,
              end_time: endTime,
              provider_id: +(values.details.provider?.value || ''),
              service_id: parseInt(values.details.service?.value || ''),
              cost: values.details.price,
              customer_id: parseInt(values.customer?.value || '') || customer_id,
              status: values.details.status?.value || '',
            },
          },
          update(cache, data) {
            const cachedAppointments = cache.readQuery({
              query: AppointmentsByArtistsDocument,
              variables: {
                options: {
                  view_type: view,
                  provider_id: isStaff ? parseInt(authCtx.user?.id || '') : null,
                  date: view === 'Daily' ? date?.toISOString().slice(0, 10) : null,
                },
              },
            }) as { appointmentsByArtists: ArtistsAppointments };

            const appointments = getAppointments(cachedAppointments, data as AppointmentType);

            cache.writeQuery({
              query: AppointmentsByArtistsDocument,
              data: {
                appointmentsByArtists: {
                  rows: appointments,
                  total_data: cachedAppointments.appointmentsByArtists.total_data,
                },
              },
            });
          },
        });
        toggle();
        formikHelpers.resetForm();
      } catch (e) {}
    },
  });

  const changeRoute = (id: string) => {
    setRoute(id);
  };

  const handleChange = async (option: any) => {
    if (option.label === 'Paid') {
      try {
        await confirmation
          .confirm('Are you sure? Paid appointments can not be edited or deleted hereafter.')
          .then(() => formik.setFieldValue('details.status', option));
      } catch (e) {}
    }
    formik.setFieldValue('details.status', option);
  };

  return (
    <div className="AddAppointment">
      <div className="AddAppointment__tab">
        <AppointmentNav route={route} onNavItemClick={changeRoute} isNavValid={!!formik.errors.details} />
        <Status
          key="details.status"
          id="details.status"
          name="details.status"
          placeholder="Status"
          value={formik.values.details?.status}
          onChange={handleChange}
        />
      </div>
      <Form className="AddAppointment__form" onSubmit={formik.handleSubmit}>
        {route === 'details' ? (
          <AppointmentDetailsForm
            formik={formik}
            toggle={toggle}
            onRouteChange={changeRoute}
            providerStatus={{ providerLoading: false, providerError: undefined }}
          />
        ) : (
          <AppointmentCustomerForm formik={formik} toggle={toggle} />
        )}
        <ErrorMessage errorData={updateError || customerError} />
      </Form>
    </div>
  );
};
