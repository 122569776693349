import { QueryResult } from '@apollo/client';
import { useState } from 'react';
import {
  Exact,
  StaffQuery,
  useDeleteTimeOffMutation,
  UserTimeOffDocument,
  UserTimeOffType,
  useUserTimeOffQuery,
} from '../../../../graphql/generated/generated';
import { AddEditTimeOffModal } from '../../../TimeOff/AddEditTimeOffModal/AddEditTimeOffModal';
import { AddTimeOffView } from '../../../TimeOff/AddTimeOffView/AddTimeOffView';
import { AddNewButton } from '../../../shared/AddNewButton/AddNewButton';
import { Table } from '../../../shared/Table/Table';
import { Title } from '../../../shared/Title/Title';
import { ErrorMessage } from '../../../shared/ErrorMessage/ErrorMessage';
import { getTimeOffRows } from './utils/getTimeOffRows';
import { Skeleton } from '../../../shared/Skeleton/Skeleton';
import { HandleLoadingState } from '../../../shared/HandleLoadingState/HandleLoadingState';

import './TimeOff.scss';

interface Props {
  user: QueryResult<
    StaffQuery,
    Exact<{
      id: number;
    }>
  >;
}

const columns = [
  { key: 'icon', header: '', width: 70 },
  { key: 'date', header: '', width: 700 },
  { key: 'delete', header: '', width: 50 },
];

export const TimeOff = (props: Props) => {
  const { loading, data, error } = props.user;
  const {
    loading: isLoading,
    data: staffTimesOff,
    error: hasError,
  } = useUserTimeOffQuery({ variables: { id: parseInt(data?.staffMember.id || '1') } });
  const [deleteTimeOffMutation, { loading: deleting, error: deleteError }] = useDeleteTimeOffMutation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [timeOffId, setTimeOffId] = useState<string>();

  const toggleModal = () => setIsModalOpen((prevState) => !prevState);

  const handleDeleteClick = async (id: string) => {
    await deleteTimeOffMutation({
      variables: { id: parseInt(id) },
      update(cache) {
        if (!data?.staffMember.id) return;
        const cachedData = cache.readQuery({
          query: UserTimeOffDocument,
          variables: { id: parseInt(data.staffMember.id) },
        }) as {
          userTimeOff: UserTimeOffType[];
        };
        cache.writeQuery({
          query: UserTimeOffDocument,
          variables: { id: parseInt(data?.staffMember.id) },
          data: {
            userTimeOff: cachedData.userTimeOff.filter((userTimeOff) => userTimeOff.id !== id),
          },
        });
      },
    });
  };

  const handleRowClick = (id: string) => {
    setTimeOffId(id);
    toggleModal();
  };

  const rows = getTimeOffRows(staffTimesOff?.userTimeOff, handleDeleteClick, deleting);
  return (
    <div className="TimeOff">
      <div className="TimeOff__header">
        <Title loading={loading} error={error}>
          <>Time Off for {data?.staffMember.full_name}:</>
        </Title>
        <AddNewButton onClick={toggleModal} />
      </div>

      <HandleLoadingState loading={isLoading} placeholder={<TimeOffPlaceHolder />}>
        {staffTimesOff?.userTimeOff.length === 0 ? (
          <AddTimeOffView toggleModal={toggleModal} />
        ) : (
          <Table className="TimeOff__table" columns={columns} rows={rows} onRowClick={handleRowClick} />
        )}
      </HandleLoadingState>

      <ErrorMessage errorData={hasError || deleteError} />

      {data?.staffMember.id && (
        <AddEditTimeOffModal
          timeOffId={timeOffId}
          staffId={data?.staffMember.id}
          isOpen={isModalOpen}
          toggle={toggleModal}
        />
      )}
    </div>
  );
};

function TimeOffPlaceHolder() {
  return (
    <div className="TimeOff__placeholder">
      <Skeleton className="TimeOff__skeleton" count={5} />
    </div>
  );
}
