import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Option } from '../../../components/shared/Select/Select';

export interface AddEditServiceFields {
  name: string;
  description: string;
  providers: Option[];
  cost: number;
  time: string;

  color: Option;
  categories: Option[];
  // private: boolean;
}

interface UseAddEditServiceOptions {
  initialValues?: AddEditServiceFields;
  onSubmit: (values: AddEditServiceFields, formikHelpers: FormikHelpers<AddEditServiceFields>) => Promise<any>;
}

export const useAddEditServiceFormik = (options: UseAddEditServiceOptions) => {
  const AddEditServiceSchema = Yup.object().shape({
    name: Yup.string().required('Service name is required'),
    description: Yup.string().required('Description is required'),
    cost: Yup.number().required('Cost is required'),
    time: Yup.string().required('Time is required'),
    categories: Yup.array().nullable().min(1, 'One category is required'),
    providers: Yup.array().nullable().min(1, 'One provider is required'),
  });

  return useFormik({
    initialValues: {
      name: '',
      description: '',
      providers: [],
      cost: 0,
      time: '0',

      color: { value: '#f0c2c1', label: '' },
      categories: [],
      // private: false,
      ...options.initialValues,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: AddEditServiceSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type AddEditServiceFormik = ReturnType<typeof useAddEditServiceFormik>;
