import React from 'react';
import { Icon } from '../Icon/Icon';
import './NoDataFound.scss';

export const NoDataFound = () => {
  return (
    <div className="NoDataFound">
      <Icon className="NoDataFound__icon" icon="info" />
      <div className="NoDataFound__message">No data found!</div>
    </div>
  );
};
