import { useMemo } from 'react';
import { AppointmentFormik } from '../../lib/hooks/formik/useAppointmentFormik';
import { ValueContainer } from '../AppointmentDetailsForm/AppointmentDetailsForm';
import { getSelectProps } from '../AppointmentDetailsForm/utils/getSelectProps';
import { getTimeOptions } from '../../lib/helpers/getTimeOptions';
import { DatePicker } from '../shared/DatePicker/DatePicker';
import { Select } from '../shared/Select/Select';
import { useWindowWidth } from '../../lib/hooks/useWindowWidth';
import { ScrollOnOption } from '../../components/shared/Select/components/ScrollOnOption';
import cs from 'classnames';

import './DatePickerWithTime.scss';

interface Props {
  formik: AppointmentFormik;
  className?: string;
  date: Date | undefined;
  onDateChange: (date: Date | null) => void;
}

export const DatePickerWithTime = (props: Props) => {
  const { formik, onDateChange, date } = props;
  const width = useWindowWidth();

  const options = useMemo(() => getTimeOptions(24), []);

  const style = {
    control: (provided: any, state: any) => ({
      ...provided,
      minHeight: '36px',
      height: '36px',
      border: 'none',
      boxShadow: '#FFFFFF',
      borderRadius: '0',
      marginRight: '0.05rem',
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      padding: width > 992 ? '5px 14px' : '4px',
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      minHeight: '16px',
      lineHeight: '21px',
      fontSize: '14px',
    }),
  };
  return (
    <div className={cs('DatePickerWithTime', props.className)}>
      <DatePicker
        className="DatePickerWithTime__date"
        placeholder="Time"
        id="details.date"
        date={date || new Date()}
        onDateChange={(date) => onDateChange(date)}
      />
      <div className="DatePickerWithTime__divider"></div>
      <Select
        {...getSelectProps({
          className: 'DatePickerWithTime__select',
          key: 'time',
          placeholder: 'Time',
          valueContainer: ValueContainer,
          isClearable: false,
          formik,
        })}
        options={options}
        selectStyle={style}
        isSearchable={false}
        isSelected
        option={ScrollOnOption}
      />
    </div>
  );
};
