import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

export interface SetPasswordFields {
  password: string;
  confirmPassword: string;
}

interface UseSetPasswordFormOptions {
  initialValues?: SetPasswordFields;
  onSubmit: (values: SetPasswordFields, formikHelpers: FormikHelpers<SetPasswordFields>) => Promise<any>;
}

export const useSetPasswordFormik = (options: UseSetPasswordFormOptions) => {
  const SetPasswordSchema = Yup.object().shape({
    password: Yup.string().min(6, 'Password should be more than 6 letters').required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .min(6, 'Confirm password should be more than 6 letters')
      .required('Confirm password is required'),
  });

  return useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: SetPasswordSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type SetPasswordFormik = ReturnType<typeof useSetPasswordFormik>;
