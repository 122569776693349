import React from 'react';
import { Spinner as ReactstrapSpinner, SpinnerProps } from 'reactstrap';
import cs from 'classnames';

import './Spinner.scss';

interface Props extends SpinnerProps {}

export const Spinner = (props: Props) => {
  const { className, ...rest } = props;
  return <ReactstrapSpinner className={cs('Spinner', className)} {...rest} />;
};
