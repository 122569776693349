import { endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { View } from 'react-big-calendar';
import { BreaksForCalendarQuery } from '../../graphql/generated/generated';
import { formatDate } from './formatDate';
import { getDayNameFromDate } from './getDayNameFromDate';

export const getBreaksEvents = (
  breaks: BreaksForCalendarQuery | undefined,
  date: Date,
  view: View,
  staffId: string,
) => {
  if (!breaks?.breaksForCalendar) return [];

  if (view === 'month') return [];

  if (view === 'day') {
    const selectedDay = getDayNameFromDate(date);

    const filteredBreaks = breaks?.breaksForCalendar.filter((item) => item.day === selectedDay);
    if (!filteredBreaks) return [];

    return filteredBreaks.map((item) => {
      return {
        eventId: item.id,
        resourceId: item?.user_id,
        start: new Date(`${formatDate(date)} ${item.start_time}`),
        end: new Date(`${formatDate(date)} ${item.end_time}`),
        status: 'Break',
        time: '',
        customer: {},
        provider: {},
        service: { color: '#CFE5E7' },
        isDisabled: true,
      };
    });
  }

  let startDate = startOfWeek(date);
  let endDate = endOfWeek(date);
  if (view === 'week') {
    startDate = startOfWeek(date);
    endDate = endOfWeek(date);
  }

  if (!staffId) return [];
  const filteredBreaks = breaks?.breaksForCalendar.filter((item) => item.user_id === parseInt(staffId));

  const breakEvents = filteredBreaks.map((item) => {
    const dateRangeBreaks = [];
    while (startDate <= endDate) {
      let dateDayName = getDayNameFromDate(startDate);

      if (item.day === dateDayName) {
        dateRangeBreaks.push({
          eventId: item.id,
          resourceId: item?.user_id,
          start: new Date(`${formatDate(startDate)} ${item.start_time}`),
          end: new Date(`${formatDate(startDate)} ${item.end_time}`),
          status: 'Break',
          time: '',
          customer: {},
          provider: {},
          service: { color: '#CFE5E7' },
        });
        if (view === 'week') {
          break;
        }
      }

      startDate = new Date(startDate.setDate(startDate.getDate() + 1));
    }

    startDate = view === 'week' ? startOfWeek(date) : startOfMonth(date);
    return dateRangeBreaks;
  });

  return breakEvents.flat();
};
