import { cache } from '../../apollo/cache';
import {
  AppointmentsByArtistsDocument,
  AppointmentsByArtistsQuery,
  AppointmentType,
  useLiveAppointmentsSubscription,
} from '../../graphql/generated/generated';
import { getAppointments } from '../helpers/getAppointments';

export const useLiveUpdates = (appointments: AppointmentsByArtistsQuery | undefined, options: any) => {
  useLiveAppointmentsSubscription({
    variables: { priorities: ['NEW_APPOINTMENT', 'UPDATE_APPOINTMENT', 'DELETE_APPOINTMENT'] },
    onSubscriptionData: (data) => {
      // find if it has newAppointment on Cache that have already been added from Apollo subscription
      const hasAppointmentOnCache = appointments?.appointmentsByArtists.rows
        ?.map((row) =>
          row.appointments?.find(
            (app) => app.id === data.subscriptionData.data?.appointmentSubscription.appointment?.id,
          ),
        )
        .filter((row) => row !== undefined);
      //if hasAppointmentOnCache return early and do not add another appointment
      if (hasAppointmentOnCache && hasAppointmentOnCache?.length > 0) return;

      const subscriptionData = data?.subscriptionData.data?.appointmentSubscription;
      if (!subscriptionData) return;
      if (subscriptionData?.type === 'NEW_APPOINTMENT') {
        const rows = getAppointments(appointments, subscriptionData?.appointment as AppointmentType);

        cache.writeQuery({
          query: AppointmentsByArtistsDocument,
          data: {
            appointmentsByArtists: {
              rows: rows,
              total_data: appointments?.appointmentsByArtists.total_data,
            },
          },
        });
      }
      if (subscriptionData.type === 'DELETE_APPOINTMENT') {
        if (!appointments || !appointments.appointmentsByArtists || !appointments.appointmentsByArtists.rows?.length)
          return;
        const rows = appointments.appointmentsByArtists.rows?.map((row) => {
          return {
            appointments: row.appointments?.filter(
              (appointment) => appointment.id !== subscriptionData.deleted_id?.toString(),
            ),

            email: row?.email,
            full_name: row.full_name,
            id: row.id.toString(),
            __typename: 'UserType',
          };
        });

        cache.writeQuery({
          query: AppointmentsByArtistsDocument,
          data: {
            appointmentsByArtists: {
              rows: rows,
              total_data: appointments.appointmentsByArtists.total_data,
              __typename: appointments.appointmentsByArtists.__typename,
            },
          },
          variables: {
            options: options,
          },
        });
      }

      if (subscriptionData.type === 'EDIT_APPOINTMENT') {
        const rows = getAppointments(appointments, subscriptionData.appointment as AppointmentType);

        cache.writeQuery({
          query: AppointmentsByArtistsDocument,
          data: {
            appointmentsByArtists: {
              rows: rows,
              total_data: appointments?.appointmentsByArtists.total_data,
            },
          },
        });
      }
    },
  });
};
