import React from 'react';
import { components, ValueContainerProps } from 'react-select';

export function CustomValueContainer(props: ValueContainerProps<any, any>) {
  const length = props.getValue().length;
  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue &&
        props.selectProps.name &&
        `${props.selectProps.name?.charAt(0).toUpperCase() + props.selectProps.name?.slice(1)} (${length})`}
      {React.Children.map(props.children as any, (child) => {
        return child?.type === components.Input ? child : null;
      })}
    </components.ValueContainer>
  );
}
