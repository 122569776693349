import './Switch.scss';
import cs from 'classnames';
interface Props {
  selected: boolean;
  toggleSelected: () => void;
  label?: string;
  className?: string;
}
export const Switch = (props: Props) => {
  const { className, selected, toggleSelected, label } = props;
  return (
    <div className={cs('Switch', className)}>
      <label className="Switch__label">{label}</label>
      <div className="Switch__container" onClick={toggleSelected}>
        <div className={`Switch__button ${selected ? '' : 'Switch__disabled'}`}></div>
        <span className={`Switch__text ${selected ? '' : 'Switch__text_disabled'}`}>{selected ? 'ON' : 'OFF'}</span>
      </div>
    </div>
  );
};
