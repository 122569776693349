import React, { useContext } from 'react';

export interface UIContextType {
  isLoginModalOpen: boolean;
  isRegisterModalOpen: boolean;
  isProfileModalOpen: boolean;
  isSidebarOpen: boolean;
  toggleLoginModal: () => void;
  toggleRegisterModal: () => void;
  toggleProfileModal: () => void;
  toggleSidebar: () => void;
}

const UIContextValues: UIContextType = {
  isLoginModalOpen: false,
  isRegisterModalOpen: false,
  isProfileModalOpen: false,
  isSidebarOpen: false,
  toggleLoginModal: () => {},
  toggleRegisterModal: () => {},
  toggleProfileModal: () => {},
  toggleSidebar: () => {},
};

export const UIContext = React.createContext<UIContextType>(UIContextValues);

export const useUIContext = () => useContext(UIContext);
