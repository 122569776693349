import { QueryResult } from '@apollo/client';
import { Exact, StaffQuery } from '../../../graphql/generated/generated';
import { RouteStaffComponentProps } from '../../../pages/Staff/Staff';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import { NoDataFound } from '../../shared/NoDataFound/NoDataFound';
import { Skeleton } from '../../shared/Skeleton/Skeleton';
import { Title } from '../../shared/Title/Title';

import './StaffDetails.scss';

interface Props extends RouteStaffComponentProps {
  user: QueryResult<
    StaffQuery,
    Exact<{
      id: number;
    }>
  >;
}

export const StaffDetails = (props: Props) => {
  const { loading, error, data } = props.user;

  if (loading) return <StaffDetailsPlaceholder />;
  if (error) return <ErrorMessage errorData={error} />;
  if (!data || !data.staffMember) return <NoDataFound />;

  return (
    <div className="StaffDetails">
      <Title description={data.staffMember.description}>{data?.staffMember.full_name}</Title>
      <div className="StaffDetails__information">
        <ul>
          <li>Mobile</li>
          <li>Email</li>
        </ul>
        <ul>
          <li>{data.staffMember.phone_number || 'No mobile number added'} </li>
          <li>{data?.staffMember.email}</li>
        </ul>
      </div>
    </div>
  );
};

function StaffDetailsPlaceholder() {
  return (
    <div className="StaffDetails__placeholder">
      <Skeleton className="StaffDetails__placeholder_name" count={1} />
      <Skeleton className="StaffDetails__placeholder_description" count={1} />
      <Skeleton className="StaffDetails__placeholder_info" count={2} />
    </div>
  );
}
