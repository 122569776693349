import { View } from 'react-big-calendar';
import { TimeOffsForCalendarQuery } from '../../graphql/generated/generated';
import { formatDate } from './formatDate';

export const getTimeOffsEvents = (timeOffs: TimeOffsForCalendarQuery | undefined, view: View) => {
  if (view === 'month') return [];
  const timeOffsEvents =
    timeOffs?.userTimeOffForCalendar.map((timeOff) => {
      let dateRangeTimeOffs = [];
      let date = new Date(timeOff.start_date);
      while (date <= new Date(timeOff.end_date)) {
        dateRangeTimeOffs.push({
          eventId: timeOff.id,
          resourceId: timeOff?.user_id,
          start: new Date(formatDate(date) + ' 00:00:00'),
          end: new Date(formatDate(date) + ' 23:59:59'),
          status: 'Time Off',
          time: '',
          customer: {},
          provider: {},
          service: { color: '#E0E5E9' },
          isDisabled: true,
        });

        date = new Date(date.setDate(date.getDate() + 1));
      }

      return dateRangeTimeOffs;
    }) || [];

  return timeOffsEvents.flat();
};
