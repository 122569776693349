import React from 'react';
import { RouteProps, Switch } from 'react-router-dom';

import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';

import { Login } from '../pages/Login/Login';
import { ResetPassword } from '../pages/ResetPassword/ResetPassword';
import { Profile } from '../pages/Profile/Profile';
import { Page404 } from '../pages/Page404/Page404';
import { RestrictedRoute } from './RestrictedRoute';
import { Services } from '../pages/Services/Services';
import { Reports } from '../pages/Reports/Reports';
import { Calendar } from '../pages/Calendar/Calendar';
import { Customers } from '../pages/Customers/Customers';
import { Staff } from '../pages/Staff/Staff';
import { SetPassword } from '../pages/SetPassword/SetPassword';
import { ForgotPassword } from '../components/user/ForgotPassword/ForgotPassword';

export enum RouteType {
  PUBLIC,
  PRIVATE,
  RESTRICTED,
}
interface AppRoute extends RouteProps {
  type?: RouteType;
}
export const AppRoutes: AppRoute[] = [
  // Restricted Routes
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: 'login',
    component: Login,
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: 'forgot-password',
    component: ForgotPassword,
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: 'reset-password',
    component: ResetPassword,
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: 'set-password',
    component: SetPassword,
  },
  // Private Routes

  {
    type: RouteType.PRIVATE,
    exact: true,
    path: 'reset-password',
    component: ResetPassword,
  },
  {
    type: RouteType.PRIVATE,
    path: 'my-profile',
    component: Profile,
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: '/',
    component: Calendar,
  },
  {
    type: RouteType.PRIVATE,
    path: 'customers',
    component: Customers,
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: 'services',
    component: Services,
  },

  {
    type: RouteType.PRIVATE,
    path: 'staff/:id',
    component: Staff,
  },

  {
    type: RouteType.PRIVATE,
    path: 'services',
    component: Services,
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: 'reports',
    component: Reports,
  },
  // Public Routes
];

export const Routes = () => {
  return (
    <Switch>
      {AppRoutes.map((r) => {
        const { type, path, ...rest } = r;
        if (type === RouteType.PRIVATE) {
          return <PrivateRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />;
        }
        if (type === RouteType.RESTRICTED) {
          return <RestrictedRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />;
        }

        return <PublicRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />;
      })}
      <PublicRoute component={Page404} />
    </Switch>
  );
};
