import { ServicesSidebar } from '../../components/Services/ServicesSidebar/ServicesSidebar';
import { ServicesById } from '../../components/Services/ServicesById/ServicesById';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { AllServices } from '../../components/Services/AllServices/AllServices';

import './Services.scss';

export const Services = (props: RouteComponentProps) => {
  return (
    <div className="Services">
      <ServicesSidebar />

      <Switch>
        <Route path={`${props.match.url}`} component={AllServices} exact />
        <Route path={`${props.match.url}/:id`} component={ServicesById} exact />
      </Switch>
    </div>
  );
};
