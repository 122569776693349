import React from 'react';
import { Icon } from '../../../shared/Icon/Icon';

import './NavSidebarButton.scss';

interface Props {
  toggle: () => void;
}

export const NavSidebarButton = (props: Props) => {
  return (
    <button className="NavSidebarButton" onClick={props.toggle}>
      <Icon icon="arrow-with-radius" className="icon" />
    </button>
  );
};
