import { ApolloError } from '@apollo/client';
import React, { useContext } from 'react';
import { StaffMemberType } from '../../../graphql/generated/generated';
import { User } from './AuthContextProvider';

export interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: User;
  error?: ApolloError;
  login: (user: User | undefined) => void;
  logout: () => void;
  update: (user: StaffMemberType | undefined) => void;
}

const voidFunction = () => {};

const AuthContextValues: AuthContextType = {
  isAuthenticated: false,
  isLoading: false,
  user: undefined,
  error: undefined,
  login: voidFunction,
  logout: voidFunction,
  update: voidFunction,
};

export const AuthContext = React.createContext<AuthContextType>(AuthContextValues);

export const useAuthContext = () => useContext(AuthContext);
