import { UserWorkingHoursType } from '../../graphql/generated/generated';

export function formatInitialValueForWorkingHour(
  days:
    | ({
        __typename?: 'UserWorkingHoursType' | undefined;
      } & Pick<UserWorkingHoursType, 'id' | 'day' | 'start_time' | 'end_time' | 'status' | 'user_id'>)[]
    | undefined,
) {
  if (!days) return [];
  return Object.assign(
    {},
    ...days?.map((day) => {
      return {
        [day.day.toLocaleLowerCase()]: {
          day: day.day,
          start_time: day.start_time?.slice(0, 5),
          end_time: day.end_time?.slice(0, 5),
          status: day.status,
        },
      };
    }),
  );
}
