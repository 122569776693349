import { ActionMeta, components, OptionsType, OptionTypeBase, ValueContainerProps } from 'react-select';
import { Option, Select, SelectProps } from '../Select/Select';

import './Status.scss';

interface Props extends SelectProps {
  value: Option | null;
  onChange?: (
    value: OptionTypeBase | OptionsType<OptionTypeBase> | null,
    actionMeta: ActionMeta<OptionTypeBase>,
  ) => void;
}
export const Status = (props: Props) => {
  const { ...rest } = props;
  return (
    <Select
      {...rest}
      options={options}
      selectStyle={style}
      valueContainer={ValueContainer}
      className="Status"
      isSearchable={false}
    />
  );
};

const style = {
  control: (provided: any, state: any) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    minHeight: '20px',
    minWidth: '90px',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    position: 'absolute',
    right: '-10px',
    top: '-15px',
    width: '150px',
    zIndex: '30',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    width: 'fit-content',
    minHeight: '20px',
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      width: 'fit-content',
      minHeight: '20px',
      top: state.isFocused ? '80%' : '50%',
    };
  },
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: '#BBBBBB',
    minWidth: '20px',
  }),
};

const options = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Confirmed', label: 'Confirmed' },
  { value: 'Done', label: 'Done' },
  { value: 'Paid', label: 'Paid' },
  { value: 'Running Late', label: 'Running late' },
];

function ValueContainer(props: ValueContainerProps<any, any>) {
  return <components.ValueContainer {...props} />;
}
