import { Button, ButtonProps } from '../Button/Button';
import cs from 'classnames';

import './NoBorderButton.scss';

interface Props extends ButtonProps {}

export const NoBorderButton = (props: Props) => {
  const { className, ...rest } = props;

  return <Button className={cs('NoBorderButton', className)} {...rest} />;
};
