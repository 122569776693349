export const addMinutesToTime = (minutesToAdd: number, time?: string) => {
  if (!time) return '08:15:00';
  let hour = parseInt(time.split(':')[0]);
  let minutes = parseInt(time.split(':')[1]);
  if (minutes < 45) {
    minutes = minutes + minutesToAdd;
  }
  if (minutes >= 45) {
    hour = hour + 1;
    minutes = minutes + minutesToAdd - 60;
  }
  if (hour === 23 && minutes > 45) {
    hour = 0;
  }

  return `${hour < 10 ? `0${hour}` : hour}:${minutes === 0 ? `00` : minutes}:00`;
};
