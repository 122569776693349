import { useStaffMembersQuery } from '../../../graphql/generated/generated';
import { Select, SelectProps } from '../../shared/Select/Select';

interface Props extends SelectProps {}

export const StaffSelect = (props: Props) => {
  const { loading, data, error } = useStaffMembersQuery();

  const staffOptions = data?.staffMembers.rows?.map((row) => ({ label: row.full_name, value: row.id }));

  const { label, isMulti, ...rest } = props;
  return (
    <Select
      isMulti={isMulti}
      label={label}
      options={staffOptions}
      loading={loading}
      error={error}
      name="Staff"
      placeholder="Select staff"
      closeMenuOnSelect={false}
      {...rest}
    />
  );
};
