import React, { useRef, useState } from 'react';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';
import cs from 'classnames';
import { useOutsideClickDetection } from '../../../lib/hooks/useOutSideClickDetection';
import { Skeleton } from '../Skeleton/Skeleton';
import { useWindowWidth } from '../../../lib/hooks/useWindowWidth';
import { ApolloError } from '@apollo/client';

import './SelectBox.scss';

interface Props {
  options: any[];
  value: Array<string>;
  multiSelect?: boolean;
  withSearch?: boolean;
  onSelect: (item: Array<string>) => void;
  header: string;
  renderElement: React.ReactChild;
  loading: boolean;
  error?: ApolloError;
}

const SelectBox = (props: Props) => {
  const { options, header, value } = props;
  const [open, setOpen] = useState(false);
  const width = useWindowWidth();
  const [filter, setFilter] = useState('');
  const selectRef = useRef(null);

  const handleOutsideClick = () => setOpen(false);
  useOutsideClickDetection(selectRef, handleOutsideClick);

  const handleItemClick = (option: string) => {
    if (!props.multiSelect) {
      props.onSelect([option]);
      setOpen(!open);
      return;
    }

    if (value.includes(option)) {
      const filteredValue = value.filter((v) => v !== option);
      props.onSelect(filteredValue);
      return;
    }

    props.onSelect([...value, option]);
  };

  const handleAllClick = () => {
    setFilter('');
    if (value.length === options.length) return props.onSelect([]);

    const values = options.map((option) => option.value);
    props.onSelect(values);
  };

  const renderHeader = () => {
    const valuesLength = value.length;

    if (valuesLength === 0) return 'None';

    if (valuesLength === 1) return options?.find((option) => option.value === value[0])?.label;

    if (valuesLength === options.length) return 'All';

    return valuesLength + ' selected';
  };

  const filterValues = (filter: string) => {
    if (filter === '') return options;

    return options.filter((option: any) => {
      return option.label.toLowerCase().includes(filter.toLowerCase());
    });
  };

  if (props.loading) return <Skeleton width={70} />;
  if (props.error) return <pre className="SelectBox__error">{props.error.message}</pre>;
  return (
    <div className={cs('SelectBox', open && 'SelectBox--open')} ref={selectRef}>
      <div onClick={() => setOpen(!open)} className="SelectBox__container">
        <div className="SelectBox__header">{header ? header : renderHeader()}</div>
        <Icon icon="arrow-general" className="SelectBox__icon" />
      </div>

      <div className={cs('SelectBox__menu', open && 'SelectBox__menu--open')}>
        {props.withSearch && (
          <div className="SelectBox__search">
            <Input
              className="SelectBox__input"
              placeholder="Find artist"
              icon="search"
              id="search"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            {width > 992 && props.renderElement}
          </div>
        )}
        <ul className="SelectBox__items">
          {props.multiSelect && <li onClick={handleAllClick}>All</li>}
          {filterValues(filter)?.map((item: any) => {
            const isSelected = value.includes(item.value);
            return (
              <li
                className={cs(isSelected && 'SelectBox__item--active')}
                key={item.value}
                onClick={() => handleItemClick(item.value)}
              >
                {item.label} {isSelected && <Icon className="SelectBox__selected" icon="done" />}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SelectBox;
