import { Route, RouteComponentProps, Switch, useParams } from 'react-router';
import { Breaks } from '../../components/Staff/routes/Breaks/Breaks';
import { StaffDetails } from '../../components/Staff/StaffDetails/StaffDetails';
import { StaffServices } from '../../components/Staff/routes/StaffServices/StaffServices';
import { TimeOff } from '../../components/Staff/routes/TimeOff/TimeOff';
import { WorkingHours } from '../../components/Staff/routes/WorkingHours/WorkingHours';
import { StaffNavbar } from '../../components/Staff/StaffNavbar/StaffNavbar';
import { StaffSidebar } from '../../components/Staff/StaffSidebar/StaffSidebar';
import { QueryResult } from '@apollo/client';
import { Exact, StaffQuery, useStaffQuery } from '../../graphql/generated/generated';

import './Staff.scss';

const routes = [
  {
    id: 'staff-details',
    path: '',
    component: StaffDetails,
  },
  {
    id: 'staff-services',
    path: 'services',
    component: StaffServices,
  },
  {
    id: 'working-hours',
    path: 'working-hours',
    component: WorkingHours,
  },
  {
    id: 'breaks',
    path: 'breaks',
    component: Breaks,
  },
  {
    id: 'time-off',
    path: 'time-off',
    component: TimeOff,
  },
];

export interface RouteStaffComponentProps {
  user: QueryResult<
    StaffQuery,
    Exact<{
      id: number;
    }>
  >;
}

export const Staff = (props: RouteComponentProps) => {
  const { id } = useParams<{ id: string }>();
  const user = useStaffQuery({ variables: { id: parseInt(id) } });
  const path = props.match;

  return (
    <div className="Staff">
      <StaffSidebar />
      <div className="Staff__navigation">
        <StaffNavbar />
        <div className="Staff__route">
          <Switch>
            {routes.map((r) => {
              return (
                <Route key={r.id} path={`${path.url}/${r.path}`} render={() => <r.component user={user} />} exact />
              );
            })}
          </Switch>
        </div>
      </div>
    </div>
  );
};
