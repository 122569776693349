import React from 'react';
//components
import cs from 'classnames';
import { Modal as ModalRS, ModalHeader, ModalProps as ReactstrapModalProps } from 'reactstrap';

//styles
import './Modal.scss';

export interface ModalProps extends ReactstrapModalProps {
  isOpen: boolean;
  children?: React.ReactChild[] | React.ReactChild;
  title?: string;
  big?: boolean;
  toggle: () => void;
}

export const Modal = (props: ModalProps) => {
  return (
    <ModalRS className={cs('Modal', props.className)} isOpen={props.isOpen} toggle={props.toggle}>
      <ModalHeader className="Modal__header">{props.title}</ModalHeader>
      {props.children}
    </ModalRS>
  );
};
