import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Form } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';
import { useResetPasswordMutation } from '../../graphql/generated/generated';
import { useSearchParams } from '../../lib/helpers/useSearchParams';
import { SetPasswordFields, useSetPasswordFormik } from '../../lib/hooks/formik/useSetPasswordFormik';
import { useRouter } from '../../lib/hooks/useRouter';
import { Button } from '../shared/Button/Button';
import { ErrorMessage } from '../shared/ErrorMessage/ErrorMessage';
import { Input, InputProps } from '../shared/Input/Input';
import { ApprovedMessage } from '../user/ApprovedMessage/ApprovedMessage';

import './SetPasswordForm.scss';

interface GetInputProps {
  key: keyof SetPasswordFields;
  type: InputType | undefined;
  placeholder: string;
}

export interface Props {
  title?: string;
}
export const SetPasswordForm = (props: Props) => {
  const [resetPassword, { error }] = useResetPasswordMutation();
  const [success, setSuccess] = useState(true);
  const queryParam = useSearchParams();
  const router = useRouter();

  const formik = useSetPasswordFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await resetPassword({
          variables: {
            data: {
              token: queryParam.get('token') || '',
              new_password: values.password,
              confirm_new_password: values.confirmPassword,
            },
          },
        });
        setSuccess(false);
        formikHelpers.resetForm();
      } catch (e) {}
    },
  });

  useEffect(() => {
    if (!queryParam.get('token')) {
      router.history.push('login');
    }
  }, []);

  const getInputProps = ({ key, type, placeholder, ...props }: GetInputProps) => {
    return {
      className: 'SetPasswordForm__input',
      type: type,
      name: key,
      id: key,
      placeholder: placeholder,
      hiddenLabel: true,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      touched: formik.touched[key],
      error: formik.errors[key],
      value: formik.values[key],
      invalid: formik.touched[key] && !!formik.errors[key],
      ...props,
    } as InputProps;
  };

  return (
    <div className="SetPasswordForm">
      {success ? (
        <>
          <h1 className="SetPasswordForm__title">{props.title ? props.title : 'Set Password'}</h1>
          <Form className="SetPasswordForm__form_wrapper" inline onSubmit={formik.handleSubmit}>
            <Input {...getInputProps({ key: 'password', type: 'password', placeholder: 'Password' })} />
            <Input {...getInputProps({ key: 'confirmPassword', type: 'password', placeholder: 'Confirm Password' })} />
            <Button
              className="SetPasswordForm__button"
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid}
              loading={formik.isSubmitting}
            >
              GO
            </Button>
            <ErrorMessage errorData={error} />
          </Form>
        </>
      ) : (
        <ApprovedMessage title="Approve" description="Password changed successfully, please log in here" />
      )}
    </div>
  );
};
