import { InputType } from 'reactstrap/es/Input';
import { LoginFields, LoginFormik } from '../../../lib/hooks/formik/useLoginFormik';
import { InputProps } from '../../shared/Input/Input';

export interface GetInputProps extends InputProps {
  key: keyof LoginFields;
  type: InputType | undefined;
  placeholder: string;
  formik: LoginFormik;
}
export const getInputProps = ({ key, type, placeholder, formik, ...props }: GetInputProps) => {
  return {
    className: 'LoginForm__input',
    type: type,
    name: key,
    id: key,
    placeholder: placeholder,
    hiddenLabel: true,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    touched: formik.touched[key],
    error: formik.errors[key],
    value: formik.values[key],
    invalid: formik.touched[key] && !!formik.errors[key],
    ...props,
  };
};
