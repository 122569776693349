import { eventPropGetter } from './utils/eventPropGetter';
import { StaffMemberType } from '../../../graphql/generated/generated';
import {
  Calendar as ReactCalendar,
  CalendarProps,
  Components,
  Event as CalendarEvent,
  SlotInfo,
} from 'react-big-calendar';
import { useState } from 'react';
import { CalendarHeader } from './CalendarHeader/CalendarHeader';

import cs from 'classnames';
import './BigCalendar.scss';
import { calendarLocalizer } from '../../../lib/helpers/calendarLocalizer';

// export interface EventType extends CalendarEvent {
//   totalPrice?: number;
// }
export interface EventType extends CalendarEvent {
  resourceId: number;
  status?: string;
  serviceInfo: { color?: string; cost?: number; name?: string };
}
interface ResourceMap {
  resourceId: number;
  resourceTitle: string;
}

interface Props extends CalendarProps {
  resourceMap?: ResourceMap[];
  onSlotSelection: (slotInfo: SlotInformation) => void;
  staff: StaffMemberType[] | undefined;
  components: Components<any, ResourceMap> | undefined;
}

export interface SlotInformation extends SlotInfo {
  resourceId?: number;
}

export const BigCalendar = (props: Props) => {
  const [expandRow, setExpandRow] = useState<boolean>(false);
  return (
    <ReactCalendar
      className={cs('BigCalendar', { BigCalendar__expanded: expandRow })}
      localizer={calendarLocalizer}
      views={['day', 'week', 'month']}
      events={props.events as any}
      defaultView="day"
      defaultDate={new Date()}
      startAccessor="start"
      endAccessor="end"
      resources={props.resourceMap}
      resourceTitleAccessor="resourceTitle"
      resourceIdAccessor="resourceId"
      min={new Date(0, 0, 0, 0, 0, 0)}
      max={new Date(0, 0, 0, 23, 59, 59)}
      onSelectSlot={(slotInfo) => props.onSlotSelection(slotInfo)}
      eventPropGetter={(e) => eventPropGetter(e)}
      components={{ ...props.components, header: CalendarHeader }}
      selectable
      formats={{
        dayRangeHeaderFormat: function (_ref3, culture: any, local: any) {
          var start = _ref3.start;
          var end = _ref3.end;
          return local.format(start, 'd.mm', culture) + ' - ' + local.format(end, 'd mmm yyyy', culture);
        },
        timeGutterFormat: 'HH:mm',
      }}
      step={15}
      timeslots={4}
      onShowMore={() => setExpandRow(true)}
      popup
    />
  );
};
