import { NavLink } from 'react-router-dom';
import { useRouter } from '../../../lib/hooks/useRouter';

import './StaffNavbar.scss';

const navItems = [
  { to: '', name: 'Details' },
  { to: '/services', name: 'Services' },
  { to: '/working-hours', name: 'Working hours' },
  { to: '/breaks', name: 'Breaks' },
  { to: '/time-off', name: 'Time off' },
];

export const StaffNavbar = () => {
  const router = useRouter();
  return (
    <div className="StaffNavbar">
      <ul className="StaffNavbar__list">
        {navItems.map((item) => (
          <li key={item.to}>
            <NavLink
              className="StaffNavbar__item"
              activeClassName="StaffNavbar__item_active"
              to={`${router.match.url}${item.to}`}
              exact
            >
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
