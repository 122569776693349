import { useState } from 'react';
import { useParams } from 'react-router';
import { TableWrapper } from '../../shared/TableWrapper/TableWrapper';
import { Table } from '../../shared/Table/Table';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import { AddEditServiceModal } from '../AddEditServiceModal/AddEditServiceModal';
import {
  AllServicesDocument,
  ServicesType,
  useAllServicesQuery,
  // useDeleteServiceMutation,
} from '../../../graphql/generated/generated';

import './AllServices.scss';
import { getMinutesFromTime } from '../../../lib/helpers/getMinutesFromTime';
import { Icon } from '../../shared/Icon/Icon';
import { useConfirmation } from '../../../lib/context/ConfirmationContext/ConfirmationContext';

const columns = [
  { key: 'color', header: '', width: 15 },
  { key: 'name', header: '', width: 705 },
  { key: 'time', header: '', width: 315 },
  { key: 'cost', header: '', width: 80 },
  // { key: 'delete', header: '', width: 35 },
];

export const AllServices = () => {
  const { id } = useParams<{ id: string }>();
  const { loading, data, error } = useAllServicesQuery();
  // const [deleteServiceMutation, { loading: deleting, error: deleteError }] = useDeleteServiceMutation();
  const [serviceId, setServiceId] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const confirmation = useConfirmation();

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  // const handleDeleteClick = async (id: string) => {
  //   try {
  //     await deleteServiceMutation({
  //       variables: { id: parseInt(id) },
  //       update(cache) {
  //         const cachedData = cache.readQuery({ query: AllServicesDocument }) as { services: ServicesType };
  //         cache.writeQuery({
  //           query: AllServicesDocument,
  //           data: { services: cachedData.services.rows?.filter((row) => row.id !== id) },
  //         });
  //       },
  //     });
  //   } catch (e) {}
  // };

  // const rows = getServicesRows(handleDeleteClick, data?.services.rows, deleting);

  const rows = data?.services.rows?.map((row) => ({
    id: row.id,
    name: row.name,
    color: <div style={{ width: '0.313rem', height: '1.125rem', backgroundColor: row.color }}></div>,
    cost: row.cost ? `${row.cost} €` : '-',
    time: `${getMinutesFromTime(row.time)} mins`,
    // delete: (
    //   <Icon
    //     className="AllServices__delete"
    //     onClick={(e) => {
    //       e.stopPropagation();
    //       confirmation.confirm('Are you sure you want to delete?').then(() => {
    //         handleDeleteClick(row.id);
    //       });
    //     }}
    //     spin={deleting}
    //     icon={deleting ? 'spinner' : 'trash'}
    //   />
    // ),
  }));

  const handleRowClick = (id: string) => {
    setServiceId(id);
    toggleModal();
  };

  const resetServiceId = () => setServiceId(undefined);

  return (
    <div className="AllServices">
      <TableWrapper title="Services" toggleModal={toggleModal}>
        <Table
          className="AllServices__table"
          loading={loading}
          error={error}
          columns={columns}
          rows={rows}
          onRowClick={handleRowClick}
        />
      </TableWrapper>
      <ErrorMessage
      //  errorData={deleteError}
      />
      <AddEditServiceModal
        serviceId={serviceId}
        resetServiceId={resetServiceId}
        categoryId={id}
        isOpen={isModalOpen}
        toggle={toggleModal}
      />
    </div>
  );
};
