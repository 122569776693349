import { NavigateAction, ToolbarProps, View } from 'react-big-calendar';
import { Icon } from '../../Icon/Icon';
import { ViewSwitcher } from '../ViewSwitcher/ViewSwitcher';
import { IncomeViewer } from '../../../IncomeViewer/IncomeViewer';
import { StaffMembersType, useGetReportQuery } from '../../../../graphql/generated/generated';
import { DateChanger } from '../DateChanger/DateChanger';
import { StaffPagination } from '../../../StaffPagination/StaffPagination';
import { getFiltersForReport } from '../../../../lib/helpers/getFiltersForReport';
import { Dropdown } from '../../Dropdown/Dropdown';
import { useState } from 'react';
import { AddStaffForm } from '../../../AddStaffForm/AddStaffForm';
import { Alert } from 'reactstrap';
import { useWindowWidth } from '../../../../lib/hooks/useWindowWidth';
import { ApolloError } from '@apollo/client';
import { useAuthContext } from '../../../../lib/context/AuthContext/AuthContext';
import { AddNewButton } from '../../AddNewButton/AddNewButton';
import SelectBox from '../../SelectBox/SelectBox';

import './Toolbar.scss';

interface Props extends ToolbarProps {
  staff?: StaffMembersType;
  selectValue?: Array<string>;
  onPageChange: (pageNumber: number) => void;
  pageNumber: number;
  selectHeader?: string;
  onProviderChange: (providerId: Array<string>) => void;
  onDateChange: (_: any, date: Date | undefined) => void;
  onViewChange: (view: View) => void;
  calendarLoading?: boolean;
  error?: ApolloError;
  loading: boolean;
}

export const Toolbar = (props: Props) => {
  const width = useWindowWidth();
  const authCtx = useAuthContext();
  const { onProviderChange, selectValue, selectHeader } = props;
  const { start_date, end_date } = getFiltersForReport(props.date, props.view);
  const { loading, data, error } = useGetReportQuery({ variables: { filters: { start_date, end_date } } });
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const staffOptions = props.staff?.rows.map((staff) => ({ value: staff.id, label: staff.full_name }));

  const handleNavigate = (action: NavigateAction, newDate?: Date | undefined) => {
    props.onDateChange(action, newDate);
    props.onNavigate(action, newDate);
  };

  return (
    <div className="Toolbar">
      <div className="Toolbar__content">
        <div className="Toolbar__filters">
          <div className="Toolbar__filter">
            {authCtx.user?.role_name === 'Admin' && (
              <>
                <Icon className="Toolbar__artist" icon="profile" />
                <SelectBox
                  loading={props.loading}
                  error={props.error}
                  value={selectValue || ['']}
                  onSelect={onProviderChange}
                  withSearch
                  header={selectHeader ? selectHeader : 'All Staff'}
                  options={[{ value: '', label: 'All Staff' }, ...(staffOptions || [])] || []}
                  renderElement={
                    <Dropdown
                      className="Toolbar__staffForm"
                      title="Add New staff"
                      isOpen={dropdownOpen}
                      toggle={toggleDropdown}
                      items={<AddStaffForm toggle={toggleDropdown} />}
                      toggleButton={<AddNewButton />}
                    />
                  }
                />
              </>
            )}
          </div>
        </div>

        <div className="Toolbar__right-side">
          <div className="Toolbar__navigation">
            <DateChanger date={props.date} view={props.view} onNavigate={handleNavigate} />
            {width > 992 && (
              <>
                <IncomeViewer loading={loading} error={error} amount={data?.report.total} />
                <ViewSwitcher
                  className="Toolbar__view_switcher"
                  activeView={props.view}
                  onView={props.onView}
                  onViewChange={props.onViewChange}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="Toolbar__pagination">
        {props.view === 'day' && selectValue?.[0] === '' && (
          <StaffPagination
            totalMembers={parseInt(props.staff?.total_data || '')}
            pageNumber={props.pageNumber}
            onPageChange={props.onPageChange}
          />
        )}
        {props.calendarLoading && (
          <Alert className="Toolbar__alert" color="warning">
            Calendar is loading...
          </Alert>
        )}
      </div>
    </div>
  );
};
