import { ApolloError } from '@apollo/client';
import { useState } from 'react';
import { Form, ModalFooter } from 'reactstrap';
import { AddEditTimeOffFormik } from '../../../lib/hooks/formik/useAddEditTimeOffFormik';
import { DateAndTimePicker } from '../../shared/DateAndTimePicker/DateAndTimePicker';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import { FormButtons } from '../../shared/FormButtons/FormButtons';
import { HandleLoadingState } from '../../shared/HandleLoadingState/HandleLoadingState';
import { Input } from '../../shared/Input/Input';
import { Skeleton } from '../../shared/Skeleton/Skeleton';
import { Switch } from '../../shared/Switch/Switch';

import './AddEditTimeOffForm.scss';

interface Props {
  formik: AddEditTimeOffFormik;
  toggle: () => void;
  error?: ApolloError;
  loading?: boolean;
}

export const AddEditTimeOffForm = (props: Props) => {
  const [withTime, setWithTime] = useState<boolean>(true);

  const toggleSwitch = () => setWithTime((prevState) => !prevState);
  const { formik, error, toggle, loading } = props;

  return (
    <Form className="AddEditTimeOffForm" onSubmit={formik.handleSubmit}>
      <HandleLoadingState loading={loading} placeholder={<AddEditTimeOffFormPlaceholder />}>
        <DateAndTimePicker
          label="Start Date"
          date={formik.values.startDate}
          time={formik.values.startTime}
          withInputTime={!withTime}
          onTimeChange={(time) => {
            formik.setFieldValue('startTime', time);
          }}
          onDateChange={(date) => {
            formik.setFieldValue('startDate', date);
          }}
        />
        <DateAndTimePicker
          label="End Date"
          date={formik.values.endDate}
          time={formik.values.endTime}
          withInputTime={!withTime}
          onTimeChange={(time) => {
            formik.setFieldValue('endTime', time);
          }}
          onDateChange={(date) => {
            formik.setFieldValue('endDate', date);
          }}
        />
        {/* <Input
          label="Description"
          className="AddEditTimeOffForm__input"
          type="textarea"
          name="description"
          id="description"
          placeholder="Description"
          wrapperClassName="AddEditTimeOffForm__wrapper_input"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.description}
          error={formik.errors.description}
          value={formik.values.description}
          invalid={formik.touched.description && !!formik.errors.description}
        />
        <Switch
          className="AddEditTimeOffForm__switch"
          label="All day"
          selected={withTime}
          toggleSelected={toggleSwitch}
        /> */}
      </HandleLoadingState>
      <ModalFooter className="AddEditTimeOffForm__modalFooter">
        <FormButtons
          submitButtonName="Save Changes"
          disabled={formik.isSubmitting || !formik.isValid}
          loading={formik.isSubmitting}
          onCancelClick={() => {
            formik.resetForm();
            toggle();
          }}
        />
        <ErrorMessage className="AddEditTimeOffForm__error" errorData={error} />
      </ModalFooter>
    </Form>
  );
};

function AddEditTimeOffFormPlaceholder() {
  return (
    <div className="AddEditTimeOffForm__placeholder">
      <Skeleton className="AddEditTimeOffForm__skeleton" count={2} />
      <Skeleton className="AddEditTimeOffForm__content" count={1} />
    </div>
  );
}
