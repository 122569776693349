import { error } from 'console';
import React, { useState } from 'react';

import {
  useCategoriesQuery,
  useDeleteCategoryMutation,
  CategoriesDocument,
  CategoryType,
} from '../../graphql/generated/generated';
import { useRouter } from '../../lib/hooks/useRouter';
import { AddCategoryForm } from '../Services/AddCategoryForm/AddCategoryForm';
import { AddNewButton } from '../shared/AddNewButton/AddNewButton';
import { Dropdown } from '../shared/Dropdown/Dropdown';
import { Sidebar } from '../shared/Sidebar/Sidebar';

interface Props {
  dropdownToggle?: () => void;
}
export const SidebarServiceList = (props: Props) => {
  const router = useRouter();
  const { loading, data, error } = useCategoriesQuery();
  const [deleteCategoryMutation, deletingCategoryResponse] = useDeleteCategoryMutation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleDeleteClick = async (id: string) => {
    const paramId = router.location.pathname.split('/')[2];
    try {
      await deleteCategoryMutation({
        variables: { id: parseInt(id) },
        update(cache) {
          const cachedData = cache.readQuery({ query: CategoriesDocument }) as { categories: CategoryType[] };
          cache.writeQuery({
            query: CategoriesDocument,
            data: { categories: cachedData.categories.filter((category) => category.id !== id) },
          });
        },
      });
    } catch (e) {
    } finally {
      if (paramId === id) {
        router.history.push('/services');
      }
    }
  };
  return (
    <Sidebar
      title="Categories"
      loading={loading}
      error={error}
      items={data?.categories}
      withInput={false}
      withAll="All Services"
      onDeleteItem={handleDeleteClick}
      deleting={deletingCategoryResponse}
      dropdownToggle={props.dropdownToggle}
      addNewButton={
        <Dropdown
          title="Add New Category"
          isOpen={dropdownOpen}
          toggle={toggleDropdown}
          items={<AddCategoryForm toggle={toggleDropdown} />}
          toggleButton={<AddNewButton />}
        />
      }
    />
  );
};
