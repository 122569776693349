import { useState } from 'react';
import { Event as CalendarEvent } from 'react-big-calendar';
import { AppointmentDetails, EditAppointmentType } from '../../AppointmentDetails/AppointmentDetails';
import { CustomerType, ProviderType, ServiceType } from '../../../../graphql/generated/generated';
import { Popover } from '../../Popover/Popover';
import cs from 'classnames';

import './Event.scss';

export interface Service {
  color?: string;
  cost?: number;
  name?: string;
}
export interface EventType extends CalendarEvent {
  resourceId: number;
  status: string;
  eventId: string;
  provider: ProviderType;
  service: ServiceType;
  customer: CustomerType;
  time: string;
  cost: number;
  isDisabled?: boolean;
  duration?: number;
}

export interface Options {
  view_type?: string;
  provider_id: number | null;
  date: string | null;
  start_date?: string;
  end_date?: string;
}
interface EventProps {
  title: string;
  event: EventType;
  options: Options;
  onEditClick: (appointment: EditAppointmentType) => void;
}

export const Event = (props: EventProps) => {
  const { event, options } = props;
  const [xCord, setXCord] = useState<number>(0);

  const isBreak = event.status === 'Break';

  return (
    <>
      <button
        className={cs('Event__button', {
          Event__disabled: event.isDisabled,
          Event__break: isBreak,
          Event__noShow: event,
        })}
        style={{ backgroundColor: event.service.color }}
        id={`Popover-${event.eventId}`}
        onClick={(e) => {
          if (event.isDisabled) return;
          e.currentTarget.focus();
          setXCord(e.clientX);
        }}
      >
        <div className="Event__date">
          {event.start?.toLocaleTimeString().slice(0, 5)} - {event.end?.toLocaleTimeString().slice(0, 5)}
          <div>{event.status}</div>
        </div>
        <div className="Event__customer">{event.customer?.name}</div>
        {event.service.name && (
          <div>
            <span>Service: </span>
            {event.service.name}
          </div>
        )}
        {event.service.cost && (
          <div>
            <span>Price: </span>
            {event.cost}
          </div>
        )}
      </button>
      {!event.isDisabled && (
        <Popover
          className={cs('Event__popover', { Event__right: xCord < 750 })}
          target={`Popover-${event.eventId}`}
          trigger="focus"
          placement={xCord < 750 ? 'right' : 'left'}
          title="Appointment Details"
        >
          <AppointmentDetails
            id={event.eventId}
            customer={event.customer}
            provider={event.provider}
            service={event.service}
            status={event.status}
            duration={event.duration}
            cost={event.cost}
            date={event.start}
            onEditClick={props.onEditClick}
            time={`${event.start?.toLocaleTimeString().slice(0, 5)} - ${event.end?.toLocaleTimeString().slice(0, 5)}`}
            options={options}
            canBeEdited
          />
        </Popover>
      )}
    </>
  );
};
