import { UserDetailsFormik } from '../../../lib/hooks/formik/useUserDetailsFormik';
import { Input } from '../../shared/Input/Input';

import './PasswordForm.scss';

interface Props {
  formik: UserDetailsFormik;
}

export const PasswordForm = (props: Props) => {
  const { formik } = props;
  return (
    <>
      <Input
        className="PasswordForm__input"
        label="Current Password"
        type="password"
        name="currentPassword"
        id="currentPassword"
        placeholder="Current Password"
        hiddenLabel
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        touched={formik.touched.currentPassword}
        value={formik.values.currentPassword}
        error={formik.errors.currentPassword}
        invalid={formik.touched.currentPassword && !!formik.errors.currentPassword}
      />
      <Input
        className="PasswordForm__input"
        label="New Password"
        type="password"
        name="newPassword"
        id="newPassword"
        placeholder="New Password"
        hiddenLabel
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        touched={formik.touched.newPassword}
        value={formik.values.newPassword}
        error={formik.errors.newPassword}
        invalid={formik.touched.newPassword && !!formik.errors.newPassword}
      />
      <Input
        className="PasswordForm__input"
        label="Retype Password"
        type="password"
        name="confirmPassword"
        id="confirmPassword"
        placeholder="Retype New Password"
        hiddenLabel
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        touched={formik.touched.confirmPassword}
        value={formik.values.confirmPassword}
        error={formik.errors.confirmPassword}
        invalid={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
      />
    </>
  );
};
