import cs from 'classnames';
import { View } from 'react-big-calendar';
import './ViewSwitcher.scss';

interface Props {
  className: string;
  activeView: string;
  onView: (view: View) => void;
  onViewChange: (view: View) => void;
}
export const ViewSwitcher = (props: Props) => {
  const views: View[] = ['day', 'week', 'month'];

  return (
    <ul className={cs('ViewSwitcher', props.className && props.className)}>
      {views.map((view: View, idx) => (
        <li
          data-text={view}
          onClick={() => {
            props.onView(view);
            props.onViewChange(view);
          }}
          className={cs('ViewSwitcher__item', { active_view: view === props.activeView })}
          key={`${view}-${idx}`}
        >
          {view.charAt(0).toUpperCase() + view.slice(1)}
        </li>
      ))}
    </ul>
  );
};
