import { Form, ModalFooter } from 'reactstrap';
import { FormButtons } from '../../shared/FormButtons/FormButtons';
import { InputProps } from '../../shared/Input/Input';
import { Modal, ModalProps } from '../../shared/Modal/Modal';
import { UserDetailsField, useUserDetailsFormik } from '../../../lib/hooks/formik/useUserDetailsFormik';
import { useState } from 'react';
import { useUpdateProfileMutation } from '../../../graphql/generated/generated';
import { useAuthContext } from '../../../lib/context/AuthContext/AuthContext';
import { UserDetailsForm } from '../UserDetailsForm/UserDetailsForm';
import { PasswordForm } from '../PasswordForm/PasswordForm';

import './UserDetailsModal.scss';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';

interface Props extends ModalProps {}

export interface GetInputProps extends InputProps {
  key: keyof UserDetailsField;
}
export const UserDetailsModal = (props: Props) => {
  const { toggle, ...rest } = props;
  const { user, update } = useAuthContext();
  const [updateProfile, { error }] = useUpdateProfileMutation();
  const [showPasswordForm, setShowPasswordForm] = useState<boolean>(false);

  const formik = useUserDetailsFormik({
    initialValues: {
      fullName: user?.full_name || '',
      email: user?.email || '',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        const user = await updateProfile({
          variables: {
            data: {
              profile: {
                email: values.email,
                full_name: values.fullName,
              },
              password: {
                current_password: values.currentPassword,
                new_password: values.newPassword,
                confirm_new_password: values.confirmPassword,
              },
            },
          },
        });
        update(user.data?.updateProfile);
        toggle();
        formikHelpers.resetForm();
      } catch (e) {}
    },
  });

  return (
    <Modal
      title={!showPasswordForm ? 'My details' : 'Update Password'}
      toggle={() => {
        toggle();
        setShowPasswordForm(false);
      }}
      className="UserDetailsModal"
      {...rest}
    >
      <Form className="UserDetailsModal__form" onSubmit={formik.handleSubmit}>
        {!showPasswordForm ? (
          <UserDetailsForm formik={formik} onUpdatePasswordClick={() => setShowPasswordForm(true)} />
        ) : (
          <PasswordForm formik={formik} />
        )}

        <ModalFooter className="UserDetailsModal__footer ">
          <FormButtons
            submitClassName="UserDetailsModal__submit"
            submitButtonName="Save"
            disabled={formik.isSubmitting || !formik.isValid}
            loading={formik.isSubmitting}
            toggle={() => {
              toggle();
              setShowPasswordForm(false);
            }}
          />
        </ModalFooter>
        <ErrorMessage errorData={error} />
      </Form>
    </Modal>
  );
};
