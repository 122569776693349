import React, { useEffect, useState } from 'react';
import { AuthContextType, AuthContext } from './AuthContext';
import {
  LoggedInUserDocument,
  LoggedInUserQueryResult,
  LoggedInUserQueryVariables,
  StaffMemberType,
} from '../../../graphql/generated/generated';
import { LoadingScreen } from '../../../components/LoadingScreen/LoadingScreen';
import { useApolloClient } from '@apollo/client';
import useLocalStorage from '@rehooks/local-storage';
interface AuthContextProviderProps {
  children: React.ReactNode | null;
}

export const LOCAL_STORAGE_KEY = 'ANGELS_BOOKING_PLATFORM';

export interface User {
  access_token: string;
  expires_in: string;
  id: string;
  full_name: string;
  role_name: string;
  role_id: number;
  email: string;
}

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  // const { loading, data, error, refetch } = useLoggedInUserQuery();
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [user, setUser] = useState<User | undefined>(undefined);

  const [userStorageDetails] = useLocalStorage(LOCAL_STORAGE_KEY);

  const checkAuthentication = async () => {
    if (!userStorageDetails) {
      setLoading(false);
      setUser(undefined);
      return;
    }
    setLoading(true);

    try {
      const { data } = await apolloClient.query<LoggedInUserQueryVariables, LoggedInUserQueryResult>({
        query: LoggedInUserDocument,
        fetchPolicy: 'network-only',
      });

      setUser(data.getLoggedInUser);
    } catch (err: any) {
      setError(err);
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = (user: User | undefined) => {
    if (!user) return;
    localStorage.setItem(LOCAL_STORAGE_KEY, user.access_token);

    setUser(user);
  };

  const update = (user: StaffMemberType | undefined) => {
    if (!user) return;
    setUser((prevState) => {
      if (!prevState)
        return { access_token: '', expires_in: '', email: '', full_name: '', role_name: '', id: '', role_id: 0 };
      return { ...prevState, full_name: user.full_name, email: user.email };
    });
  };

  const logout = async () => {
    setUser(undefined);
    await apolloClient.clearStore();
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  };

  if (loading) return <LoadingScreen />;

  const context: AuthContextType = {
    isAuthenticated: user !== undefined,
    isLoading: loading,
    error: error,
    user: user,
    login: login,
    logout: logout,
    update: update,
  };

  return <AuthContext.Provider value={context}>{props.children}</AuthContext.Provider>;
};
