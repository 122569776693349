import { useAddTimeOffMutation, UserTimeOffDocument, UserTimeOffType } from '../../../graphql/generated/generated';
import { formatDate } from '../../../lib/helpers/formatDate';
import { useAddEditTimeOffFormik } from '../../../lib/hooks/formik/useAddEditTimeOffFormik';
import { AddEditTimeOffForm } from '../AddEditTimeOffForm/AddEditTimeOffForm';

import './AddTimeOff.scss';

interface Props {
  toggle: () => void;
  staffId: string;
}

const currentDate = new Date();

export const AddTimeOff = (props: Props) => {
  const [addTimeOffMutation, { error }] = useAddTimeOffMutation();

  const { staffId, toggle, ...rest } = props;
  const formik = useAddEditTimeOffFormik({
    initialValues: {
      startDate: currentDate,
      endDate: currentDate,
      description: '',
      startTime: null,
      endTime: null,
    },
    onSubmit: async (values, formikHelpers) => {
      // const startTime = getMinutesAndHourFromTime(values.startTime?.value);
      // const endTime = getMinutesAndHourFromTime(values.startTime?.value);
      // console.log(values.startDate?.toISOString());
      try {
        await addTimeOffMutation({
          variables: {
            data: {
              description: values.description,
              user_id: parseInt(staffId),
              start_date: formatDate(values.startDate) || '',
              end_date: formatDate(values.endDate) || '',
              // end_date: values.endDate?.setHours(endTime.hour, endTime.minutes).toString() || '',
              // start_date: values.startDate?.setHours(startTime.hour, startTime.minutes).toString() || '',
            },
          },
          update(cache, data) {
            const cachedData = cache.readQuery({
              query: UserTimeOffDocument,
              variables: { id: parseInt(staffId) },
            }) as {
              userTimeOff: UserTimeOffType[];
            };
            if (!data.data?.addUserTimeOff) return;
            cache.writeQuery({
              query: UserTimeOffDocument,
              variables: { id: parseInt(staffId) },
              data: { userTimeOff: cachedData.userTimeOff.concat([data.data?.addUserTimeOff]) },
            });
          },
        });
        toggle();
        formikHelpers.resetForm();
      } catch (e) {}
    },
  });
  return <AddEditTimeOffForm toggle={toggle} error={error} formik={formik} {...rest} />;
};
