import { QueryResult } from '@apollo/client';
import { Exact, StaffQuery } from '../../../../graphql/generated/generated';
import { Title } from '../../../shared/Title/Title';
import { WorkingHoursForm } from '../../../WorkingHoursForm/WorkingHoursForm';
import './WorkingHours.scss';

interface Props {
  user: QueryResult<
    StaffQuery,
    Exact<{
      id: number;
    }>
  >;
}
export const WorkingHours = (props: Props) => {
  const { data, loading, error } = props.user;

  return (
    <div className="WorkingHours">
      <Title loading={loading} error={error}>
        <>{data?.staffMember.full_name}'s Working Hours</>
      </Title>
      {data?.staffMember.id && <WorkingHoursForm staffId={data?.staffMember.id} />}
    </div>
  );
};
