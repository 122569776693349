import React, { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AccountItems } from './AccountItems/AccountItems';
import { useRouter } from '../../../lib/hooks/useRouter';
import { Dropdown } from '../../shared/Dropdown/Dropdown';
import { Icon } from '../../shared/Icon/Icon';
import { useAuthContext } from '../../../lib/context/AuthContext/AuthContext';
import { getNameInitials } from '../../../lib/helpers/getNameInitials';
import { UserDetailsModal } from '../../user/UserDetailsModal/UserDetailsModal';
import { useWindowWidth } from '../../../lib/hooks/useWindowWidth';

import './Header.scss';
import { NotificationModal } from '../../Notification/NotificationModal/NotificationModal';

export const Header = () => {
  const router = useRouter();
  const authCtx = useAuthContext();
  const width = useWindowWidth();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);

  const toggleModal = () => setIsModalOpen((prevState) => !prevState);
  const toggleModalNotification = () => setIsNotificationOpen((prevState) => !prevState);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const initials = useMemo(() => getNameInitials(authCtx.user?.full_name), [authCtx.user?.full_name]);
  return (
    <div className="Header">
      <Icon className="Header__icon" icon="logo" onClick={() => router.history.push('/')} />

      <div className="Header__navigation_wrapper">
        <ul className="Header__navigation">
          {authCtx.user?.role_name === 'Admin' && (
            <li>
              <NavLink className="Header__navigation_item" to={`/staff/${authCtx.user?.id}`}>
                Staff
              </NavLink>
            </li>
          )}
          <Dropdown
            className="Header__dropdown"
            menuClassName="Header__menu"
            nav
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            toggleButton={
              <div className="Header__toggle_account">
                Account <Icon className="Header__toggle_icon" icon="arrow-general" />{' '}
              </div>
            }
            items={<AccountItems onUserInfoClick={toggleModal} />}
          />
          {authCtx.user?.role_name === 'Admin' && (
            <div className="Header__notification" onClick={toggleModalNotification}>
              <Icon className="Header__icon--notification" icon="notification" />
            </div>
          )}
        </ul>
        <span className="Header__avatar">{initials}</span>
      </div>
      <UserDetailsModal isOpen={isModalOpen} toggle={toggleModal} />
      <NotificationModal isOpen={isNotificationOpen} toggle={toggleModalNotification} />
    </div>
  );
};
