import React, { FocusEvent, useEffect, useRef, useState } from 'react';
import ReactSelect, {
  ActionMeta,
  components,
  GroupTypeBase,
  OptionsType,
  OptionTypeBase,
  Styles,
  ValueContainerProps,
} from 'react-select';
import { Icon, IconDefinition } from '../Icon/Icon';
import { styles } from './styles';
import { Skeleton } from '../Skeleton/Skeleton';
import cs from 'classnames';

import './Select.scss';
import { CustomValueContainer } from './components/CustomValueContainer';

export interface Option {
  label: string;
  value: string;
}
export interface SelectProps {
  label?: string;
  name?: string;
  id?: string;
  options?: readonly (OptionTypeBase | GroupTypeBase<OptionTypeBase>)[] | undefined;
  placeholder?: string;
  loading?: boolean;
  error?: any;
  value?: OptionTypeBase | readonly OptionTypeBase[] | null | undefined;
  isMulti?: boolean;
  isClearable?: boolean;
  onChange?: (
    value: OptionTypeBase | OptionsType<OptionTypeBase> | null,
    actionMeta: ActionMeta<OptionTypeBase>,
  ) => void;
  onBlur?: (e: FocusEvent<any>) => void;
  className?: string;
  closeMenuOnSelect?: boolean;
  touched?: boolean;
  customStyles?: Partial<Styles<OptionTypeBase, boolean, GroupTypeBase<any>>> | undefined;
  option?: any;
  valueContainer?: any;
  selectStyle?: any;
  isSearchable?: boolean;
  dropDownIcon?: IconDefinition;
  isDisabled?: boolean;
  isSelected?: boolean;
  defaultValue?: any;
  isOptionDisabled?: ((option: OptionTypeBase, options: OptionsType<OptionTypeBase>) => boolean) | undefined;
}

export const Select = (props: SelectProps) => {
  const {
    error,
    touched,
    option,
    isDisabled,
    loading,
    dropDownIcon,
    label,
    className,
    customStyles,
    selectStyle,
    closeMenuOnSelect,
    valueContainer,
    value,
    ...rest
  } = props;

  function DropdownIndicator(props: any) {
    return (
      <components.DropdownIndicator {...props}>
        <Icon
          className="Select__icon"
          style={{ cursor: 'pointer' }}
          icon={dropDownIcon ? dropDownIcon : 'arrow-general'}
        />
      </components.DropdownIndicator>
    );
  }

  const CustomOption = (props: any) => <components.Option {...props} />;

  if (loading)
    return (
      <div className="Select__placeholder">
        <Skeleton className="Select__skeleton" />
      </div>
    );

  const style = styles(selectStyle);
  return (
    <div className="Select__wrapper">
      {label && <label className="Select__label">{label}</label>}
      <div className="Select__container">
        <ReactSelect
          select
          isDisabled={isDisabled}
          isOptionDisabled={props.isOptionDisabled}
          className={cs('Select', className)}
          components={{
            IndicatorSeparator,
            DropdownIndicator,
            ValueContainer: valueContainer ? valueContainer : !!CustomValueContainer ? CustomValueContainer : undefined,
            Option: option ? option : CustomOption,
          }}
          isClearable={false}
          hideSelectedOptions={false}
          closeMenuOnSelect={closeMenuOnSelect}
          styles={customStyles ? customStyles : style}
          invalid={props.touched && !!props.error}
          value={value}
          menuShouldScrollIntoView
          {...rest}
        />
        {error && touched && <span className="Select__error">{error}</span>}
      </div>
    </div>
  );
};

function IndicatorSeparator() {
  return null;
}
