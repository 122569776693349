import { UIContextProvider } from './lib/context/UIContext/UIContextProvider';
import { AuthContextProvider } from './lib/context/AuthContext/AuthContextProvider';
import { ConfirmationContextProvider } from './lib/context/ConfirmationContext/ConfirmationContextProvider';
import { client } from './apollo/client';

//Partials
import { Routes } from './routes/Routes';

//Styles
import './App.scss';
import { ApolloProvider } from '@apollo/client';

function App() {
  return (
    <div className="App container">
      <ApolloProvider client={client}>
        <AuthContextProvider>
          <UIContextProvider>
            <ConfirmationContextProvider>
              <Routes />
            </ConfirmationContextProvider>
          </UIContextProvider>
        </AuthContextProvider>
      </ApolloProvider>
    </div>
  );
}

export default App;
