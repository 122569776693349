import { AppointmentDetailsFields, AppointmentFormik } from '../../../lib/hooks/formik/useAppointmentFormik';
import cs from 'classnames';
import { InputProps } from '../../shared/Input/Input';

interface GetInputProps {
  key: keyof AppointmentDetailsFields;
  type: string;
  placeholder?: string;
  label?: string;
  className?: string;
  formik: AppointmentFormik;
  inputClassName?: string;
}

export const getInputProps = ({
  key,
  type,
  placeholder,
  label,
  className,
  formik,
  inputClassName,
  ...props
}: GetInputProps) => {
  return {
    className: cs('AppointmentDetailsForm__input', className),
    inputClassName: cs('AppointmentDetailsForm__field', inputClassName),
    type: type,
    name: key,
    label: label,
    id: key,
    placeholder: placeholder,
    hiddenLabel: false,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    touched: formik.touched.details?.[key],
    error: formik.errors.details?.[key],
    value: formik.values.details?.[key],
    invalid: formik.touched.details?.[key] && !!formik.errors.details?.[key],
    ...props,
  } as InputProps;
};
