import React, { useEffect } from 'react';
import { useState } from 'react';
import { Alert } from 'reactstrap';
import { ApolloError } from '@apollo/client';
import cs from 'classnames';

import './ErrorMessage.scss';

interface Props {
  errorData?: ApolloError;
  duration?: number;
  className?: string;
}

export const ErrorMessage = (props: Props) => {
  const { className, errorData, duration, ...rest } = props;

  const [visible, setVisible] = useState<boolean>(false);
  useEffect(() => {
    if (!errorData) {
      setVisible(false);
      return;
    }
    setVisible(true);
    const timer = setTimeout(
      () => {
        setVisible(false);
      },
      duration ? duration * 1000 : 3000,
    );
    return () => clearTimeout(timer);
  }, [duration, errorData]);
  const onDismiss = () => setVisible(false);

  if (!visible) return null;

  if (props.errorData?.message)
    return (
      <Alert isOpen={visible} className={cs('ErrorMessage', className)} color="danger" toggle={onDismiss} {...rest}>
        {props.errorData?.message}
      </Alert>
    );
  if (!errorData || errorData.graphQLErrors.length === 0) return null;

  return (
    <div>
      {errorData.graphQLErrors.map(({ message }: any, i: number) => (
        <Alert
          isOpen={visible}
          className={cs('ErrorMessage', className)}
          color="danger"
          key={`ErrorMessage-${i}`}
          toggle={onDismiss}
          {...rest}
        >
          {message}
        </Alert>
      ))}
    </div>
  );
};
