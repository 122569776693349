import { ApolloError } from '@apollo/client';
import { ErrorMessage } from '../../ErrorMessage/ErrorMessage';
import { Skeleton } from '../../Skeleton/Skeleton';
import { useRouter } from '../../../../lib/hooks/useRouter';
import cs from 'classnames';
import { Icon } from '../../Icon/Icon';
import { useConfirmation } from '../../../../lib/context/ConfirmationContext/ConfirmationContext';

import './SidebarList.scss';
import { useState } from 'react';
import { useWindowWidth } from '../../../../lib/hooks/useWindowWidth';

interface Props {
  items?: { id: string; name: string }[];
  loading: boolean;
  error?: ApolloError;
  withAll?: string;
  onDeleteItem?: (id: string) => void;
  deleting?: boolean;
  className?: string;
  dropdownToggle?: any;
}

export const SidebarList = (props: Props) => {
  const router = useRouter();
  const confirmation = useConfirmation();
  const width = useWindowWidth();

  const { className, items, loading, error, onDeleteItem, deleting, dropdownToggle } = props;

  const handleClick = () => {
    return width < 992 ? dropdownToggle() : null;
  };

  if (loading)
    return (
      <Skeleton
        className="Sidebar__skeleton"
        count={8}
        color="rgba(16, 15, 15, 0.3)"
        highlightColor="rgba(16, 15, 15, 0.35)"
      />
    );
  if (error) return <ErrorMessage errorData={error} />;
  if (!items || items.length === 0) return <div className="SidebarList__noData">No items found!</div>;

  const path = router.match.url.split('/')?.[1];
  const id = router.location.pathname.split('/')?.[2];
  const alternativePath = router.location.pathname.split('/')?.[3];

  return (
    <ul className={cs('SidebarList', className)}>
      {props.withAll && (
        <li
          key="all"
          className={cs('SidebarList__item', { SidebarList__item_selected: undefined === id })}
          onClick={() => {
            router.history.push(`${router.match.path}`);
          }}
        >
          {props.withAll}
        </li>
      )}
      {items?.map((item, i) => (
        <li
          className={cs('SidebarList__item', { SidebarList__item_selected: item.id === id })}
          key={item.id}
          onClick={() => {
            handleClick();
            if (!alternativePath) {
              router.history.push(`/${path}/${item.id}`);
            }
            if (!alternativePath) return;
            router.history.push(`/${path}/${item.id}/${alternativePath}`);
          }}
        >
          {item.name}
          <Icon
            className={cs('SidebarList__delete', { SidebarList__deleting: deleting && item.id === id })}
            icon={deleting ? 'spinner' : 'trash'}
            spin={deleting}
            onClick={(e) => {
              confirmation.confirm('Are you sure you want to delete?').then(() => {
                onDeleteItem && onDeleteItem(item.id);
              });
              e.stopPropagation();
            }}
          />
        </li>
      ))}
    </ul>
  );
};
