import React from 'react';

import { Button } from '../Button/Button';
import { NoBorderButton } from '../NoBorderButton/NoBorderButton';
import cs from 'classnames';

import './FormButtons.scss';

interface Props {
  submitButtonName: string;
  disabled: boolean;
  loading: boolean;
  submitClassName?: string;
  cancelClassName?: string;
  toggle?: () => void;
  resetForm?: () => void;
  onCancelClick?: () => void;
}

export const FormButtons = (props: Props) => {
  const { submitButtonName, loading, disabled, toggle, submitClassName, cancelClassName, resetForm, onCancelClick } =
    props;

  const handleCancel = () => {
    onCancelClick && onCancelClick();
    resetForm && resetForm();
    toggle && toggle();
  };
  return (
    <div className="FormButtons">
      <NoBorderButton className={cs('FormButtons__cancel', cancelClassName)} type="reset" onClick={handleCancel}>
        Cancel
      </NoBorderButton>
      <Button
        className={cs('FormButtons__submit', submitClassName)}
        type="submit"
        loading={loading}
        disabled={disabled}
      >
        {submitButtonName}
      </Button>
    </div>
  );
};
