import React from 'react';
import { Form } from 'reactstrap';
import { useAddCategoryFormik } from '../../../lib/hooks/formik/useAddCategoryFormik';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import { CategoriesDocument, CategoryType, useAddCategoryMutation } from '../../../graphql/generated/generated';
import { FormButtons } from '../../shared/FormButtons/FormButtons';
import { Input } from '../../shared/Input/Input';

import './AddCategoryForm.scss';

interface Props {
  toggle: () => void;
}

export const AddCategoryForm = (props: Props) => {
  const [categoryMutation, { error }] = useAddCategoryMutation();

  const formik = useAddCategoryFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await categoryMutation({
          variables: {
            data: {
              name: values.category,
            },
          },
          update(cache, data) {
            const cachedData = cache.readQuery({ query: CategoriesDocument }) as { categories: CategoryType[] };
            if (!data.data || !data.data.addCategory) return;
            cache.writeQuery({
              query: CategoriesDocument,
              data: {
                categories: cachedData.categories.concat([data.data?.addCategory]),
              },
            });
          },
        });
        formikHelpers.resetForm();
        props.toggle && props.toggle();
      } catch (e) {}
    },
  });

  return (
    <div className="AddCategoryForm">
      <Form inline onSubmit={formik.handleSubmit}>
        <Input
          className="AddCategoryForm__input"
          type="text"
          name="category"
          id="category"
          placeholder="Category name"
          wrapperClassName="AddCategoryForm__wrapper_input"
          hiddenLabel
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.category}
          error={formik.errors.category}
          value={formik.values.category}
          invalid={formik.touched.category && !!formik.errors.category}
        />

        <FormButtons
          submitButtonName="Add category"
          disabled={formik.isSubmitting || !formik.isValid}
          loading={formik.isSubmitting}
          submitClassName="AddCategoryForm__submit"
          toggle={props.toggle}
        />

        <ErrorMessage errorData={error} />
      </Form>
    </div>
  );
};
