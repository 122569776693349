import { SetPasswordForm } from '../../components/SetPasswordForm/SetPasswordForm';
import './SetPassword.scss';

export interface Props {
  title: string;
}

export const SetPassword = (props: Props) => {
  return (
    <div className="SetPassword">
      <SetPasswordForm title={props.title} />
    </div>
  );
};
