import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type AddAppointmentInput = {
  date: Scalars['String'];
  start_time: Scalars['String'];
  end_time?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  discountedPrice?: Maybe<Scalars['Float']>;
  time?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  provider_id: Scalars['Float'];
  service_id: Scalars['Float'];
  customer_id?: Maybe<Scalars['Float']>;
  customer?: Maybe<AddCustomerInput>;
};

export type AddCategoryInput = {
  name: Scalars['String'];
};

export type AddCustomerInput = {
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
};

export type AddNotificationInput = {
  title: Scalars['String'];
  description: Scalars['String'];
};

export type AddStaffInput = {
  full_name: Scalars['String'];
  email: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type AddUserBreaksInput = {
  day: Scalars['String'];
  start_time: Scalars['String'];
  end_time: Scalars['String'];
  user_id: Scalars['Float'];
};

export type AddUserTimeOffInput = {
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  description: Scalars['String'];
  user_id: Scalars['Float'];
};

export type AddUserWorkingHoursInput = {
  working_hours: Array<UserWorkingHours>;
  user_id: Scalars['Float'];
};

export type AppointmentPayload = {
  __typename?: 'AppointmentPayload';
  type: Scalars['String'];
  appointment?: Maybe<AppointmentType>;
  deleted_id?: Maybe<Scalars['Float']>;
};

export type AppointmentType = {
  __typename?: 'AppointmentType';
  id: Scalars['ID'];
  date: Scalars['String'];
  start_time: Scalars['String'];
  end_time?: Maybe<Scalars['String']>;
  cost: Scalars['Float'];
  status: Scalars['String'];
  provider_id: Scalars['Float'];
  service_id: Scalars['Float'];
  customer_id?: Maybe<Scalars['Float']>;
  service?: Maybe<ServiceType>;
  provider?: Maybe<ProviderType>;
  customer?: Maybe<CustomerType>;
};

export type AppointmentsType = {
  __typename?: 'AppointmentsType';
  total_data: Scalars['ID'];
  rows?: Maybe<Array<AppointmentType>>;
};

export type ArtistsAppointments = {
  __typename?: 'ArtistsAppointments';
  total_data: Scalars['ID'];
  rows?: Maybe<Array<UserType>>;
};

export type Break = {
  __typename?: 'Break';
  id: Scalars['Float'];
  start_time: Scalars['String'];
  end_time: Scalars['String'];
};

export type CategoryType = {
  __typename?: 'CategoryType';
  id: Scalars['ID'];
  name: Scalars['String'];
  services?: Maybe<Array<ServiceType>>;
};

export type ChangePasswordInput = {
  current_password: Scalars['String'];
  new_password: Scalars['String'];
  confirm_new_password: Scalars['String'];
};

export type CustomerType = {
  __typename?: 'CustomerType';
  id: Scalars['ID'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  appointments?: Maybe<Array<AppointmentType>>;
  role_id: Scalars['Float'];
  role_name: Scalars['String'];
  access_token: Scalars['String'];
  expires_in: Scalars['String'];
};

export type CustomersAppointments = {
  __typename?: 'CustomersAppointments';
  total_data: Scalars['ID'];
  rows?: Maybe<Array<CustomerType>>;
};

export type CustomersType = {
  __typename?: 'CustomersType';
  total_data: Scalars['ID'];
  rows?: Maybe<Array<CustomerType>>;
};


export type DeleteStaffMemberType = {
  __typename?: 'DeleteStaffMemberType';
  message: Scalars['String'];
};

export type DeleteUserWorkingHoursInput = {
  day: Scalars['String'];
  user_id: Scalars['Float'];
};

export type FilterType = {
  key: Scalars['String'];
  operator: Scalars['String'];
  value: Scalars['String'];
  not?: Maybe<Scalars['Boolean']>;
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type GetAppointmentsInput = {
  view_type?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
};

export type GetUserBreaksType = {
  __typename?: 'GetUserBreaksType';
  day: Scalars['String'];
  breaks?: Maybe<Array<Break>>;
  user_id: Scalars['Float'];
};

export type GraphqlQueryParserInput = {
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  sortByDesc?: Maybe<Scalars['String']>;
  sortByAsc?: Maybe<Scalars['String']>;
  relations?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<FilterType>>;
  filterByOr?: Maybe<Array<FilterType>>;
};

export type LoginCustomerInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  device_token?: Maybe<Scalars['String']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAppointment: AppointmentType;
  addAppointmentForSub: AppointmentType;
  updateAppointment: AppointmentType;
  deleteAppointment: ReturnMessageType;
  addCategory: CategoryType;
  updateCategory: CategoryType;
  deleteCategory: ReturnMessageType;
  registerCustomer: CustomerType;
  loginCustomer: CustomerType;
  updateCustomerProfile: CustomerType;
  updateCustomerPassword: CustomerType;
  forgotPasswordCustomer: ReturnMessageType;
  resetPasswordCustomer: ReturnMessageType;
  addCustomer: CustomerType;
  updateCustomer: CustomerType;
  deleteCustomer: ReturnMessageType;
  addNotification: NotificationType;
  addService: ReturnMessageType;
  updateService: ServiceType;
  deleteService: ReturnMessageType;
  changePassword: StaffMemberType;
  forgotPassword: ReturnMessageType;
  resetPassword: ReturnMessageType;
  updateProfile: StaffMemberType;
  addStaffMember: StaffMemberType;
  resendSetPasswordRequest: ReturnMessageType;
  updateStaffMember: StaffMemberType;
  deleteStaffMember: DeleteStaffMemberType;
  login: UserType;
  register: UserType;
  addUserBreak: UserBreaksType;
  updateUserBreak: UserBreaksType;
  deleteUserBreak: ReturnMessageType;
  addUserTimeOff: UserTimeOffType;
  updateUserTimeOff: UserTimeOffType;
  deleteUserTimeOff: ReturnMessageType;
  addUserWorkingHours: Array<UserWorkingHoursType>;
  deleteUserWorkingHours: ReturnMessageType;
};


export type MutationAddAppointmentArgs = {
  data: AddAppointmentInput;
};


export type MutationAddAppointmentForSubArgs = {
  parseResourceOptions?: Maybe<GraphqlQueryParserInput>;
};


export type MutationUpdateAppointmentArgs = {
  data?: Maybe<UpdateAppointmentInput>;
  id: Scalars['Float'];
};


export type MutationDeleteAppointmentArgs = {
  id: Scalars['Float'];
};


export type MutationAddCategoryArgs = {
  data: AddCategoryInput;
};


export type MutationUpdateCategoryArgs = {
  data?: Maybe<UpdateCategoryInput>;
  id: Scalars['Float'];
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['Float'];
};


export type MutationRegisterCustomerArgs = {
  data: RegisterCustomerInput;
};


export type MutationLoginCustomerArgs = {
  data: LoginCustomerInput;
};


export type MutationUpdateCustomerProfileArgs = {
  data: UpdateCustomerInput;
};


export type MutationUpdateCustomerPasswordArgs = {
  data: ChangePasswordInput;
};


export type MutationForgotPasswordCustomerArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordCustomerArgs = {
  data: ResetPasswordInput;
};


export type MutationAddCustomerArgs = {
  data: AddCustomerInput;
};


export type MutationUpdateCustomerArgs = {
  data?: Maybe<UpdateCustomerInput>;
  id: Scalars['Float'];
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['Float'];
};


export type MutationAddNotificationArgs = {
  data: AddNotificationInput;
};


export type MutationUpdateServiceArgs = {
  data?: Maybe<UpdateServiceInput>;
  id: Scalars['Float'];
};


export type MutationChangePasswordArgs = {
  data: ChangePasswordInput;
};


export type MutationForgotPasswordArgs = {
  data: ForgotPasswordInput;
};


export type MutationResetPasswordArgs = {
  data: ResetPasswordInput;
};


export type MutationUpdateProfileArgs = {
  data: UpdateProfileInput;
};


export type MutationAddStaffMemberArgs = {
  data: AddStaffInput;
};


export type MutationResendSetPasswordRequestArgs = {
  data: ResendSetPasswordInput;
};


export type MutationUpdateStaffMemberArgs = {
  data: UpdateStaffInput;
  id: Scalars['Float'];
};


export type MutationDeleteStaffMemberArgs = {
  id: Scalars['Float'];
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationRegisterArgs = {
  data: RegisterInput;
};


export type MutationAddUserBreakArgs = {
  data: AddUserBreaksInput;
};


export type MutationUpdateUserBreakArgs = {
  data: UpdateUserBreaksInput;
  id: Scalars['Float'];
};


export type MutationDeleteUserBreakArgs = {
  id: Scalars['Float'];
};


export type MutationAddUserTimeOffArgs = {
  data: AddUserTimeOffInput;
};


export type MutationUpdateUserTimeOffArgs = {
  data: UpdateUserTimeOffInput;
  id: Scalars['Float'];
};


export type MutationDeleteUserTimeOffArgs = {
  id: Scalars['Float'];
};


export type MutationAddUserWorkingHoursArgs = {
  data: AddUserWorkingHoursInput;
};


export type MutationDeleteUserWorkingHoursArgs = {
  data: DeleteUserWorkingHoursInput;
};

export type NotificationType = {
  __typename?: 'NotificationType';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
};

export type NotificationsType = {
  __typename?: 'NotificationsType';
  total_data: Scalars['ID'];
  rows?: Maybe<Array<NotificationType>>;
};

export type ProfileUpdateInput = {
  full_name: Scalars['String'];
  email: Scalars['String'];
};

export type ProviderType = {
  __typename?: 'ProviderType';
  id: Scalars['Float'];
  full_name: Scalars['String'];
  email: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  appointments: AppointmentsType;
  appointment: AppointmentType;
  appointmentsByArtists: ArtistsAppointments;
  suggestAvailableSlots: Array<SuggestAvailableSlotsType>;
  customerAppointments: CustomersAppointments;
  categories: Array<CategoryType>;
  category: CategoryType;
  customers: CustomersType;
  customer: CustomerType;
  notifications: NotificationsType;
  report: ReportType;
  services: ServicesType;
  publicServices: ServicesType;
  service: ServiceType;
  staffMembers: StaffMembersType;
  staffMember: StaffMemberType;
  providersWithSerivce: StaffMembersType;
  userBreaks: Array<GetUserBreaksType>;
  breaksForCalendar: Array<UserBreaksType>;
  users: Array<UserType>;
  getLoggedInUser: UserType;
  userTimeOff: Array<UserTimeOffType>;
  userTimeOffForCalendar: Array<UserTimeOffType>;
  userTimeOffById: UserTimeOffType;
  userWorkingHours: Array<UserWorkingHoursType>;
  userWorkingHoursForCalendar: Array<UserWorkingHoursType>;
};


export type QueryAppointmentsArgs = {
  parseResourceOptions?: Maybe<GraphqlQueryParserInput>;
};


export type QueryAppointmentArgs = {
  parseResourceOptions?: Maybe<GraphqlQueryParserInput>;
  id: Scalars['Float'];
};


export type QueryAppointmentsByArtistsArgs = {
  parseResourceOptions?: Maybe<GraphqlQueryParserInput>;
  options?: Maybe<GetAppointmentsInput>;
};


export type QuerySuggestAvailableSlotsArgs = {
  data: SuggestAvailableSlotsInput;
};


export type QueryCustomerAppointmentsArgs = {
  parseResourceOptions?: Maybe<GraphqlQueryParserInput>;
  options?: Maybe<GetAppointmentsInput>;
};


export type QueryCategoriesArgs = {
  parseResourceOptions?: Maybe<GraphqlQueryParserInput>;
};


export type QueryCategoryArgs = {
  parseResourceOptions?: Maybe<GraphqlQueryParserInput>;
  id: Scalars['Float'];
};


export type QueryCustomersArgs = {
  parseResourceOptions?: Maybe<GraphqlQueryParserInput>;
};


export type QueryCustomerArgs = {
  parseResourceOptions?: Maybe<GraphqlQueryParserInput>;
  id: Scalars['Float'];
};


export type QueryNotificationsArgs = {
  parseResourceOptions?: Maybe<GraphqlQueryParserInput>;
};


export type QueryReportArgs = {
  filters: ReportFilterInput;
};


export type QueryServicesArgs = {
  parseResourceOptions?: Maybe<GraphqlQueryParserInput>;
};


export type QueryPublicServicesArgs = {
  parseResourceOptions?: Maybe<GraphqlQueryParserInput>;
};


export type QueryServiceArgs = {
  parseResourceOptions?: Maybe<GraphqlQueryParserInput>;
  id: Scalars['Float'];
};


export type QueryStaffMembersArgs = {
  parseResourceOptions?: Maybe<GraphqlQueryParserInput>;
};


export type QueryStaffMemberArgs = {
  id: Scalars['Float'];
};


export type QueryProvidersWithSerivceArgs = {
  service_id: Scalars['Float'];
};


export type QueryUserBreaksArgs = {
  id: Scalars['Float'];
};


export type QueryUserTimeOffArgs = {
  id: Scalars['Float'];
};


export type QueryUserTimeOffForCalendarArgs = {
  data: StartEndDateInput;
};


export type QueryUserTimeOffByIdArgs = {
  id: Scalars['Float'];
};


export type QueryUserWorkingHoursArgs = {
  id: Scalars['Float'];
};

export type RegisterCustomerInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  phone_number: Scalars['String'];
  birthday: Scalars['String'];
  password: Scalars['String'];
  confirm_password: Scalars['String'];
  device_token?: Maybe<Scalars['String']>;
};

export type RegisterInput = {
  full_name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type ReportFilterInput = {
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  services?: Maybe<Array<Scalars['Float']>>;
  providers?: Maybe<Array<Scalars['Float']>>;
};

export type ReportType = {
  __typename?: 'ReportType';
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  appointments: Scalars['Float'];
  total: Scalars['Float'];
};

export type ResendSetPasswordInput = {
  email: Scalars['String'];
};

export type ResetPasswordInput = {
  token: Scalars['String'];
  new_password: Scalars['String'];
  confirm_new_password: Scalars['String'];
};

export type ReturnMessageType = {
  __typename?: 'ReturnMessageType';
  message: Scalars['String'];
};

export type ServiceType = {
  __typename?: 'ServiceType';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  cost: Scalars['Float'];
  time: Scalars['String'];
  buffer_time: Scalars['String'];
  is_private: Scalars['Boolean'];
  color: Scalars['String'];
  categories?: Maybe<Array<CategoryType>>;
  providers?: Maybe<Array<ProviderType>>;
};

export type ServicesType = {
  __typename?: 'ServicesType';
  total_data: Scalars['ID'];
  rows?: Maybe<Array<ServiceType>>;
};

export type StaffMemberType = {
  __typename?: 'StaffMemberType';
  id: Scalars['ID'];
  full_name: Scalars['String'];
  email: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  appointments?: Maybe<Array<AppointmentType>>;
  services?: Maybe<Array<ServiceType>>;
};

export type StaffMembersType = {
  __typename?: 'StaffMembersType';
  total_data: Scalars['ID'];
  rows: Array<StaffMemberType>;
};

export type StartEndDateInput = {
  start_date: Scalars['String'];
  end_date: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  appointmentSubscription: AppointmentPayload;
};


export type SubscriptionAppointmentSubscriptionArgs = {
  priorities: Array<Scalars['String']>;
};

export type SuggestAvailableSlotsInput = {
  provider_id: Scalars['Float'];
  service_id: Scalars['Float'];
  date: Scalars['String'];
};

export type SuggestAvailableSlotsType = {
  __typename?: 'SuggestAvailableSlotsType';
  start_time: Scalars['String'];
  end_time: Scalars['String'];
};

export type UpdateAppointmentInput = {
  date?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  discountedPrice?: Maybe<Scalars['Float']>;
  time?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Float']>;
};

export type UpdateCategoryInput = {
  name?: Maybe<Scalars['String']>;
};

export type UpdateCustomerInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
};

export type UpdateProfileInput = {
  profile?: Maybe<ProfileUpdateInput>;
  password?: Maybe<ChangePasswordInput>;
};

export type UpdateServiceInput = {
  description?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  buffer_time?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Scalars['Float']>>;
  providers?: Maybe<Array<Scalars['Float']>>;
};

export type UpdateStaffInput = {
  full_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['Float']>;
  phone_number?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  services?: Maybe<Array<Scalars['Float']>>;
};

export type UpdateUserBreaksInput = {
  start_time?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
};

export type UpdateUserTimeOffInput = {
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type UserBreaksType = {
  __typename?: 'UserBreaksType';
  id: Scalars['ID'];
  day: Scalars['String'];
  start_time: Scalars['String'];
  end_time: Scalars['String'];
  user_id: Scalars['Float'];
};

export type UserTimeOffType = {
  __typename?: 'UserTimeOffType';
  id: Scalars['ID'];
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  description: Scalars['String'];
  user_id: Scalars['Float'];
};

export type UserType = {
  __typename?: 'UserType';
  id: Scalars['ID'];
  full_name: Scalars['String'];
  email: Scalars['String'];
  role_id: Scalars['Float'];
  role_name: Scalars['String'];
  appointments?: Maybe<Array<AppointmentType>>;
  access_token: Scalars['String'];
  expires_in: Scalars['String'];
};

export type UserWorkingHours = {
  day: Scalars['String'];
  start_time?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type UserWorkingHoursType = {
  __typename?: 'UserWorkingHoursType';
  id: Scalars['ID'];
  day: Scalars['String'];
  start_time?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  user_id: Scalars['Float'];
};

export type AppointmentFieldsFragment = (
  { __typename?: 'AppointmentType' }
  & Pick<AppointmentType, 'id' | 'date' | 'start_time' | 'end_time' | 'cost' | 'status'>
  & { service?: Maybe<(
    { __typename?: 'ServiceType' }
    & Pick<ServiceType, 'id' | 'name' | 'cost' | 'color' | 'time'>
  )>, customer?: Maybe<(
    { __typename?: 'CustomerType' }
    & Pick<CustomerType, 'id' | 'name'>
  )>, provider?: Maybe<(
    { __typename?: 'ProviderType' }
    & Pick<ProviderType, 'email' | 'id' | 'full_name'>
  )> }
);

export type AddAppointmentMutationVariables = Exact<{
  data: AddAppointmentInput;
}>;


export type AddAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { addAppointment: (
    { __typename?: 'AppointmentType' }
    & Pick<AppointmentType, 'id' | 'date' | 'start_time' | 'end_time' | 'cost' | 'status'>
    & { service?: Maybe<(
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'name' | 'cost' | 'time'>
    )>, provider?: Maybe<(
      { __typename?: 'ProviderType' }
      & Pick<ProviderType, 'id' | 'full_name' | 'email'>
    )>, customer?: Maybe<(
      { __typename?: 'CustomerType' }
      & Pick<CustomerType, 'id' | 'name' | 'email'>
    )> }
  ) }
);

export type DeleteAppointmentMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DeleteAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { deleteAppointment: (
    { __typename?: 'ReturnMessageType' }
    & Pick<ReturnMessageType, 'message'>
  ) }
);

export type UpdateAppointmentMutationVariables = Exact<{
  data?: Maybe<UpdateAppointmentInput>;
  id: Scalars['Float'];
}>;


export type UpdateAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { updateAppointment: (
    { __typename?: 'AppointmentType' }
    & Pick<AppointmentType, 'id' | 'date' | 'start_time' | 'end_time' | 'cost' | 'status'>
    & { service?: Maybe<(
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'name' | 'cost' | 'time'>
    )>, provider?: Maybe<(
      { __typename?: 'ProviderType' }
      & Pick<ProviderType, 'id' | 'full_name' | 'email'>
    )>, customer?: Maybe<(
      { __typename?: 'CustomerType' }
      & Pick<CustomerType, 'id' | 'name' | 'email'>
    )> }
  ) }
);

export type AddCategoryMutationVariables = Exact<{
  data: AddCategoryInput;
}>;


export type AddCategoryMutation = (
  { __typename?: 'Mutation' }
  & { addCategory: (
    { __typename?: 'CategoryType' }
    & Pick<CategoryType, 'id' | 'name'>
  ) }
);

export type DeleteCategoryMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DeleteCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteCategory: (
    { __typename?: 'ReturnMessageType' }
    & Pick<ReturnMessageType, 'message'>
  ) }
);

export type AddCustomerMutationVariables = Exact<{
  data: AddCustomerInput;
}>;


export type AddCustomerMutation = (
  { __typename?: 'Mutation' }
  & { addCustomer: (
    { __typename?: 'CustomerType' }
    & Pick<CustomerType, 'id' | 'name' | 'email' | 'phone_number' | 'birthday'>
  ) }
);

export type DeleteCustomerMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DeleteCustomerMutation = (
  { __typename?: 'Mutation' }
  & { deleteCustomer: (
    { __typename?: 'ReturnMessageType' }
    & Pick<ReturnMessageType, 'message'>
  ) }
);

export type AddNotificationMMutationVariables = Exact<{
  data: AddNotificationInput;
}>;


export type AddNotificationMMutation = (
  { __typename?: 'Mutation' }
  & { addNotification: (
    { __typename?: 'NotificationType' }
    & Pick<NotificationType, 'title'>
  ) }
);

export type UpdateServiceMutationVariables = Exact<{
  data: UpdateServiceInput;
  id: Scalars['Float'];
}>;


export type UpdateServiceMutation = (
  { __typename?: 'Mutation' }
  & { updateService: (
    { __typename?: 'ServiceType' }
    & Pick<ServiceType, 'id' | 'name' | 'cost' | 'time'>
  ) }
);

export type AddStaffMutationVariables = Exact<{
  data: AddStaffInput;
}>;


export type AddStaffMutation = (
  { __typename?: 'Mutation' }
  & { addStaffMember: (
    { __typename?: 'StaffMemberType' }
    & Pick<StaffMemberType, 'id' | 'full_name' | 'email'>
  ) }
);

export type DeleteStaffMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DeleteStaffMutation = (
  { __typename?: 'Mutation' }
  & { deleteStaffMember: (
    { __typename?: 'DeleteStaffMemberType' }
    & Pick<DeleteStaffMemberType, 'message'>
  ) }
);

export type AssignServicesMutationVariables = Exact<{
  data: UpdateStaffInput;
  id: Scalars['Float'];
}>;


export type AssignServicesMutation = (
  { __typename?: 'Mutation' }
  & { updateStaffMember: (
    { __typename?: 'StaffMemberType' }
    & Pick<StaffMemberType, 'phone_number'>
    & { services?: Maybe<Array<(
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'name'>
    )>> }
  ) }
);

export type AddTimeOffMutationVariables = Exact<{
  data: AddUserTimeOffInput;
}>;


export type AddTimeOffMutation = (
  { __typename?: 'Mutation' }
  & { addUserTimeOff: (
    { __typename?: 'UserTimeOffType' }
    & Pick<UserTimeOffType, 'id' | 'start_date' | 'end_date' | 'description' | 'user_id'>
  ) }
);

export type DeleteTimeOffMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DeleteTimeOffMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserTimeOff: (
    { __typename?: 'ReturnMessageType' }
    & Pick<ReturnMessageType, 'message'>
  ) }
);

export type UpdateTimeOffMutationVariables = Exact<{
  data: UpdateUserTimeOffInput;
  id: Scalars['Float'];
}>;


export type UpdateTimeOffMutation = (
  { __typename?: 'Mutation' }
  & { updateUserTimeOff: (
    { __typename?: 'UserTimeOffType' }
    & Pick<UserTimeOffType, 'id' | 'start_date' | 'end_date' | 'description' | 'user_id'>
  ) }
);

export type AddBreakMutationVariables = Exact<{
  data: AddUserBreaksInput;
}>;


export type AddBreakMutation = (
  { __typename?: 'Mutation' }
  & { addUserBreak: (
    { __typename?: 'UserBreaksType' }
    & Pick<UserBreaksType, 'id' | 'day' | 'start_time' | 'end_time' | 'user_id'>
  ) }
);

export type DeleteBreakMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DeleteBreakMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserBreak: (
    { __typename?: 'ReturnMessageType' }
    & Pick<ReturnMessageType, 'message'>
  ) }
);

export type UpdateBreakMutationVariables = Exact<{
  data: UpdateUserBreaksInput;
  id: Scalars['Float'];
}>;


export type UpdateBreakMutation = (
  { __typename?: 'Mutation' }
  & { updateUserBreak: (
    { __typename?: 'UserBreaksType' }
    & Pick<UserBreaksType, 'id' | 'day' | 'start_time' | 'end_time'>
  ) }
);

export type AddWorkingHoursMutationVariables = Exact<{
  data: AddUserWorkingHoursInput;
}>;


export type AddWorkingHoursMutation = (
  { __typename?: 'Mutation' }
  & { addUserWorkingHours: Array<(
    { __typename?: 'UserWorkingHoursType' }
    & Pick<UserWorkingHoursType, 'id' | 'day' | 'start_time' | 'end_time' | 'status'>
  )> }
);

export type LoginMutationVariables = Exact<{
  data: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'full_name' | 'email' | 'role_id' | 'role_name' | 'expires_in' | 'access_token'>
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'ReturnMessageType' }
    & Pick<ReturnMessageType, 'message'>
  ) }
);

export type ForgotPasswordMutationVariables = Exact<{
  data: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & { forgotPassword: (
    { __typename?: 'ReturnMessageType' }
    & Pick<ReturnMessageType, 'message'>
  ) }
);

export type UpdateProfileMutationVariables = Exact<{
  data: UpdateProfileInput;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile: (
    { __typename?: 'StaffMemberType' }
    & Pick<StaffMemberType, 'id' | 'full_name' | 'email' | 'phone_number' | 'description'>
  ) }
);

export type AppointmentsByCustomerQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AppointmentsByCustomerQuery = (
  { __typename?: 'Query' }
  & { appointments: (
    { __typename?: 'AppointmentsType' }
    & Pick<AppointmentsType, 'total_data'>
    & { rows?: Maybe<Array<(
      { __typename?: 'AppointmentType' }
      & Pick<AppointmentType, 'id' | 'date' | 'start_time' | 'end_time' | 'cost' | 'status' | 'provider_id' | 'service_id' | 'customer_id'>
      & { customer?: Maybe<(
        { __typename?: 'CustomerType' }
        & Pick<CustomerType, 'id' | 'name' | 'email' | 'phone_number'>
      )>, provider?: Maybe<(
        { __typename?: 'ProviderType' }
        & Pick<ProviderType, 'id' | 'full_name' | 'email'>
      )>, service?: Maybe<(
        { __typename?: 'ServiceType' }
        & Pick<ServiceType, 'id' | 'name' | 'description' | 'cost' | 'time' | 'buffer_time' | 'is_private'>
      )> }
    )>> }
  ) }
);

export type AppointmentsByArtistsQueryVariables = Exact<{
  options?: Maybe<GetAppointmentsInput>;
}>;


export type AppointmentsByArtistsQuery = (
  { __typename?: 'Query' }
  & { appointmentsByArtists: (
    { __typename?: 'ArtistsAppointments' }
    & Pick<ArtistsAppointments, 'total_data'>
    & { rows?: Maybe<Array<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'full_name' | 'id' | 'email' | 'role_id'>
      & { appointments?: Maybe<Array<(
        { __typename?: 'AppointmentType' }
        & AppointmentFieldsFragment
      )>> }
    )>> }
  ) }
);

export type AppointmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type AppointmentsQuery = (
  { __typename?: 'Query' }
  & { appointments: (
    { __typename?: 'AppointmentsType' }
    & Pick<AppointmentsType, 'total_data'>
    & { rows?: Maybe<Array<(
      { __typename?: 'AppointmentType' }
      & Pick<AppointmentType, 'id' | 'date' | 'start_time' | 'end_time' | 'cost' | 'status' | 'provider_id' | 'service_id' | 'customer_id'>
      & { customer?: Maybe<(
        { __typename?: 'CustomerType' }
        & Pick<CustomerType, 'id' | 'name' | 'email' | 'phone_number'>
      )>, provider?: Maybe<(
        { __typename?: 'ProviderType' }
        & Pick<ProviderType, 'id' | 'full_name' | 'email'>
      )>, service?: Maybe<(
        { __typename?: 'ServiceType' }
        & Pick<ServiceType, 'id' | 'name' | 'description' | 'cost' | 'time' | 'buffer_time' | 'is_private'>
      )> }
    )>> }
  ) }
);

export type AppointmentQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type AppointmentQuery = (
  { __typename?: 'Query' }
  & { appointment: (
    { __typename?: 'AppointmentType' }
    & Pick<AppointmentType, 'id' | 'date' | 'start_time' | 'end_time' | 'cost' | 'status'>
    & { customer?: Maybe<(
      { __typename?: 'CustomerType' }
      & Pick<CustomerType, 'name'>
    )>, provider?: Maybe<(
      { __typename?: 'ProviderType' }
      & Pick<ProviderType, 'full_name'>
    )>, service?: Maybe<(
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'name'>
    )> }
  ) }
);

export type BreaksForCalendarQueryVariables = Exact<{ [key: string]: never; }>;


export type BreaksForCalendarQuery = (
  { __typename?: 'Query' }
  & { breaksForCalendar: Array<(
    { __typename?: 'UserBreaksType' }
    & Pick<UserBreaksType, 'id' | 'day' | 'start_time' | 'end_time' | 'user_id'>
  )> }
);

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = (
  { __typename?: 'Query' }
  & { categories: Array<(
    { __typename?: 'CategoryType' }
    & Pick<CategoryType, 'id' | 'name'>
  )> }
);

export type CategoryQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type CategoryQuery = (
  { __typename?: 'Query' }
  & { category: (
    { __typename?: 'CategoryType' }
    & Pick<CategoryType, 'id' | 'name'>
    & { services?: Maybe<Array<(
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'cost' | 'name' | 'time' | 'color'>
    )>> }
  ) }
);

export type CustomersQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomersQuery = (
  { __typename?: 'Query' }
  & { customers: (
    { __typename?: 'CustomersType' }
    & Pick<CustomersType, 'total_data'>
    & { rows?: Maybe<Array<(
      { __typename?: 'CustomerType' }
      & Pick<CustomerType, 'id' | 'name' | 'email' | 'phone_number'>
    )>> }
  ) }
);

export type CustomerInformationQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type CustomerInformationQuery = (
  { __typename?: 'Query' }
  & { customer: (
    { __typename?: 'CustomerType' }
    & Pick<CustomerType, 'id' | 'name' | 'email' | 'phone_number'>
  ) }
);

export type GetReportQueryVariables = Exact<{
  filters: ReportFilterInput;
}>;


export type GetReportQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'ReportType' }
    & Pick<ReportType, 'start_date' | 'end_date' | 'appointments' | 'total'>
  ) }
);

export type AllServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllServicesQuery = (
  { __typename?: 'Query' }
  & { services: (
    { __typename?: 'ServicesType' }
    & Pick<ServicesType, 'total_data'>
    & { rows?: Maybe<Array<(
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'name' | 'description' | 'cost' | 'time' | 'color' | 'buffer_time'>
      & { categories?: Maybe<Array<(
        { __typename?: 'CategoryType' }
        & Pick<CategoryType, 'id' | 'name'>
      )>>, providers?: Maybe<Array<(
        { __typename?: 'ProviderType' }
        & Pick<ProviderType, 'id' | 'full_name' | 'email'>
      )>> }
    )>> }
  ) }
);

export type ServiceQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type ServiceQuery = (
  { __typename?: 'Query' }
  & { service: (
    { __typename?: 'ServiceType' }
    & Pick<ServiceType, 'id' | 'name' | 'buffer_time' | 'cost' | 'description' | 'is_private' | 'color' | 'time'>
    & { categories?: Maybe<Array<(
      { __typename?: 'CategoryType' }
      & Pick<CategoryType, 'id' | 'name'>
    )>>, providers?: Maybe<Array<(
      { __typename?: 'ProviderType' }
      & Pick<ProviderType, 'id' | 'full_name'>
    )>> }
  ) }
);

export type StaffQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type StaffQuery = (
  { __typename?: 'Query' }
  & { staffMember: (
    { __typename?: 'StaffMemberType' }
    & Pick<StaffMemberType, 'id' | 'full_name' | 'email' | 'phone_number' | 'description'>
    & { services?: Maybe<Array<(
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'name'>
    )>> }
  ) }
);

export type ServicesByStaffQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ServicesByStaffQuery = (
  { __typename?: 'Query' }
  & { services: (
    { __typename?: 'ServicesType' }
    & Pick<ServicesType, 'total_data'>
    & { rows?: Maybe<Array<(
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'name' | 'cost' | 'time' | 'color'>
    )>> }
  ) }
);

export type UserTimeOffQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type UserTimeOffQuery = (
  { __typename?: 'Query' }
  & { userTimeOff: Array<(
    { __typename?: 'UserTimeOffType' }
    & Pick<UserTimeOffType, 'id' | 'start_date' | 'end_date' | 'description' | 'user_id'>
  )> }
);

export type WorkingHoursQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type WorkingHoursQuery = (
  { __typename?: 'Query' }
  & { userWorkingHours: Array<(
    { __typename?: 'UserWorkingHoursType' }
    & Pick<UserWorkingHoursType, 'id' | 'day' | 'start_time' | 'end_time' | 'status' | 'user_id'>
  )> }
);

export type TimeOffByIdQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type TimeOffByIdQuery = (
  { __typename?: 'Query' }
  & { userTimeOffById: (
    { __typename?: 'UserTimeOffType' }
    & Pick<UserTimeOffType, 'id' | 'start_date' | 'end_date' | 'description' | 'user_id'>
  ) }
);

export type UserBreaksQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type UserBreaksQuery = (
  { __typename?: 'Query' }
  & { userBreaks: Array<(
    { __typename?: 'GetUserBreaksType' }
    & Pick<GetUserBreaksType, 'day' | 'user_id'>
    & { breaks?: Maybe<Array<(
      { __typename?: 'Break' }
      & Pick<Break, 'id' | 'start_time' | 'end_time'>
    )>> }
  )> }
);

export type StaffMembersQueryVariables = Exact<{
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
}>;


export type StaffMembersQuery = (
  { __typename?: 'Query' }
  & { staffMembers: (
    { __typename?: 'StaffMembersType' }
    & Pick<StaffMembersType, 'total_data'>
    & { rows: Array<(
      { __typename?: 'StaffMemberType' }
      & Pick<StaffMemberType, 'id' | 'full_name' | 'email'>
    )> }
  ) }
);

export type LiveAppointmentsSubscriptionVariables = Exact<{
  priorities: Array<Scalars['String']> | Scalars['String'];
}>;


export type LiveAppointmentsSubscription = (
  { __typename?: 'Subscription' }
  & { appointmentSubscription: (
    { __typename?: 'AppointmentPayload' }
    & Pick<AppointmentPayload, 'type' | 'deleted_id'>
    & { appointment?: Maybe<(
      { __typename?: 'AppointmentType' }
      & Pick<AppointmentType, 'id' | 'date' | 'start_time' | 'end_time' | 'cost' | 'status' | 'provider_id' | 'service_id' | 'customer_id'>
      & { service?: Maybe<(
        { __typename?: 'ServiceType' }
        & Pick<ServiceType, 'id' | 'name' | 'description' | 'cost' | 'time' | 'color'>
      )>, provider?: Maybe<(
        { __typename?: 'ProviderType' }
        & Pick<ProviderType, 'id' | 'full_name' | 'email'>
      )>, customer?: Maybe<(
        { __typename?: 'CustomerType' }
        & Pick<CustomerType, 'id' | 'name' | 'email' | 'phone_number'>
      )> }
    )> }
  ) }
);

export type TimeOffsForCalendarQueryVariables = Exact<{
  data: StartEndDateInput;
}>;


export type TimeOffsForCalendarQuery = (
  { __typename?: 'Query' }
  & { userTimeOffForCalendar: Array<(
    { __typename?: 'UserTimeOffType' }
    & Pick<UserTimeOffType, 'start_date' | 'end_date' | 'description' | 'user_id' | 'id'>
  )> }
);

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'full_name'>
  )> }
);

export type LoggedInUserQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInUserQuery = (
  { __typename?: 'Query' }
  & { getLoggedInUser: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'full_name' | 'email' | 'role_id' | 'role_name'>
  ) }
);

export type UserWorkingHoursForCalendarQueryVariables = Exact<{ [key: string]: never; }>;


export type UserWorkingHoursForCalendarQuery = (
  { __typename?: 'Query' }
  & { userWorkingHoursForCalendar: Array<(
    { __typename?: 'UserWorkingHoursType' }
    & Pick<UserWorkingHoursType, 'id' | 'day' | 'start_time' | 'end_time' | 'status' | 'user_id'>
  )> }
);

export const AppointmentFieldsFragmentDoc = gql`
    fragment AppointmentFields on AppointmentType {
  id
  date
  start_time
  end_time
  cost
  status
  service {
    id
    name
    cost
    color
    time
  }
  customer {
    id
    name
  }
  provider {
    email
    id
    full_name
  }
}
    `;
export const AddAppointmentDocument = gql`
    mutation addAppointment($data: AddAppointmentInput!) {
  addAppointment(data: $data) {
    id
    date
    start_time
    end_time
    cost
    status
    service {
      id
      name
      cost
      time
    }
    provider {
      id
      full_name
      email
    }
    customer {
      id
      name
      email
    }
  }
}
    `;

/**
 * __useAddAppointmentMutation__
 *
 * To run a mutation, you first call `useAddAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAppointmentMutation, { data, loading, error }] = useAddAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<AddAppointmentMutation, AddAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAppointmentMutation, AddAppointmentMutationVariables>(AddAppointmentDocument, options);
      }
export type AddAppointmentMutationHookResult = ReturnType<typeof useAddAppointmentMutation>;
export type AddAppointmentMutationResult = Apollo.MutationResult<AddAppointmentMutation>;
export type AddAppointmentMutationOptions = Apollo.BaseMutationOptions<AddAppointmentMutation, AddAppointmentMutationVariables>;
export const DeleteAppointmentDocument = gql`
    mutation deleteAppointment($id: Float!) {
  deleteAppointment(id: $id) {
    message
  }
}
    `;

/**
 * __useDeleteAppointmentMutation__
 *
 * To run a mutation, you first call `useDeleteAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppointmentMutation, { data, loading, error }] = useDeleteAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>(DeleteAppointmentDocument, options);
      }
export type DeleteAppointmentMutationHookResult = ReturnType<typeof useDeleteAppointmentMutation>;
export type DeleteAppointmentMutationResult = Apollo.MutationResult<DeleteAppointmentMutation>;
export type DeleteAppointmentMutationOptions = Apollo.BaseMutationOptions<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>;
export const UpdateAppointmentDocument = gql`
    mutation updateAppointment($data: UpdateAppointmentInput, $id: Float!) {
  updateAppointment(data: $data, id: $id) {
    id
    date
    start_time
    end_time
    cost
    status
    service {
      id
      name
      cost
      time
    }
    provider {
      id
      full_name
      email
    }
    customer {
      id
      name
      email
    }
  }
}
    `;

/**
 * __useUpdateAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>(UpdateAppointmentDocument, options);
      }
export type UpdateAppointmentMutationHookResult = ReturnType<typeof useUpdateAppointmentMutation>;
export type UpdateAppointmentMutationResult = Apollo.MutationResult<UpdateAppointmentMutation>;
export type UpdateAppointmentMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;
export const AddCategoryDocument = gql`
    mutation addCategory($data: AddCategoryInput!) {
  addCategory(data: $data) {
    id
    name
  }
}
    `;

/**
 * __useAddCategoryMutation__
 *
 * To run a mutation, you first call `useAddCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCategoryMutation, { data, loading, error }] = useAddCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddCategoryMutation, AddCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCategoryMutation, AddCategoryMutationVariables>(AddCategoryDocument, options);
      }
export type AddCategoryMutationHookResult = ReturnType<typeof useAddCategoryMutation>;
export type AddCategoryMutationResult = Apollo.MutationResult<AddCategoryMutation>;
export type AddCategoryMutationOptions = Apollo.BaseMutationOptions<AddCategoryMutation, AddCategoryMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation deleteCategory($id: Float!) {
  deleteCategory(id: $id) {
    message
  }
}
    `;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const AddCustomerDocument = gql`
    mutation addCustomer($data: AddCustomerInput!) {
  addCustomer(data: $data) {
    id
    name
    email
    phone_number
    birthday
  }
}
    `;

/**
 * __useAddCustomerMutation__
 *
 * To run a mutation, you first call `useAddCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomerMutation, { data, loading, error }] = useAddCustomerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddCustomerMutation(baseOptions?: Apollo.MutationHookOptions<AddCustomerMutation, AddCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCustomerMutation, AddCustomerMutationVariables>(AddCustomerDocument, options);
      }
export type AddCustomerMutationHookResult = ReturnType<typeof useAddCustomerMutation>;
export type AddCustomerMutationResult = Apollo.MutationResult<AddCustomerMutation>;
export type AddCustomerMutationOptions = Apollo.BaseMutationOptions<AddCustomerMutation, AddCustomerMutationVariables>;
export const DeleteCustomerDocument = gql`
    mutation deleteCustomer($id: Float!) {
  deleteCustomer(id: $id) {
    message
  }
}
    `;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(DeleteCustomerDocument, options);
      }
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>;
export type DeleteCustomerMutationResult = Apollo.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>;
export const AddNotificationMDocument = gql`
    mutation addNotificationM($data: AddNotificationInput!) {
  addNotification(data: $data) {
    title
  }
}
    `;

/**
 * __useAddNotificationMMutation__
 *
 * To run a mutation, you first call `useAddNotificationMMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNotificationMMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNotificationMMutation, { data, loading, error }] = useAddNotificationMMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddNotificationMMutation(baseOptions?: Apollo.MutationHookOptions<AddNotificationMMutation, AddNotificationMMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNotificationMMutation, AddNotificationMMutationVariables>(AddNotificationMDocument, options);
      }
export type AddNotificationMMutationHookResult = ReturnType<typeof useAddNotificationMMutation>;
export type AddNotificationMMutationResult = Apollo.MutationResult<AddNotificationMMutation>;
export type AddNotificationMMutationOptions = Apollo.BaseMutationOptions<AddNotificationMMutation, AddNotificationMMutationVariables>;
export const UpdateServiceDocument = gql`
    mutation updateService($data: UpdateServiceInput!, $id: Float!) {
  updateService(data: $data, id: $id) {
    id
    name
    cost
    time
  }
}
    `;

/**
 * __useUpdateServiceMutation__
 *
 * To run a mutation, you first call `useUpdateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceMutation, { data, loading, error }] = useUpdateServiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceMutation, UpdateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceMutation, UpdateServiceMutationVariables>(UpdateServiceDocument, options);
      }
export type UpdateServiceMutationHookResult = ReturnType<typeof useUpdateServiceMutation>;
export type UpdateServiceMutationResult = Apollo.MutationResult<UpdateServiceMutation>;
export type UpdateServiceMutationOptions = Apollo.BaseMutationOptions<UpdateServiceMutation, UpdateServiceMutationVariables>;
export const AddStaffDocument = gql`
    mutation addStaff($data: AddStaffInput!) {
  addStaffMember(data: $data) {
    id
    full_name
    email
  }
}
    `;

/**
 * __useAddStaffMutation__
 *
 * To run a mutation, you first call `useAddStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStaffMutation, { data, loading, error }] = useAddStaffMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddStaffMutation(baseOptions?: Apollo.MutationHookOptions<AddStaffMutation, AddStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStaffMutation, AddStaffMutationVariables>(AddStaffDocument, options);
      }
export type AddStaffMutationHookResult = ReturnType<typeof useAddStaffMutation>;
export type AddStaffMutationResult = Apollo.MutationResult<AddStaffMutation>;
export type AddStaffMutationOptions = Apollo.BaseMutationOptions<AddStaffMutation, AddStaffMutationVariables>;
export const DeleteStaffDocument = gql`
    mutation deleteStaff($id: Float!) {
  deleteStaffMember(id: $id) {
    message
  }
}
    `;

/**
 * __useDeleteStaffMutation__
 *
 * To run a mutation, you first call `useDeleteStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStaffMutation, { data, loading, error }] = useDeleteStaffMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStaffMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStaffMutation, DeleteStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStaffMutation, DeleteStaffMutationVariables>(DeleteStaffDocument, options);
      }
export type DeleteStaffMutationHookResult = ReturnType<typeof useDeleteStaffMutation>;
export type DeleteStaffMutationResult = Apollo.MutationResult<DeleteStaffMutation>;
export type DeleteStaffMutationOptions = Apollo.BaseMutationOptions<DeleteStaffMutation, DeleteStaffMutationVariables>;
export const AssignServicesDocument = gql`
    mutation assignServices($data: UpdateStaffInput!, $id: Float!) {
  updateStaffMember(id: $id, data: $data) {
    phone_number
    services {
      name
    }
  }
}
    `;

/**
 * __useAssignServicesMutation__
 *
 * To run a mutation, you first call `useAssignServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignServicesMutation, { data, loading, error }] = useAssignServicesMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssignServicesMutation(baseOptions?: Apollo.MutationHookOptions<AssignServicesMutation, AssignServicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignServicesMutation, AssignServicesMutationVariables>(AssignServicesDocument, options);
      }
export type AssignServicesMutationHookResult = ReturnType<typeof useAssignServicesMutation>;
export type AssignServicesMutationResult = Apollo.MutationResult<AssignServicesMutation>;
export type AssignServicesMutationOptions = Apollo.BaseMutationOptions<AssignServicesMutation, AssignServicesMutationVariables>;
export const AddTimeOffDocument = gql`
    mutation addTimeOff($data: AddUserTimeOffInput!) {
  addUserTimeOff(data: $data) {
    id
    start_date
    end_date
    description
    user_id
  }
}
    `;

/**
 * __useAddTimeOffMutation__
 *
 * To run a mutation, you first call `useAddTimeOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTimeOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTimeOffMutation, { data, loading, error }] = useAddTimeOffMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddTimeOffMutation(baseOptions?: Apollo.MutationHookOptions<AddTimeOffMutation, AddTimeOffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTimeOffMutation, AddTimeOffMutationVariables>(AddTimeOffDocument, options);
      }
export type AddTimeOffMutationHookResult = ReturnType<typeof useAddTimeOffMutation>;
export type AddTimeOffMutationResult = Apollo.MutationResult<AddTimeOffMutation>;
export type AddTimeOffMutationOptions = Apollo.BaseMutationOptions<AddTimeOffMutation, AddTimeOffMutationVariables>;
export const DeleteTimeOffDocument = gql`
    mutation deleteTimeOff($id: Float!) {
  deleteUserTimeOff(id: $id) {
    message
  }
}
    `;

/**
 * __useDeleteTimeOffMutation__
 *
 * To run a mutation, you first call `useDeleteTimeOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeOffMutation, { data, loading, error }] = useDeleteTimeOffMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTimeOffMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeOffMutation, DeleteTimeOffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimeOffMutation, DeleteTimeOffMutationVariables>(DeleteTimeOffDocument, options);
      }
export type DeleteTimeOffMutationHookResult = ReturnType<typeof useDeleteTimeOffMutation>;
export type DeleteTimeOffMutationResult = Apollo.MutationResult<DeleteTimeOffMutation>;
export type DeleteTimeOffMutationOptions = Apollo.BaseMutationOptions<DeleteTimeOffMutation, DeleteTimeOffMutationVariables>;
export const UpdateTimeOffDocument = gql`
    mutation updateTimeOff($data: UpdateUserTimeOffInput!, $id: Float!) {
  updateUserTimeOff(data: $data, id: $id) {
    id
    start_date
    end_date
    description
    user_id
  }
}
    `;

/**
 * __useUpdateTimeOffMutation__
 *
 * To run a mutation, you first call `useUpdateTimeOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeOffMutation, { data, loading, error }] = useUpdateTimeOffMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateTimeOffMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimeOffMutation, UpdateTimeOffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimeOffMutation, UpdateTimeOffMutationVariables>(UpdateTimeOffDocument, options);
      }
export type UpdateTimeOffMutationHookResult = ReturnType<typeof useUpdateTimeOffMutation>;
export type UpdateTimeOffMutationResult = Apollo.MutationResult<UpdateTimeOffMutation>;
export type UpdateTimeOffMutationOptions = Apollo.BaseMutationOptions<UpdateTimeOffMutation, UpdateTimeOffMutationVariables>;
export const AddBreakDocument = gql`
    mutation addBreak($data: AddUserBreaksInput!) {
  addUserBreak(data: $data) {
    id
    day
    start_time
    end_time
    user_id
  }
}
    `;

/**
 * __useAddBreakMutation__
 *
 * To run a mutation, you first call `useAddBreakMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBreakMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBreakMutation, { data, loading, error }] = useAddBreakMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddBreakMutation(baseOptions?: Apollo.MutationHookOptions<AddBreakMutation, AddBreakMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBreakMutation, AddBreakMutationVariables>(AddBreakDocument, options);
      }
export type AddBreakMutationHookResult = ReturnType<typeof useAddBreakMutation>;
export type AddBreakMutationResult = Apollo.MutationResult<AddBreakMutation>;
export type AddBreakMutationOptions = Apollo.BaseMutationOptions<AddBreakMutation, AddBreakMutationVariables>;
export const DeleteBreakDocument = gql`
    mutation deleteBreak($id: Float!) {
  deleteUserBreak(id: $id) {
    message
  }
}
    `;

/**
 * __useDeleteBreakMutation__
 *
 * To run a mutation, you first call `useDeleteBreakMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBreakMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBreakMutation, { data, loading, error }] = useDeleteBreakMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBreakMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBreakMutation, DeleteBreakMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBreakMutation, DeleteBreakMutationVariables>(DeleteBreakDocument, options);
      }
export type DeleteBreakMutationHookResult = ReturnType<typeof useDeleteBreakMutation>;
export type DeleteBreakMutationResult = Apollo.MutationResult<DeleteBreakMutation>;
export type DeleteBreakMutationOptions = Apollo.BaseMutationOptions<DeleteBreakMutation, DeleteBreakMutationVariables>;
export const UpdateBreakDocument = gql`
    mutation updateBreak($data: UpdateUserBreaksInput!, $id: Float!) {
  updateUserBreak(data: $data, id: $id) {
    id
    day
    start_time
    end_time
  }
}
    `;

/**
 * __useUpdateBreakMutation__
 *
 * To run a mutation, you first call `useUpdateBreakMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBreakMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBreakMutation, { data, loading, error }] = useUpdateBreakMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateBreakMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBreakMutation, UpdateBreakMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBreakMutation, UpdateBreakMutationVariables>(UpdateBreakDocument, options);
      }
export type UpdateBreakMutationHookResult = ReturnType<typeof useUpdateBreakMutation>;
export type UpdateBreakMutationResult = Apollo.MutationResult<UpdateBreakMutation>;
export type UpdateBreakMutationOptions = Apollo.BaseMutationOptions<UpdateBreakMutation, UpdateBreakMutationVariables>;
export const AddWorkingHoursDocument = gql`
    mutation addWorkingHours($data: AddUserWorkingHoursInput!) {
  addUserWorkingHours(data: $data) {
    id
    day
    start_time
    end_time
    status
  }
}
    `;

/**
 * __useAddWorkingHoursMutation__
 *
 * To run a mutation, you first call `useAddWorkingHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkingHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkingHoursMutation, { data, loading, error }] = useAddWorkingHoursMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddWorkingHoursMutation(baseOptions?: Apollo.MutationHookOptions<AddWorkingHoursMutation, AddWorkingHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWorkingHoursMutation, AddWorkingHoursMutationVariables>(AddWorkingHoursDocument, options);
      }
export type AddWorkingHoursMutationHookResult = ReturnType<typeof useAddWorkingHoursMutation>;
export type AddWorkingHoursMutationResult = Apollo.MutationResult<AddWorkingHoursMutation>;
export type AddWorkingHoursMutationOptions = Apollo.BaseMutationOptions<AddWorkingHoursMutation, AddWorkingHoursMutationVariables>;
export const LoginDocument = gql`
    mutation login($data: LoginInput!) {
  login(data: $data) {
    id
    full_name
    email
    role_id
    role_name
    expires_in
    access_token
  }
}
    `;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($data: ResetPasswordInput!) {
  resetPassword(data: $data) {
    message
  }
}
    `;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($data: ForgotPasswordInput!) {
  forgotPassword(data: $data) {
    message
  }
}
    `;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($data: UpdateProfileInput!) {
  updateProfile(data: $data) {
    id
    full_name
    email
    phone_number
    description
  }
}
    `;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const AppointmentsByCustomerDocument = gql`
    query appointmentsByCustomer($id: String!) {
  appointments(
    parseResourceOptions: {filter: {key: "customer_id", operator: "eq", value: $id}, relations: "customer,provider,service"}
  ) {
    total_data
    rows {
      id
      date
      start_time
      end_time
      cost
      status
      provider_id
      service_id
      customer_id
      customer {
        id
        name
        email
        phone_number
      }
      provider {
        id
        full_name
        email
      }
      service {
        id
        name
        description
        cost
        time
        buffer_time
        is_private
      }
    }
  }
}
    `;

/**
 * __useAppointmentsByCustomerQuery__
 *
 * To run a query within a React component, call `useAppointmentsByCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsByCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsByCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppointmentsByCustomerQuery(baseOptions: Apollo.QueryHookOptions<AppointmentsByCustomerQuery, AppointmentsByCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentsByCustomerQuery, AppointmentsByCustomerQueryVariables>(AppointmentsByCustomerDocument, options);
      }
export function useAppointmentsByCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsByCustomerQuery, AppointmentsByCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentsByCustomerQuery, AppointmentsByCustomerQueryVariables>(AppointmentsByCustomerDocument, options);
        }
export type AppointmentsByCustomerQueryHookResult = ReturnType<typeof useAppointmentsByCustomerQuery>;
export type AppointmentsByCustomerLazyQueryHookResult = ReturnType<typeof useAppointmentsByCustomerLazyQuery>;
export type AppointmentsByCustomerQueryResult = Apollo.QueryResult<AppointmentsByCustomerQuery, AppointmentsByCustomerQueryVariables>;
export const AppointmentsByArtistsDocument = gql`
    query appointmentsByArtists($options: GetAppointmentsInput) {
  appointmentsByArtists(options: $options) {
    total_data
    rows {
      full_name
      id
      email
      role_id
      appointments {
        ...AppointmentFields
      }
    }
  }
}
    ${AppointmentFieldsFragmentDoc}`;

/**
 * __useAppointmentsByArtistsQuery__
 *
 * To run a query within a React component, call `useAppointmentsByArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsByArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsByArtistsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAppointmentsByArtistsQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentsByArtistsQuery, AppointmentsByArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentsByArtistsQuery, AppointmentsByArtistsQueryVariables>(AppointmentsByArtistsDocument, options);
      }
export function useAppointmentsByArtistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsByArtistsQuery, AppointmentsByArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentsByArtistsQuery, AppointmentsByArtistsQueryVariables>(AppointmentsByArtistsDocument, options);
        }
export type AppointmentsByArtistsQueryHookResult = ReturnType<typeof useAppointmentsByArtistsQuery>;
export type AppointmentsByArtistsLazyQueryHookResult = ReturnType<typeof useAppointmentsByArtistsLazyQuery>;
export type AppointmentsByArtistsQueryResult = Apollo.QueryResult<AppointmentsByArtistsQuery, AppointmentsByArtistsQueryVariables>;
export const AppointmentsDocument = gql`
    query appointments {
  appointments(parseResourceOptions: {relations: "customer,provider,service"}) {
    total_data
    rows {
      id
      date
      start_time
      end_time
      cost
      status
      provider_id
      service_id
      customer_id
      customer {
        id
        name
        email
        phone_number
      }
      provider {
        id
        full_name
        email
      }
      service {
        id
        name
        description
        cost
        time
        buffer_time
        is_private
      }
    }
  }
}
    `;

/**
 * __useAppointmentsQuery__
 *
 * To run a query within a React component, call `useAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, options);
      }
export function useAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, options);
        }
export type AppointmentsQueryHookResult = ReturnType<typeof useAppointmentsQuery>;
export type AppointmentsLazyQueryHookResult = ReturnType<typeof useAppointmentsLazyQuery>;
export type AppointmentsQueryResult = Apollo.QueryResult<AppointmentsQuery, AppointmentsQueryVariables>;
export const AppointmentDocument = gql`
    query appointment($id: Float!) {
  appointment(
    id: $id
    parseResourceOptions: {relations: "provider,service,customer"}
  ) {
    id
    date
    start_time
    end_time
    cost
    status
    customer {
      name
    }
    provider {
      full_name
    }
    service {
      name
    }
  }
}
    `;

/**
 * __useAppointmentQuery__
 *
 * To run a query within a React component, call `useAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppointmentQuery(baseOptions: Apollo.QueryHookOptions<AppointmentQuery, AppointmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentQuery, AppointmentQueryVariables>(AppointmentDocument, options);
      }
export function useAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentQuery, AppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentQuery, AppointmentQueryVariables>(AppointmentDocument, options);
        }
export type AppointmentQueryHookResult = ReturnType<typeof useAppointmentQuery>;
export type AppointmentLazyQueryHookResult = ReturnType<typeof useAppointmentLazyQuery>;
export type AppointmentQueryResult = Apollo.QueryResult<AppointmentQuery, AppointmentQueryVariables>;
export const BreaksForCalendarDocument = gql`
    query breaksForCalendar {
  breaksForCalendar {
    id
    day
    start_time
    end_time
    user_id
  }
}
    `;

/**
 * __useBreaksForCalendarQuery__
 *
 * To run a query within a React component, call `useBreaksForCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useBreaksForCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBreaksForCalendarQuery({
 *   variables: {
 *   },
 * });
 */
export function useBreaksForCalendarQuery(baseOptions?: Apollo.QueryHookOptions<BreaksForCalendarQuery, BreaksForCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BreaksForCalendarQuery, BreaksForCalendarQueryVariables>(BreaksForCalendarDocument, options);
      }
export function useBreaksForCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BreaksForCalendarQuery, BreaksForCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BreaksForCalendarQuery, BreaksForCalendarQueryVariables>(BreaksForCalendarDocument, options);
        }
export type BreaksForCalendarQueryHookResult = ReturnType<typeof useBreaksForCalendarQuery>;
export type BreaksForCalendarLazyQueryHookResult = ReturnType<typeof useBreaksForCalendarLazyQuery>;
export type BreaksForCalendarQueryResult = Apollo.QueryResult<BreaksForCalendarQuery, BreaksForCalendarQueryVariables>;
export const CategoriesDocument = gql`
    query categories {
  categories {
    id
    name
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const CategoryDocument = gql`
    query category($id: Float!) {
  category(id: $id, parseResourceOptions: {relations: "services"}) {
    id
    name
    services {
      id
      cost
      name
      time
      color
    }
  }
}
    `;

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryQuery(baseOptions: Apollo.QueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
      }
export function useCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
        }
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategoryQueryResult = Apollo.QueryResult<CategoryQuery, CategoryQueryVariables>;
export const CustomersDocument = gql`
    query customers {
  customers {
    total_data
    rows {
      id
      name
      email
      phone_number
    }
  }
}
    `;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomersQuery(baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
      }
export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
        }
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;
export const CustomerInformationDocument = gql`
    query CustomerInformation($id: Float!) {
  customer(id: $id) {
    id
    name
    email
    phone_number
  }
}
    `;

/**
 * __useCustomerInformationQuery__
 *
 * To run a query within a React component, call `useCustomerInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerInformationQuery(baseOptions: Apollo.QueryHookOptions<CustomerInformationQuery, CustomerInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerInformationQuery, CustomerInformationQueryVariables>(CustomerInformationDocument, options);
      }
export function useCustomerInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerInformationQuery, CustomerInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerInformationQuery, CustomerInformationQueryVariables>(CustomerInformationDocument, options);
        }
export type CustomerInformationQueryHookResult = ReturnType<typeof useCustomerInformationQuery>;
export type CustomerInformationLazyQueryHookResult = ReturnType<typeof useCustomerInformationLazyQuery>;
export type CustomerInformationQueryResult = Apollo.QueryResult<CustomerInformationQuery, CustomerInformationQueryVariables>;
export const GetReportDocument = gql`
    query getReport($filters: ReportFilterInput!) {
  report(filters: $filters) {
    start_date
    end_date
    appointments
    total
  }
}
    `;

/**
 * __useGetReportQuery__
 *
 * To run a query within a React component, call `useGetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetReportQuery(baseOptions: Apollo.QueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
      }
export function useGetReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
        }
export type GetReportQueryHookResult = ReturnType<typeof useGetReportQuery>;
export type GetReportLazyQueryHookResult = ReturnType<typeof useGetReportLazyQuery>;
export type GetReportQueryResult = Apollo.QueryResult<GetReportQuery, GetReportQueryVariables>;
export const AllServicesDocument = gql`
    query allServices {
  services(parseResourceOptions: {relations: "providers,categories"}) {
    total_data
    rows {
      id
      name
      description
      cost
      time
      color
      buffer_time
      categories {
        id
        name
      }
      providers {
        id
        full_name
        email
      }
    }
  }
}
    `;

/**
 * __useAllServicesQuery__
 *
 * To run a query within a React component, call `useAllServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllServicesQuery(baseOptions?: Apollo.QueryHookOptions<AllServicesQuery, AllServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllServicesQuery, AllServicesQueryVariables>(AllServicesDocument, options);
      }
export function useAllServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllServicesQuery, AllServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllServicesQuery, AllServicesQueryVariables>(AllServicesDocument, options);
        }
export type AllServicesQueryHookResult = ReturnType<typeof useAllServicesQuery>;
export type AllServicesLazyQueryHookResult = ReturnType<typeof useAllServicesLazyQuery>;
export type AllServicesQueryResult = Apollo.QueryResult<AllServicesQuery, AllServicesQueryVariables>;
export const ServiceDocument = gql`
    query service($id: Float!) {
  service(id: $id, parseResourceOptions: {relations: "providers,categories"}) {
    id
    name
    buffer_time
    cost
    description
    is_private
    color
    time
    categories {
      id
      name
    }
    providers {
      id
      full_name
    }
  }
}
    `;

/**
 * __useServiceQuery__
 *
 * To run a query within a React component, call `useServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceQuery(baseOptions: Apollo.QueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
      }
export function useServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
        }
export type ServiceQueryHookResult = ReturnType<typeof useServiceQuery>;
export type ServiceLazyQueryHookResult = ReturnType<typeof useServiceLazyQuery>;
export type ServiceQueryResult = Apollo.QueryResult<ServiceQuery, ServiceQueryVariables>;
export const StaffDocument = gql`
    query staff($id: Float!) {
  staffMember(id: $id) {
    id
    full_name
    email
    phone_number
    description
    services {
      id
      name
    }
  }
}
    `;

/**
 * __useStaffQuery__
 *
 * To run a query within a React component, call `useStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStaffQuery(baseOptions: Apollo.QueryHookOptions<StaffQuery, StaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options);
      }
export function useStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffQuery, StaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options);
        }
export type StaffQueryHookResult = ReturnType<typeof useStaffQuery>;
export type StaffLazyQueryHookResult = ReturnType<typeof useStaffLazyQuery>;
export type StaffQueryResult = Apollo.QueryResult<StaffQuery, StaffQueryVariables>;
export const ServicesByStaffDocument = gql`
    query servicesByStaff($id: String!) {
  services(
    parseResourceOptions: {relations: "providers", filter: {key: "providers.id", operator: "eq", value: $id}}
  ) {
    total_data
    rows {
      id
      name
      cost
      time
      color
    }
  }
}
    `;

/**
 * __useServicesByStaffQuery__
 *
 * To run a query within a React component, call `useServicesByStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesByStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesByStaffQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServicesByStaffQuery(baseOptions: Apollo.QueryHookOptions<ServicesByStaffQuery, ServicesByStaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesByStaffQuery, ServicesByStaffQueryVariables>(ServicesByStaffDocument, options);
      }
export function useServicesByStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesByStaffQuery, ServicesByStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesByStaffQuery, ServicesByStaffQueryVariables>(ServicesByStaffDocument, options);
        }
export type ServicesByStaffQueryHookResult = ReturnType<typeof useServicesByStaffQuery>;
export type ServicesByStaffLazyQueryHookResult = ReturnType<typeof useServicesByStaffLazyQuery>;
export type ServicesByStaffQueryResult = Apollo.QueryResult<ServicesByStaffQuery, ServicesByStaffQueryVariables>;
export const UserTimeOffDocument = gql`
    query userTimeOff($id: Float!) {
  userTimeOff(id: $id) {
    id
    start_date
    end_date
    description
    user_id
  }
}
    `;

/**
 * __useUserTimeOffQuery__
 *
 * To run a query within a React component, call `useUserTimeOffQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTimeOffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTimeOffQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserTimeOffQuery(baseOptions: Apollo.QueryHookOptions<UserTimeOffQuery, UserTimeOffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTimeOffQuery, UserTimeOffQueryVariables>(UserTimeOffDocument, options);
      }
export function useUserTimeOffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTimeOffQuery, UserTimeOffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTimeOffQuery, UserTimeOffQueryVariables>(UserTimeOffDocument, options);
        }
export type UserTimeOffQueryHookResult = ReturnType<typeof useUserTimeOffQuery>;
export type UserTimeOffLazyQueryHookResult = ReturnType<typeof useUserTimeOffLazyQuery>;
export type UserTimeOffQueryResult = Apollo.QueryResult<UserTimeOffQuery, UserTimeOffQueryVariables>;
export const WorkingHoursDocument = gql`
    query workingHours($id: Float!) {
  userWorkingHours(id: $id) {
    id
    day
    start_time
    end_time
    status
    user_id
  }
}
    `;

/**
 * __useWorkingHoursQuery__
 *
 * To run a query within a React component, call `useWorkingHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingHoursQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkingHoursQuery(baseOptions: Apollo.QueryHookOptions<WorkingHoursQuery, WorkingHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkingHoursQuery, WorkingHoursQueryVariables>(WorkingHoursDocument, options);
      }
export function useWorkingHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkingHoursQuery, WorkingHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkingHoursQuery, WorkingHoursQueryVariables>(WorkingHoursDocument, options);
        }
export type WorkingHoursQueryHookResult = ReturnType<typeof useWorkingHoursQuery>;
export type WorkingHoursLazyQueryHookResult = ReturnType<typeof useWorkingHoursLazyQuery>;
export type WorkingHoursQueryResult = Apollo.QueryResult<WorkingHoursQuery, WorkingHoursQueryVariables>;
export const TimeOffByIdDocument = gql`
    query timeOffById($id: Float!) {
  userTimeOffById(id: $id) {
    id
    start_date
    end_date
    description
    user_id
  }
}
    `;

/**
 * __useTimeOffByIdQuery__
 *
 * To run a query within a React component, call `useTimeOffByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTimeOffByIdQuery(baseOptions: Apollo.QueryHookOptions<TimeOffByIdQuery, TimeOffByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffByIdQuery, TimeOffByIdQueryVariables>(TimeOffByIdDocument, options);
      }
export function useTimeOffByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffByIdQuery, TimeOffByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffByIdQuery, TimeOffByIdQueryVariables>(TimeOffByIdDocument, options);
        }
export type TimeOffByIdQueryHookResult = ReturnType<typeof useTimeOffByIdQuery>;
export type TimeOffByIdLazyQueryHookResult = ReturnType<typeof useTimeOffByIdLazyQuery>;
export type TimeOffByIdQueryResult = Apollo.QueryResult<TimeOffByIdQuery, TimeOffByIdQueryVariables>;
export const UserBreaksDocument = gql`
    query userBreaks($id: Float!) {
  userBreaks(id: $id) {
    day
    breaks {
      id
      start_time
      end_time
    }
    user_id
  }
}
    `;

/**
 * __useUserBreaksQuery__
 *
 * To run a query within a React component, call `useUserBreaksQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBreaksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBreaksQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserBreaksQuery(baseOptions: Apollo.QueryHookOptions<UserBreaksQuery, UserBreaksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserBreaksQuery, UserBreaksQueryVariables>(UserBreaksDocument, options);
      }
export function useUserBreaksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserBreaksQuery, UserBreaksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserBreaksQuery, UserBreaksQueryVariables>(UserBreaksDocument, options);
        }
export type UserBreaksQueryHookResult = ReturnType<typeof useUserBreaksQuery>;
export type UserBreaksLazyQueryHookResult = ReturnType<typeof useUserBreaksLazyQuery>;
export type UserBreaksQueryResult = Apollo.QueryResult<UserBreaksQuery, UserBreaksQueryVariables>;
export const StaffMembersDocument = gql`
    query staffMembers($limit: Float, $page: Float) {
  staffMembers(parseResourceOptions: {limit: $limit, page: $page}) {
    total_data
    rows {
      id
      full_name
      email
    }
  }
}
    `;

/**
 * __useStaffMembersQuery__
 *
 * To run a query within a React component, call `useStaffMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffMembersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useStaffMembersQuery(baseOptions?: Apollo.QueryHookOptions<StaffMembersQuery, StaffMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffMembersQuery, StaffMembersQueryVariables>(StaffMembersDocument, options);
      }
export function useStaffMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffMembersQuery, StaffMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffMembersQuery, StaffMembersQueryVariables>(StaffMembersDocument, options);
        }
export type StaffMembersQueryHookResult = ReturnType<typeof useStaffMembersQuery>;
export type StaffMembersLazyQueryHookResult = ReturnType<typeof useStaffMembersLazyQuery>;
export type StaffMembersQueryResult = Apollo.QueryResult<StaffMembersQuery, StaffMembersQueryVariables>;
export const LiveAppointmentsDocument = gql`
    subscription liveAppointments($priorities: [String!]!) {
  appointmentSubscription(priorities: $priorities) {
    appointment {
      id
      date
      start_time
      end_time
      cost
      status
      provider_id
      service_id
      customer_id
      service {
        id
        name
        description
        cost
        time
        color
      }
      provider {
        id
        full_name
        email
      }
      customer {
        id
        name
        email
        phone_number
      }
    }
    type
    deleted_id
  }
}
    `;

/**
 * __useLiveAppointmentsSubscription__
 *
 * To run a query within a React component, call `useLiveAppointmentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLiveAppointmentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveAppointmentsSubscription({
 *   variables: {
 *      priorities: // value for 'priorities'
 *   },
 * });
 */
export function useLiveAppointmentsSubscription(baseOptions: Apollo.SubscriptionHookOptions<LiveAppointmentsSubscription, LiveAppointmentsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<LiveAppointmentsSubscription, LiveAppointmentsSubscriptionVariables>(LiveAppointmentsDocument, options);
      }
export type LiveAppointmentsSubscriptionHookResult = ReturnType<typeof useLiveAppointmentsSubscription>;
export type LiveAppointmentsSubscriptionResult = Apollo.SubscriptionResult<LiveAppointmentsSubscription>;
export const TimeOffsForCalendarDocument = gql`
    query timeOffsForCalendar($data: StartEndDateInput!) {
  userTimeOffForCalendar(data: $data) {
    start_date
    end_date
    description
    user_id
    id
  }
}
    `;

/**
 * __useTimeOffsForCalendarQuery__
 *
 * To run a query within a React component, call `useTimeOffsForCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffsForCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffsForCalendarQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTimeOffsForCalendarQuery(baseOptions: Apollo.QueryHookOptions<TimeOffsForCalendarQuery, TimeOffsForCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffsForCalendarQuery, TimeOffsForCalendarQueryVariables>(TimeOffsForCalendarDocument, options);
      }
export function useTimeOffsForCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffsForCalendarQuery, TimeOffsForCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffsForCalendarQuery, TimeOffsForCalendarQueryVariables>(TimeOffsForCalendarDocument, options);
        }
export type TimeOffsForCalendarQueryHookResult = ReturnType<typeof useTimeOffsForCalendarQuery>;
export type TimeOffsForCalendarLazyQueryHookResult = ReturnType<typeof useTimeOffsForCalendarLazyQuery>;
export type TimeOffsForCalendarQueryResult = Apollo.QueryResult<TimeOffsForCalendarQuery, TimeOffsForCalendarQueryVariables>;
export const GetAllUsersDocument = gql`
    query getAllUsers {
  users {
    id
    full_name
  }
}
    `;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const LoggedInUserDocument = gql`
    query loggedInUser {
  getLoggedInUser {
    id
    full_name
    email
    role_id
    role_name
  }
}
    `;

/**
 * __useLoggedInUserQuery__
 *
 * To run a query within a React component, call `useLoggedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserQuery(baseOptions?: Apollo.QueryHookOptions<LoggedInUserQuery, LoggedInUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoggedInUserQuery, LoggedInUserQueryVariables>(LoggedInUserDocument, options);
      }
export function useLoggedInUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoggedInUserQuery, LoggedInUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoggedInUserQuery, LoggedInUserQueryVariables>(LoggedInUserDocument, options);
        }
export type LoggedInUserQueryHookResult = ReturnType<typeof useLoggedInUserQuery>;
export type LoggedInUserLazyQueryHookResult = ReturnType<typeof useLoggedInUserLazyQuery>;
export type LoggedInUserQueryResult = Apollo.QueryResult<LoggedInUserQuery, LoggedInUserQueryVariables>;
export const UserWorkingHoursForCalendarDocument = gql`
    query userWorkingHoursForCalendar {
  userWorkingHoursForCalendar {
    id
    day
    start_time
    end_time
    status
    user_id
  }
}
    `;

/**
 * __useUserWorkingHoursForCalendarQuery__
 *
 * To run a query within a React component, call `useUserWorkingHoursForCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWorkingHoursForCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWorkingHoursForCalendarQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWorkingHoursForCalendarQuery(baseOptions?: Apollo.QueryHookOptions<UserWorkingHoursForCalendarQuery, UserWorkingHoursForCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWorkingHoursForCalendarQuery, UserWorkingHoursForCalendarQueryVariables>(UserWorkingHoursForCalendarDocument, options);
      }
export function useUserWorkingHoursForCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWorkingHoursForCalendarQuery, UserWorkingHoursForCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWorkingHoursForCalendarQuery, UserWorkingHoursForCalendarQueryVariables>(UserWorkingHoursForCalendarDocument, options);
        }
export type UserWorkingHoursForCalendarQueryHookResult = ReturnType<typeof useUserWorkingHoursForCalendarQuery>;
export type UserWorkingHoursForCalendarLazyQueryHookResult = ReturnType<typeof useUserWorkingHoursForCalendarLazyQuery>;
export type UserWorkingHoursForCalendarQueryResult = Apollo.QueryResult<UserWorkingHoursForCalendarQuery, UserWorkingHoursForCalendarQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    