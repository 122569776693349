import { UserTimeOffType } from '../../../../../graphql/generated/generated';
import { Icon } from '../../../../shared/Icon/Icon';

export const getTimeOffRows = (
  rows:
    | ({
        __typename?: 'UserTimeOffType' | undefined;
      } & Pick<UserTimeOffType, 'id' | 'start_date' | 'end_date' | 'description' | 'user_id'>)[]
    | undefined,
  onDeleteClick: (id: string) => void,
  deleting: boolean,
) => {
  return rows?.map((row) => ({
    id: row.id,
    icon: <Icon icon="calendar" />,
    date: (
      <div className="TimeOff__date">
        {row.start_date} to {row.end_date}
        <div className="TimeOff__description">{row.description || 'No description'}</div>
      </div>
    ),
    delete: (
      <Icon
        className="TimeOff__delete"
        icon={deleting ? 'spinner' : 'trash'}
        spin={deleting}
        onClick={(e) => {
          onDeleteClick(row.id);
          e.stopPropagation();
        }}
      />
    ),
  }));
};
