import React, { useState } from 'react';
import { UIContext, UIContextType } from './UIContext';
import { Modal } from '../../../components/shared/Modal/Modal';
import { LoginForm } from '../../../components/user/LoginForm/LoginForm';

interface UIContextProviderProps {
  children: React.ReactNode | null;
}

interface UIContextProviderState {
  isLoginModalOpen: boolean;
  isRegisterModalOpen: boolean;
  isProfileModalOpen: boolean;
  isSidebarOpen: boolean;
}

export const UIContextProvider = (props: UIContextProviderProps) => {
  const [state, setState] = useState<UIContextProviderState>({
    isLoginModalOpen: false,
    isRegisterModalOpen: false,
    isProfileModalOpen: false,
    isSidebarOpen: true,
  });

  const toggleLoginModal = () => {
    setState({
      ...state,
      isLoginModalOpen: !state.isLoginModalOpen,
      isRegisterModalOpen: false,
      isProfileModalOpen: false,
    });
  };

  const toggleRegisterModal = () => {
    setState({
      ...state,
      isLoginModalOpen: false,
      isRegisterModalOpen: !state.isRegisterModalOpen,
      isProfileModalOpen: false,
    });
  };

  const toggleProfileModal = () => {
    setState({
      ...state,
      isLoginModalOpen: false,
      isRegisterModalOpen: false,
      isProfileModalOpen: !state.isProfileModalOpen,
    });
  };

  const toggleSidebar = () => {
    setState({
      ...state,
      isSidebarOpen: !state.isSidebarOpen,
    });
  };

  const context: UIContextType = {
    isLoginModalOpen: state.isLoginModalOpen,
    isRegisterModalOpen: state.isRegisterModalOpen,
    isProfileModalOpen: state.isProfileModalOpen,
    isSidebarOpen: state.isSidebarOpen,
    toggleLoginModal,
    toggleRegisterModal,
    toggleProfileModal,
    toggleSidebar,
  };

  return (
    <UIContext.Provider value={context}>
      {props.children}
      <Modal title="Welcome Back" isOpen={state.isLoginModalOpen} toggle={toggleLoginModal}>
        <LoginForm />
      </Modal>
      <Modal title="Create an Account" isOpen={state.isRegisterModalOpen} toggle={toggleRegisterModal}></Modal>
    </UIContext.Provider>
  );
};
