import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { View } from 'react-big-calendar';

export const getDateRange = (date: Date, view: View) => {
  if (view === 'day') return;
  if (view === 'week') {
    const startDate = startOfWeek(date, { weekStartsOn: 1 }).toISOString().slice(0, 10);
    const endDate = endOfWeek(date, { weekStartsOn: 1 }).toISOString().slice(0, 10);
    return { startDate, endDate };
  }
  if (view === 'month') {
    const startDate = startOfMonth(date).toISOString().slice(0, 10);
    const endDate = endOfMonth(date).toISOString().slice(0, 10);
    return { startDate, endDate };
  }
};
