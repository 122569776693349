import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Option } from '../../../components/shared/Select/Select';

export interface AssignServiceFields {
  services: Option[];
}

interface UseAssignServiceOptions {
  initialValues?: AssignServiceFields;
  onSubmit: (values: AssignServiceFields, formikHelpers: FormikHelpers<AssignServiceFields>) => Promise<any>;
}

export const useAssignServiceFormik = (options: UseAssignServiceOptions) => {
  const AssignServiceSchema = Yup.object().shape({});

  return useFormik({
    initialValues: {
      services: [],
      ...options.initialValues,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: AssignServiceSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type AssignServiceFormik = ReturnType<typeof useAssignServiceFormik>;
