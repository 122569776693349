import { convertDateToIsoString } from './convertDateToIsoString';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { View } from 'react-big-calendar';

export const getFiltersForReport = (date: Date, view: View) => {
  if (view === 'day') return { start_date: convertDateToIsoString(date), end_date: convertDateToIsoString(date) };
  if (view === 'week')
    return { start_date: convertDateToIsoString(startOfWeek(date)), end_date: convertDateToIsoString(endOfWeek(date)) };
  if (view === 'month')
    return {
      start_date: convertDateToIsoString(startOfMonth(date)),
      end_date: convertDateToIsoString(endOfMonth(date)),
    };
  return { start_date: '', end_date: '' };
};
