import { Form } from 'reactstrap';
import { FormButtons } from '../../shared/FormButtons/FormButtons';
import { InputType } from 'reactstrap/es/Input';
import { AddCustomerFields, useAddCustomerFormik } from '../../../lib/hooks/formik/useAddCustomerFormik';
import { CustomersDocument, CustomersType, useAddCustomerMutation } from '../../../graphql/generated/generated';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import { Input } from '../../shared/Input/Input';
import { DatePicker } from '../../shared/DatePicker/DatePicker';

import './AddCustomerForm.scss';
import { formatDate } from '../../../lib/helpers/formatDate';

export interface GetInputProps {
  key: keyof Omit<AddCustomerFields, 'birthday'>;
  type: InputType | undefined;
  placeholder: string;
}

interface Props {
  toggle: () => void;
}
export const AddCustomerForm = (props: Props) => {
  const [addCustomerMutation, { error }] = useAddCustomerMutation();

  const formik = useAddCustomerFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await addCustomerMutation({
          variables: {
            data: {
              ...values,
              birthday: formatDate(values.birthday),
            },
          },
          update(cache, data) {
            // const cachedData = cache.readQuery({ query: CustomersDocument }) as { customers: CustomersType };
            // if (!data.data || !data.data.addCustomer) return;
            // cache.writeQuery({
            //   query: CustomersDocument,
            //   data: {
            //     customers: cachedData.customers.rows?.concat([data.data.addCustomer]),
            //   },
            // });
          },
        });
        formikHelpers.resetForm();
        props.toggle && props.toggle();
      } catch (e) {}
    },
  });

  const getInputProps = ({ key, type, placeholder, ...props }: GetInputProps) => {
    return {
      className: 'AddCustomerForm__input',
      wrapperClassName: 'AddCustomerForm__wrapper_input',
      type: type,
      name: key,
      id: key,
      placeholder: placeholder,
      hiddenLabel: true,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      touched: formik.touched[key],
      error: formik.errors[key],
      value: formik.values[key],
      invalid: formik.touched[key] && !!formik.errors[key],
      ...props,
    };
  };

  return (
    <div className="AddCustomerForm">
      <Form inline onSubmit={formik.handleSubmit}>
        <Input {...getInputProps({ key: 'name', type: 'text', placeholder: 'Name' })} />
        <Input {...getInputProps({ key: 'email', type: 'text', placeholder: 'Email' })} />
        <Input {...getInputProps({ key: 'phone_number', type: 'text', placeholder: 'Mobile' })} />
        <DatePicker
          className="AddCustomerForm__datepicker"
          id="datepicker"
          date={formik.values.birthday}
          placeholder="Date of birth"
          onDateChange={(option) => {
            formik.setFieldValue('birthday', option);
            formik.setFieldTouched('birthday');
          }}
        />
        <FormButtons
          submitButtonName="Add customer"
          disabled={formik.isSubmitting || !formik.isValid}
          loading={formik.isSubmitting}
          submitClassName="AddCustomerForm__submit"
          toggle={props.toggle}
        />

        <ErrorMessage errorData={error} />
      </Form>
    </div>
  );
};
