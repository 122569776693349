import { Modal, ModalProps } from '../../shared/Modal/Modal';
import { AddService } from '../AddService/AddService';
import { EditService } from '../EditService/EditService';

import './AddEditServiceModal.scss';

interface Props extends ModalProps {
  serviceId?: string;
  categoryId: string;
}

export const AddEditServiceModal = (props: Props) => {
  const { serviceId, categoryId, resetServiceId, ...rest } = props;

  return (
    <Modal
      title={
        serviceId && 'Edit Service'
        // : 'Add New Service'
      }
      className="AddEditServiceModal"
      {...rest}
    >
      {serviceId && (
        <EditService categoryId={categoryId} serviceId={serviceId} resetServiceId={resetServiceId} {...rest} />
      )}
      {/* ) : (
        <AddService categoryId={categoryId} resetServiceId={resetServiceId} {...rest} />
      )} */}
    </Modal>
  );
};
