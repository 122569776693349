import { getTimeOptions } from '../../lib/helpers/getTimeOptions';
import { WorkingHoursInterface } from '../../lib/hooks/formik/useAddEditWorkingHours';
import { ValueContainer } from '../AppointmentDetailsForm/AppointmentDetailsForm';
import { Select } from '../shared/Select/Select';
import { Switch } from '../shared/Switch/Switch';
import { formatDataForSelectOption } from '../../lib/helpers/formatDataForSelectOption';
import cs from 'classnames';

import './ChooseWorkingHour.scss';
import { ScrollOnOption } from '../shared/Select/components/ScrollOnOption';

interface Props {
  label: string;
  disabled: boolean;
  value: WorkingHoursInterface;
  onStartTimeChange: (value: string) => void;
  onEndTimeChange: (value: string) => void;
  onToggleSwitch: () => void;
}

export const ChooseWorkingHour = (props: Props) => {
  const { onStartTimeChange, onEndTimeChange, onToggleSwitch, value, disabled } = props;

  return (
    <div className="ChooseWorkingHour">
      <div className="ChooseWorkingHour__label">{props.label}</div>
      <Switch
        className={cs({ ChooseWorkingHour__switch_hidden: disabled })}
        selected={value.status}
        toggleSelected={onToggleSwitch}
      />
      {!value.status ? (
        <div className="ChooseWorkingHour__off">All day off</div>
      ) : (
        <>
          <Select
            className="ChooseWorkingHour__select"
            value={formatDataForSelectOption(value.start_time)}
            onChange={(option: any) => onStartTimeChange(option?.value as string)}
            options={getTimeOptions(24)}
            isDisabled={disabled}
            valueContainer={ValueContainer}
            selectStyle={hourStyles}
            isSearchable={false}
            option={ScrollOnOption}
          />
          <p className="ChooseWorkingHour__text">to</p>
          <Select
            className="ChooseWorkingHour__select"
            value={formatDataForSelectOption(value.end_time)}
            onChange={(option: any) => onEndTimeChange(option?.value)}
            valueContainer={ValueContainer}
            options={getTimeOptions(24)}
            selectStyle={hourStyles}
            isDisabled={disabled}
            isSearchable={false}
            option={ScrollOnOption}
          />
        </>
      )}
    </div>
  );
};
export const hourStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: '1.2rem',
    height: '1.875rem',
    borderColor: state.isDisabled ? 'white' : '#DDC9C0',
    borderRadius: '5px',
    paddingLeft: '0.4rem',
    fontSize: '1rem',
    backgroundColor: '#FFFFFFF',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    minHeight: '1.1rem',
    margin: 0,
    maxWidth: 'unset',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    minHeight: '1.2rem',
    height: '1.875rem',
    paddingLeft: '0.4rem',
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    visibility: state.isDisabled ? 'hidden' : 'visible',
    minHeight: '1.2rem',
    height: '1.875rem',
  }),
};
