import { Button, ButtonProps } from '../shared/Button/Button';
import { Icon } from '../shared/Icon/Icon';

import './SeeMoreButton.scss';

interface Props extends ButtonProps {}

export const SeeMoreButton = (props: Props) => {
  return (
    <Button className="SeeMoreButton" {...props}>
      <Icon className="SeeMoreButton__icon" icon="see-more" />
    </Button>
  );
};
