import { ApolloClient, HttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { cache } from './cache';
import { LOCAL_STORAGE_KEY } from '../lib/context/AuthContext/AuthContextProvider';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL,
});

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_WSLINK}`,
  options: {
    reconnect: true,
    connectionParams: { authToken: localStorage.getItem(LOCAL_STORAGE_KEY) },
  },
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (!token) {
    return {
      headers: { ...headers },
    };
  }

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink),
);

export const client = new ApolloClient({
  cache: cache,
  link: splitLink,
});
