import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Option } from '../../../components/shared/Select/Select';

export type AppointmentFormikKeys = 'details.provider';
export interface AppointmentDetailsFields {
  provider: Option | null;
  service: Option | null;
  date: Date | undefined;
  time: Option | null;
  notes: string;
  price: number;
  discount?: number;
  duration: number;
  status: Option | null;
}

export interface CustomerFields {
  name: string;
  email: string;
  mobile: string;
  birthday: Date | undefined;
}
export interface AppointmentFields {
  details: AppointmentDetailsFields;
  customer: Option | null;
  addCustomer: CustomerFields;
  showAddCustomerForm: boolean;
}

interface UseAppointmentFields {
  initialValues?: AppointmentFields;
  onSubmit: (values: AppointmentFields, formikHelpers: FormikHelpers<AppointmentFields>) => Promise<any>;
}

export const useAppointmentFormik = (options: UseAppointmentFields) => {
  const AddServiceSchema = Yup.object().shape({
    showAddCustomerForm: Yup.boolean(),
    details: Yup.object().shape({
      service: Yup.object().nullable().required('Service is required'),
      provider: Yup.object().nullable().required('Provider is required'),
    }),
    customer: Yup.object()
      .nullable()
      .when('showAddCustomerForm', {
        is: false,
        then: Yup.object().nullable().required('Customer is required.'),
      }),
    addCustomer: Yup.object().when('showAddCustomerForm', {
      is: true,
      then: Yup.object().shape({
        name: Yup.string().required('Name is required!'),
        email: Yup.string().required('Email is required!'),
        mobile: Yup.string()
          .required('Mobile is required!')
          .matches(/^[+]\d+/, 'Should start with +383'),
      }),
    }),
  });

  return useFormik({
    initialValues: {
      showAddCustomerForm: false,
      details: {
        provider: null,
        status: null,
        time: null,
        service: null,
        date: undefined,
        notes: '',
        discount: 0,
        duration: 0,
        price: 0,
      },
      customer: null,
      addCustomer: {
        name: '',
        mobile: '',
        email: '',
        birthday: undefined,
      },
      ...options.initialValues,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: AddServiceSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type AppointmentFormik = ReturnType<typeof useAppointmentFormik>;
