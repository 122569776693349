import { useParams } from 'react-router';
import { CustomerAppointments } from '../CustomerAppointments/CustomerAppointments';
import { CustomerInformation } from '../CustomerInformation/CustomerInformation';

import './CustomerDetails.scss';

export const CustomerDetails = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) return null;
  return (
    <div className="CustomerDetails">
      <CustomerInformation id={id} />
      <CustomerAppointments id={id} />
    </div>
  );
};
