import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../components/shared/Icon/Icon';

//styles
import './Page404.scss';

export const Page404 = () => {
  return (
    <div className="Page404">
      <Icon className="Page404__icon" icon="error-404" />
      <p className="Page404__error">This page was not found</p>
      <NavLink className="Page404__message" to="/">
        Return to homepage?
      </NavLink>
    </div>
  );
};
