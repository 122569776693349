import { NavigateAction, View } from 'react-big-calendar';
import { useWindowWidth } from '../../../../lib/hooks/useWindowWidth';
import { DatePicker } from '../../DatePicker/DatePicker';
import { Icon } from '../../Icon/Icon';

import './DateChanger.scss';

interface Props {
  date: Date;
  onNavigate: (action: NavigateAction, newDate?: Date) => void;
  view: View;
}

export const DateChanger = (props: Props) => {
  const width = useWindowWidth();
  const handleDateChange = (date: Date | [Date | null, Date | null] | null) => {
    if (!date) return;
    if (Array.isArray(date)) return props.onNavigate('DATE', date?.[0] || undefined);
    props.onNavigate('DATE', date ? date : undefined);
  };

  return (
    <div className="DateChanger">
      <span
        className="DateChanger__item_wrapper"
        onClick={() => {
          props.onNavigate('PREV');
          if (props.view === 'day') {
            return handleDateChange(new Date(props.date.setDate(props.date.getDate() - 1)));
          }
          if (props.view === 'week') {
            return handleDateChange(new Date(props.date.setDate(props.date.getDate() - 7)));
          }
          if (props.view === 'month') {
            return handleDateChange(
              new Date(props.date.getFullYear(), props.date.getMonth() - 1, props.date.getDate()),
            );
          }
        }}
      >
        <Icon icon="arrow-general" className="DateChanger__arrow left" />
      </span>

      <DatePicker
        id="date"
        placeholder="Date"
        date={props.date}
        onDateChange={handleDateChange}
        format="dd MMMM yyyy"
      />

      <span
        className="DateChanger__item_wrapper"
        onClick={() => {
          props.onNavigate('NEXT');
          if (props.view === 'day') {
            return handleDateChange(new Date(props.date.setDate(props.date.getDate() + 1)));
          }
          if (props.view === 'week') {
            return handleDateChange(new Date(props.date.setDate(props.date.getDate() + 7)));
          }
          if (props.view === 'month') {
            return handleDateChange(
              new Date(props.date.getFullYear(), props.date.getMonth() + 1, props.date.getDate()),
            );
          }
        }}
      >
        <Icon icon="arrow-general" className="DateChanger__arrow right" />
      </span>

      {width > 767 && (
        <span className="DateChanger__item_wrapper">
          <Icon icon="calendar" className="DateChanger__calendar_icon" />
        </span>
      )}
    </div>
  );
};
