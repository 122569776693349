import { Form } from 'reactstrap';
import { useLoginMutation } from '../../../graphql/generated/generated';
import { useAuthContext } from '../../../lib/context/AuthContext/AuthContext';
import { useLoginFormik } from '../../../lib/hooks/formik/useLoginFormik';
import { Input } from '../../shared/Input/Input';
import { Button } from '../../shared/Button/Button';
import { getInputProps } from './getInputProps';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import { useRouter } from '../../../lib/hooks/useRouter';
import './LoginForm.scss';

export const LoginForm = () => {
  const authCtx = useAuthContext();
  const [loginMutation, { error }] = useLoginMutation();
  const router = useRouter();

  const handleClick = () => {
    router.history.push('forgot-password');
  };
  const formik = useLoginFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        const user = await loginMutation({
          variables: {
            data: values,
          },
        });
        authCtx.login(user.data?.login);
        formikHelpers.resetForm();
      } catch (e) {}
    },
  });

  return (
    <div className="LoginForm">
      <h1 className="LoginForm__title">Login</h1>
      <Form className="LoginForm__form_wrapper" inline onSubmit={formik.handleSubmit}>
        <Input {...getInputProps({ key: 'email', type: 'email', placeholder: 'Email', formik })} />
        <Input {...getInputProps({ key: 'password', type: 'password', placeholder: 'Password', formik })} />
        <p onClick={handleClick} className="LoginForm__forgot-password">
          Forgot password
        </p>
        <Button
          className="LoginForm__button"
          type="submit"
          disabled={formik.isSubmitting || !formik.isValid}
          loading={formik.isSubmitting}
        >
          GO
        </Button>
        <ErrorMessage errorData={error} />
      </Form>
    </div>
  );
};
