import { ApolloError } from '@apollo/client';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';

interface Props {
  error: ApolloError | undefined;
  children: React.ReactNode;
  placeholder?: React.ReactNode;
}

export const HandleErrorState = (props: Props) => {
  if (!props.error) {
    return <>{props.children}</>;
  }

  if (props.placeholder && props.error) return <> {props.placeholder}</>;

  return (
    <>
      <ErrorMessage errorData={props.error} />
    </>
  );
};
