import React, { useState } from 'react';
import cs from 'classnames';
import { NavSidebarButton } from './NavSidebarButton/NavSidebarButton';
import { NavSidebarList } from './NavSidebarList/NavSidebarList';
import { useUIContext } from '../../../lib/context/UIContext/UIContext';

import './NavSidebar.scss';
import { Icon } from '../../shared/Icon/Icon';
import { useWindowWidth } from '../../../lib/hooks/useWindowWidth';

export const NavSidebar = () => {
  const { isSidebarOpen, toggleSidebar } = useUIContext();
  const width = useWindowWidth();

  return (
    <div className="NavSidebar__wrapper">
      <div className={cs('NavSidebar', { NavSidebar__closed: width < 992 ? isSidebarOpen : !isSidebarOpen })}>
        <NavSidebarButton toggle={toggleSidebar} />
        <NavSidebarList toggle={toggleSidebar} />
      </div>
    </div>
  );
};
