import { Icon } from '../shared/Icon/Icon';
import './StaffPagination.scss';

interface Props {
  onPageChange: (pageNumber: number) => void;
  pageNumber: number;
  totalMembers?: number;
}

export const StaffPagination = (props: Props) => {
  const { totalMembers, pageNumber, onPageChange } = props;

  if (!totalMembers) return null;
  const pageFrom = 1 + totalMembers - (totalMembers - (pageNumber - 1) * 7);
  return (
    <div className="StaffPagination">
      <button
        className="StaffPagination__button"
        disabled={pageNumber === 1}
        onClick={() => onPageChange(pageNumber - 1)}
      >
        <Icon className="StaffPagination__previous" icon="arrow-general" />
      </button>
      {pageNumber > 1 ? pageFrom : pageNumber} -{' '}
      {pageNumber > 1 ? (pageNumber * 7 > totalMembers ? totalMembers : pageNumber * 7) : pageNumber + 6}
      <button
        className="StaffPagination__button"
        disabled={totalMembers < pageNumber * 7 ? true : false}
        onClick={() => onPageChange(pageNumber + 1)}
      >
        <Icon className="StaffPagination__next" icon="arrow-general" />
      </button>
    </div>
  );
};
