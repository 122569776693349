import { AddNewButton } from '../AddNewButton/AddNewButton';
import cs from 'classnames';
import './TableWrapper.scss';

interface Props {
  children: React.ReactChild;
  title: string;
  toggleModal: () => void;
  className?: string;
}

export const TableWrapper = (props: Props) => {
  return (
    <div className={cs('TableWrapper', props.className)}>
      <div className="TableWrapper__content">
        <div className="TableWrapper__header">
          <h3 className="TableWrapper__title">{props.title}</h3>
          {/* <AddNewButton className="TableWrapper__add" onClick={props.toggleModal} /> */}
        </div>
        {props.children}
      </div>
    </div>
  );
};
