import { QueryResult } from '@apollo/client';
import { useState } from 'react';
import {
  Exact,
  ServicesByStaffDocument,
  ServicesType,
  StaffQuery,
  useAssignServicesMutation,
  useServicesByStaffQuery,
} from '../../../../graphql/generated/generated';
import { getMinutesFromTime } from '../../../../lib/helpers/getMinutesFromTime';
import { useRouter } from '../../../../lib/hooks/useRouter';
import { AddNewButton } from '../../../shared/AddNewButton/AddNewButton';
import { Dropdown } from '../../../shared/Dropdown/Dropdown';
import { ErrorMessage } from '../../../shared/ErrorMessage/ErrorMessage';
import { Icon } from '../../../shared/Icon/Icon';
import { Table } from '../../../shared/Table/Table';
import { Title } from '../../../shared/Title/Title';
import { AssignServiceToStaffForm } from '../../AssignServiceToStaffForm/AssignServiceToStaffForm';

import './StaffServices.scss';

interface Props {
  user: QueryResult<
    StaffQuery,
    Exact<{
      id: number;
    }>
  >;
}

const columns = [
  {
    key: 'name',
    header: '',
    width: 716,
  },
  { key: 'duration', header: '', width: 315 },
  { key: 'cost', header: '', width: 60 },
  { key: 'delete', header: '', width: 15 },
];

export const StaffServices = (props: Props) => {
  const router = useRouter();
  const staffId = router.match.url.split('/')[2];
  const { data, loading, error } = useServicesByStaffQuery({ variables: { id: staffId } });
  const [updateStaffMutation, { loading: updating, error: updateError }] = useAssignServicesMutation();

  const { data: staff, loading: isLoading, error: isError } = props.user;

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleDeleteClick = async (id: string) => {
    try {
      const updatedServices = data?.services.rows?.filter((row) => row.id !== id);
      await updateStaffMutation({
        variables: {
          data: {
            services: updatedServices?.map((service) => parseInt(service.id)),
          },
          id: parseInt(staffId),
        },
        update(cache) {
          const cachedData = cache.readQuery({ query: ServicesByStaffDocument, variables: { id: staffId } }) as {
            services: ServicesType;
          };
          cache.writeQuery({
            query: ServicesByStaffDocument,
            variables: { id: staffId },
            data: { services: cachedData.services.rows?.filter((row) => row.id !== id) },
          });
        },
      });
    } catch (e) {}
  };

  const rows = data?.services.rows?.map((row) => ({
    name: row.name,
    duration: getMinutesFromTime(row.time),
    cost: `${row.cost} €`,
    delete: (
      <Icon
        onClick={() => handleDeleteClick(row.id)}
        className="StaffServices__delete"
        spin={updating}
        icon={updating ? 'spinner' : 'trash'}
      />
    ),
  }));

  const assignedServices = data?.services.rows?.map((row) => ({ value: row.id, label: row.name }));
  return (
    <div className="StaffServices">
      <div className="StaffServices__header">
        <Title loading={isLoading} error={isError} description={staff?.staffMember.description}>
          <>Services {staff?.staffMember.full_name} Provides:</>
        </Title>
        <Dropdown
          className="StaffServices__dropdown"
          title="Assign services"
          isOpen={isDropdownOpen}
          toggle={toggleDropdown}
          items={<AssignServiceToStaffForm id={staffId} toggle={toggleDropdown} assignedServices={assignedServices} />}
          toggleButton={<AddNewButton />}
        />
      </div>
      <Table className="StaffServices__table" loading={loading} error={error} columns={columns} rows={rows} />
      <ErrorMessage errorData={updateError} />
    </div>
  );
};
