import { useConfirmation } from '../../../lib/context/ConfirmationContext/ConfirmationContext';
import { Options } from '../BigCalendar/Event/Event';
import { Button } from '../Button/Button';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import {
  AppointmentsByArtistsDocument,
  AppointmentsByArtistsQuery,
  CustomerType,
  ProviderType,
  ServiceType,
  useDeleteAppointmentMutation,
} from '../../../graphql/generated/generated';

import './AppointmentDetails.scss';

export interface EditAppointmentType {
  id?: string;
  cost?: number;
  service?: ServiceType | null;
  provider?: ProviderType | null;
  customer?: CustomerType | null;
  date?: Date;
  status?: string;
  discount?: number;
  duration?: number;
}
interface Props {
  canBeEdited?: boolean;
  options?: Options;
  id?: string;
  date?: Date | undefined;
  time?: string;
  cost?: number;
  discount?: number;
  status?: string;
  provider?: ProviderType | null;
  service?: ServiceType | null;
  customer?: CustomerType | null;
  duration?: number;
  onEditClick?: (appointment: EditAppointmentType) => void;
}
export const AppointmentDetails = (props: Props) => {
  const confirmation = useConfirmation();
  const [deleteAppointmentMutation, { loading: isDeleting, error: deleteError }] = useDeleteAppointmentMutation();
  const { canBeEdited, id, time, date, provider, service, cost, status, customer, duration, discount } = props;

  const deleteAppointment = async () => {
    if (!id || !canBeEdited) return;
    try {
      await deleteAppointmentMutation({
        variables: { id: +id },
        update(cache) {
          const cachedData =
            (cache.readQuery({
              query: AppointmentsByArtistsDocument,
              variables: { options: props.options },
            }) as AppointmentsByArtistsQuery) || undefined;

          if (!cachedData || !cachedData.appointmentsByArtists || !cachedData.appointmentsByArtists.rows?.length)
            return;

          const rows = cachedData.appointmentsByArtists?.rows.map((row) => {
            return {
              appointments: row.appointments?.filter((appointment) => appointment.id !== id),

              email: row?.email,
              full_name: row.full_name,
              id: row.id.toString(),
              __typename: 'UserType',
            };
          });

          cache.writeQuery({
            query: AppointmentsByArtistsDocument,
            data: {
              appointmentsByArtists: {
                rows: rows,
                total_data: cachedData.appointmentsByArtists.total_data,
                __typename: cachedData.appointmentsByArtists.__typename,
              },
            },
            variables: {
              options: props.options,
            },
          });
        },
      });
    } catch (error) {}
  };

  const formattedDate = date?.toLocaleString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <div className="AppointmentDetails">
      <div className="AppointmentDetails__timeline">
        <div className="AppointmentDetails__time">
          {formattedDate} {time}
        </div>
        <div className="AppointmentDetails__status">{status}</div>
      </div>
      <table className="AppointmentDetails__content">
        <tbody>
          <tr>
            <th>Provider</th>
            <td>{provider?.full_name}</td>
          </tr>
          <tr>
            <th>Service</th>
            <td>{service?.name}</td>
          </tr>
          <tr>
            <th>Cost €</th>
            <td>{cost} €</td>
          </tr>
          <tr>
            <th>Customer</th>
            <td>{customer?.name}</td>
          </tr>
          <tr>
            <th>Booking ID</th>
            <td>{id}</td>
          </tr>
        </tbody>
      </table>

      {!canBeEdited || status !== 'Paid' ? (
        <div className="AppointmentDetails__footer">
          <Button
            className="AppointmentDetails__edit"
            onClick={(e) => {
              e.stopPropagation();
              props.onEditClick &&
                props.onEditClick({
                  id,
                  date,
                  service,
                  status: status,
                  provider,
                  customer,
                  cost,
                  duration,
                });
            }}
          >
            Edit Appointment
          </Button>
          <Button
            loading={isDeleting}
            disabled={isDeleting}
            className="AppointmentDetails__delete"
            onClick={() => {
              confirmation.confirm('Delete Appointment?').then(() => deleteAppointment());
            }}
          >
            Delete
          </Button>
        </div>
      ) : null}
      <ErrorMessage errorData={deleteError} />
    </div>
  );
};
