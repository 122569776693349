import { useEffect, useRef } from 'react';
import { components } from 'react-select';

export function ScrollOnOption(props: any) {
  const ref = useRef<any>();

  useEffect(() => {
    props.isSelected && ref.current && ref.current.scrollIntoView();
  }, [props.isSelected]);

  return <components.Option {...props} innerRef={ref} />;
}
