import { DropdownItem } from 'reactstrap';
import { useAuthContext } from '../../../../lib/context/AuthContext/AuthContext';
import { useRouter } from '../../../../lib/hooks/useRouter';
import { useWindowWidth } from '../../../../lib/hooks/useWindowWidth';
import { Icon } from '../../../shared/Icon/Icon';

import './AccountItems.scss';

interface Props {
  onUserInfoClick: () => void;
}

export const AccountItems = (props: Props) => {
  const router = useRouter();
  const width = useWindowWidth();
  const authCtx = useAuthContext();

  return (
    <div className="AccountItems">
      <DropdownItem className="AccountItems__dropdown_header">
        Account <Icon className="AccountItems__dropdown_header_icon" icon="arrow-general" />
      </DropdownItem>
      <div className="AccountItems__wrapper">
        <DropdownItem className="AccountItems__dropdown_item" onClick={props.onUserInfoClick}>
          <Icon className="AccountItems__dropdown_icon" icon="profile" />
          <div className="AccountItems__dropdown_content">
            <div className="AccountItems__dropdown_email">{authCtx.user?.email}</div>
            <div className="AccountItems__dropdown_details">Email/Password</div>
          </div>
        </DropdownItem>
        {authCtx.user?.role_name === 'Admin' && width > 992 && (
          <DropdownItem className="AccountItems__dropdown_item" onClick={() => router.history.push('/reports')}>
            <Icon className="AccountItems__dropdown_icon" icon="reports" />
            <div className="AccountItems__dropdown_content">
              <div>Reports</div>
              <div className="AccountItems__dropdown_details">Updates the Reports</div>
            </div>
          </DropdownItem>
        )}
        <DropdownItem className="AccountItems__dropdown_item" onClick={() => window.location.reload()}>
          <Icon className="AccountItems__dropdown_icon" icon="refresh" />
          <div className="AccountItems__dropdown_content">
            <div>Refresh</div>
            <div className="AccountItems__dropdown_details">Updates the Page</div>
          </div>
        </DropdownItem>
        <DropdownItem divider disabled />
        <DropdownItem className="AccountItems__dropdown_item" onClick={() => authCtx.logout()}>
          <Icon className="AccountItems__dropdown_icon" icon="sign-out" />
          <div className="AccountItems__dropdown_content">Sign Out</div>
        </DropdownItem>
      </div>
    </div>
  );
};
