import { differenceInMinutes } from 'date-fns';
import { AppointmentsByArtistsQuery } from '../../graphql/generated/generated';
import { getMinutesAndHourFromTime } from './getMinutesAndHourFromTime';

export const getEvents = (data: AppointmentsByArtistsQuery | undefined | null) => {
  if (!data?.appointmentsByArtists || !data?.appointmentsByArtists.rows) return [];

  const events = data?.appointmentsByArtists.rows.map((row) => {
    if (!row.appointments || row.appointments.length === 0) return [];

    return row?.appointments?.map((item) => {
      const startTime = getMinutesAndHourFromTime(item.start_time);
      const endTime = getMinutesAndHourFromTime(item.end_time || '');

      const startDate = new Date(item.date).setHours(startTime?.hour, startTime?.minutes);
      const endDate = new Date(item.date).setHours(endTime?.hour, endTime?.minutes);
      return {
        eventId: item.id,
        resourceId: item.provider?.id,
        start: new Date(startDate),
        end: new Date(endDate),
        status: item.status,
        cost: item.cost,
        duration: differenceInMinutes(endDate, startDate),
        time: item.service?.time,
        customer: item.customer,
        provider: item.provider,
        service: item.service,
      };
    });
  });

  return events.flat();
};
