import { useFormik, FormikHelpers } from 'formik';
import { Option } from '../../../components/shared/Select/Select';
import * as Yup from 'yup';

export interface ReportsFields {
  startDate: Date | undefined;
  endDate: Date | undefined;
  services: Option[] | null;
  status: Option | null;
  providers: Option[] | null;
}

interface UseReportsOptions {
  initialValues?: ReportsFields;
  onSubmit: (values: ReportsFields, formikHelpers: FormikHelpers<ReportsFields>) => Promise<any>;
}

export const useReportsFormik = (options: UseReportsOptions) => {
  const ReportsSchema = Yup.object().shape({
    startDate: Yup.date().required('Start date is required'),
    endDate: Yup.date().required('End date is required'),
  });

  return useFormik({
    initialValues: {
      startDate: undefined,
      endDate: undefined,
      services: null,
      status: null,
      providers: null,
      ...options.initialValues,
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ReportsSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type ReportFormik = ReturnType<typeof useReportsFormik>;
