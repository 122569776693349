import React, { useState } from 'react';
import { SidebarList } from './SidebarList/SidebarList';
import { ApolloError, MutationResult } from '@apollo/client';
import { Input } from '../Input/Input';
import cs from 'classnames';
import { Icon } from '../Icon/Icon';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';

import './Sidebar.scss';
interface Props {
  items?: { id: string; name: string }[];
  withInput?: boolean;
  title: string;
  error?: ApolloError;
  loading: boolean;
  addNewButton?: JSX.Element;
  className?: string;
  withAll?: string;
  deleting?: MutationResult<any>;
  onDeleteItem?: (id: string) => void;
  listClassName?: string;
  dropdownToggle?: () => void;
}

export const Sidebar = (props: Props) => {
  const [search, setSearch] = useState<string>();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    if (!e || !e.currentTarget.value) return setSearch('');

    setSearch(e?.currentTarget.value);
  };

  const {
    listClassName,
    deleting,
    className,
    items,
    withInput,
    title,
    loading,
    error,
    addNewButton,
    withAll,
    dropdownToggle,
    onDeleteItem,
  } = props;
  return (
    <div className={cs('Sidebar', className)}>
      {withInput && (
        <div className="Sidebar__search">
          <Input
            className="Sidebar__input_wrapper"
            inputClassName="Sidebar__input"
            type="text"
            icon="search"
            id="search"
            value={search}
            placeholder="Find Customer"
            onChange={handleSearch}
          />
          <Icon className="Sidebar__icon" icon="search" />
        </div>
      )}
      <div className="Sidebar__header">
        <div className="Sidebar__title">{title}</div>
        {addNewButton}
      </div>
      <SidebarList
        withAll={withAll}
        loading={loading}
        error={error}
        className={listClassName}
        onDeleteItem={onDeleteItem}
        deleting={deleting?.loading}
        dropdownToggle={dropdownToggle}
        items={search ? items?.filter((item) => item.name.toLowerCase().startsWith(search.toLowerCase())) : items}
      />
      <ErrorMessage errorData={deleting?.error} />
    </div>
  );
};
