import { useFormik, FormikHelpers } from 'formik';
import { OptionTypeBase } from 'react-select';
import * as Yup from 'yup';

export interface AddEditTimeOffFields {
  startDate: Date | undefined;
  endDate: Date | undefined;
  startTime: OptionTypeBase | null;
  endTime: OptionTypeBase | null;
  description: string;
}

interface UseAddEditTimeOffOptions {
  initialValues?: AddEditTimeOffFields;
  onSubmit: (values: AddEditTimeOffFields, formikHelpers: FormikHelpers<AddEditTimeOffFields>) => Promise<any>;
}

export const useAddEditTimeOffFormik = (options: UseAddEditTimeOffOptions) => {
  const AddEditTimeOffSchema = Yup.object().shape({
    startDate: Yup.date().required('StartDate is required'),
    endDate: Yup.date().required('StartDate is required'),
  });

  return useFormik({
    initialValues: {
      startDate: undefined,
      endDate: undefined,
      startTime: null,
      endTime: null,
      description: '',
      ...options.initialValues,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: AddEditTimeOffSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type AddEditTimeOffFormik = ReturnType<typeof useAddEditTimeOffFormik>;
