import React, { useEffect, useState } from 'react';
import {
  useCustomersQuery,
  useDeleteCustomerMutation,
  CustomersDocument,
  CustomersType,
} from '../../graphql/generated/generated';
import { useSearchParams } from '../../lib/helpers/useSearchParams';

import { useRouter } from '../../lib/hooks/useRouter';
import { useWindowWidth } from '../../lib/hooks/useWindowWidth';
import { AddCustomerForm } from '../Customers/AddCustomerForm/AddCustomerForm';
import { AddNewButton } from '../shared/AddNewButton/AddNewButton';
import { Dropdown } from '../shared/Dropdown/Dropdown';
import { Sidebar } from '../shared/Sidebar/Sidebar';

interface Props {
  dropdownToggle?: () => void;
  toggle?: boolean;
}
export const SideBarItems = (props: Props) => {
  const router = useRouter();
  const { loading, data, error } = useCustomersQuery();
  const [deleteCustomerMutation, deleteCustomerResponse] = useDeleteCustomerMutation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  //set first customer ID on url

  useEffect(() => {
    const firstCustomerId = data?.customers.rows?.[0].id;
    if (router.history.location.pathname === '/customers') {
      if (firstCustomerId) router.history.push(`/customers/${firstCustomerId}`);
    }
  }, [data?.customers.rows, router.history]);

  const customers = data?.customers.rows?.map((row) => ({ id: row.id, name: row.name }));

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleDeleteItem = async (id: string) => {
    const paramId = router.location.pathname.split('/')[2];

    try {
      await deleteCustomerMutation({
        variables: { id: parseInt(id) },
        update(cache) {
          const cachedData = cache.readQuery({ query: CustomersDocument }) as { customers: CustomersType };
          cache.writeQuery({
            query: CustomersDocument,
            data: { customers: cachedData.customers.rows?.filter((customer) => customer.id !== id) },
          });
        },
      });
    } catch (e) {
    } finally {
      if (paramId === id) {
        router.history.push(`/customers/${data?.customers.rows?.[0].id}`);
      }
    }
  };
  return (
    <Sidebar
      className="CustomersSidebar"
      onDeleteItem={handleDeleteItem}
      deleting={deleteCustomerResponse}
      dropdownToggle={props.dropdownToggle}
      addNewButton={
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggleDropdown}
          title="Add New Customer"
          menuClassName="Services__menu"
          items={<AddCustomerForm toggle={toggleDropdown} />}
          toggleButton={<AddNewButton />}
        />
      }
      error={error}
      loading={loading}
      title="Customers"
      items={customers}
      withInput
    />
  );
};
