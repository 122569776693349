import { Modal, ModalProps } from '../../shared/Modal/Modal';
import { AddTimeOff } from '../AddTimeOff/AddTimeOff';
import { EditTimeOff } from '../EditTimeOff/EditTimeOff';

import './AddEditTimeOffModal.scss';

interface Props extends ModalProps {
  staffId: string;
  timeOffId?: string;
}

export const AddEditTimeOffModal = (props: Props) => {
  const { timeOffId, ...rest } = props;

  return (
    <Modal title={timeOffId ? 'Edit Time Off' : 'Add New Time Off'} className="AddEditTimeOffModal" {...rest}>
      {timeOffId ? <EditTimeOff timeOffId={timeOffId} {...rest} /> : <AddTimeOff {...rest} />}
    </Modal>
  );
};
