import { EventType } from '../Event/Event';

export const eventPropGetter = (props: EventType) => {
  return {
    className: 'Event',
    style: {
      backgroundColor: props.service.color ? props.service.color : '#FFE6D1',
      borderRadius: '0px',
      opacity: 1,
      color: 'black',
      border: '0px',
      display: 'block',
    },
  };
};
