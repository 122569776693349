import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

export interface AddStaffFields {
  name: string;
  email: string;
  description: string;
  mobile: string;
}

interface UseAddStaffOptions {
  initialValues?: AddStaffFields;
  onSubmit: (values: AddStaffFields, formikHelpers: FormikHelpers<AddStaffFields>) => Promise<any>;
}

export const useAddStaffFormik = (options: UseAddStaffOptions) => {
  const AddStaffSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email().required('Email is required'),
    mobile: Yup.string()
      .required('Mobile is required')
      .matches(/^[+]\d+/, 'Should start with +383'),
  });

  return useFormik({
    initialValues: {
      name: '',
      email: '',
      description: '',
      mobile: '',
      ...options.initialValues,
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: AddStaffSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type AddStaffFormik = ReturnType<typeof useAddStaffFormik>;
